import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import axios from "axios";
import { useCookies } from "react-cookie";

import img1 from "../../assets/images/house2.png";
import dotimg1 from "../../assets/images/house2.png";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaChevronLeft } from "react-icons/fa";

function ListingDetailsGallery({ ledger, propertyData }) {
  const [photosArr, setPhotoArr] = useState([]);
  const [cookies] = useCookies(["Authorization"]);

  // call API to get photos arr
  useEffect(() => {
    const formData = new FormData();
    formData.append("propertyID", propertyData.propertyID);
    axios
      .post("/api/property/view_photos", formData, { headers: cookies })
      .then((res) => {
        console.log(res.data.records);
        // setPhotoArr([...res.data.records])

        // map through the records to create the items and slideDots array
        const parsedPhotos = res?.data?.records?.map((item) => {
          return {
            img: `assets/img/properties/${item.propertyID}/${item.photoID}${item.ext}`,
          };
        });
        console.log(parsedPhotos);
        if (parsedPhotos) {
          setPhotoArr([...parsedPhotos]);
        }
      });
  }, []);

  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  const [slider1, setSlider1] = useState(null);
  const [slider2, setSlider2] = useState(null);

  useEffect(() => {
    setNav1(slider1);
    setNav2(slider2);
  });

  const state = {
    title: "Return to Catalogue",
    items: photosArr,
    slideDots: photosArr,
  };
  const settingsMain = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    asNavFor: ".slider-nav",
    className: "places-carousel gallery-carousel padding-top-35px",
  };
  const settingsThumbs = {
    slidesToShow: photosArr.length,
    slidesToScroll: 1,
    asNavFor: ".slider-for",
    dots: true,
    swipeToSlide: true,
    focusOnSelect: true,
    responsive: [
      {
        breakpoint: 700,
        settings: {
          arrows: false,
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 500,
        settings: {
          arrows: false,
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 400,
        settings: {
          arrows: false,
          slidesToShow: 1,
        },
      },
    ],
  };

  function statusBadge(status) {
    if (status === "1") {
      return ["badge-details badge-reopened", "Re-opened"];
    } else if (status === "2") {
      return ["badge-details", "New"];
    } else {
      return ["badge-details badge-closed", "Closed"];
    }
  }

  return (
    <>
      {propertyData && (
        <div>
          <h2 className="widget-title">
            {ledger ? (
              <Link to={"/dashboard"}>
                <FaChevronLeft />
                {"Return to Dashboard"}
              </Link>
            ) : (
              <Link to={"/listing-grid"}>
                <FaChevronLeft />
                {state.title}
              </Link>
            )}
          </h2>
          <div className="title-shape"></div>
        </div>
      )}
      <div>
        <div className="card-image-wrap">
          {propertyData && (
            <div className="card-image">
              <span className={statusBadge(propertyData.status)[0]}>
                {statusBadge(propertyData.status)[1]}
              </span>
            </div>
          )}
          <Slider
            {...settingsMain}
            asNavFor={nav2}
            ref={(slider) => setSlider1(slider)}
          >
            {state?.items.map((slide, i) => {
              return (
                <div key={i} className="gallery-item">
                  <img src={"/api/" + slide.img} alt="Gallery" />
                </div>
              );
            })}
          </Slider>
        </div>
      </div>

      <div className="gallery-carousel-dots">
        <Slider
          {...settingsThumbs}
          asNavFor={nav1}
          ref={(slider) => setSlider2(slider)}
        >
          {state.slideDots.map((slide, i) => {
            return (
              <div key={i}>
                <img src={"/api/" + slide.img} alt="" />
              </div>
            );
          })}
        </Slider>
      </div>
    </>
  );
}

export default ListingDetailsGallery;
