import React from "react";
import GeneralHeader from "../components/common/GeneralHeader";
import AskQuestionFieldBP from "../components/contact/AskQuestionFieldBP";
import Footer from "../components/common/footer/Footer";
import ScrollTopBtn from "../components/common/ScrollTopBtn";

function BusinessPartners() {
  return (
    <div>
      <main className="contact-page">
        {/* Header */}
        <GeneralHeader />

        <div className="listing-details-banner"></div>

        <section className="contact-area padding-top-40px padding-bottom-80px">
          <div className="container">
            <div className="row d-flex justify-content-around">
              <div className="col-lg-7">
                {/* previous col was 7 if keeping the contact side bar */}
                <AskQuestionFieldBP
                  title="Become a Business Partner"
                  partner={true}
                />
              </div>
            </div>
          </div>
        </section>

        {/* Footer */}
        <Footer />

        <ScrollTopBtn />
      </main>
    </div>
  );
}

export default BusinessPartners;
