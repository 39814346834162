import React, { useState, useEffect } from "react";
import AdminSidePanel from "./AdminSidePanel";
import { Link } from "react-router-dom";
import { FaPlusCircle } from "react-icons/fa";
import { useCookies } from "react-cookie";
import axios from "axios";

function AdminSiteManager() {
  const [cookies, removeCookie] = useCookies(["Authorization"]);
  const [announcementList, setAnnouncementList] = useState([]);
  const [propertyList, setPropertyList] = useState([]);
  const [documentList, setDocumentList] = useState([]);
  const [mapList, setMapList] = useState([]);

  useEffect(() => {
    axios.get("/api/announcement/load_list", { headers: cookies }).then((res) => {
      console.log("res.data in adminsitemanager for announcement is", res.data);
      setAnnouncementList(res.data.records);
    });
  }, []);

  useEffect(() => {
    axios.get("/api/property/load_list", { headers: cookies }).then((res) => {
      console.log("res.data in adminsitemanager for property is", res.data);
      setPropertyList(res.data.records);
    });
  }, []);

  useEffect(() => {
    axios.get("/api/document/load_list", { headers: cookies }).then((res) => {
      console.log("res.data in adminsitemanager for document is", res.data);
      setDocumentList(res.data.records);
    });
  }, []);

  useEffect(() => {
    axios.get("/api/location/load_list", { headers: cookies }).then((res) => {
      console.log("res.data in adminsitemanager for location is", res.data);
      setMapList(res.data.records);
    });
  }, []);

  return (
    <>
      <main className="admin-panel">
        <div className="row">
          <div className="col-lg-2">
            <AdminSidePanel />
          </div>
          <div className="col-lg-9">
            <div className="admin-sitemap">Admin Panel {">"} Site Manager</div>
            <br></br>
            <br></br>

            {/* Announcements */}
            <div className="col-lg-12">
              <div className="section-heading">
                <h2 className="sec__title font-size-24">Announcements</h2>
                <Link to="/admin-addannouncement" className="admin-add">
                  <FaPlusCircle />
                </Link>
              </div>
              <div className="ledger-details-payment table-responsive">
                <table className="table mb-0">
                  <thead>
                    <tr>
                      <th scope="col">Announcement</th>
                      <th scope="col">Details</th>
                      <th scope="col">Image</th>
                      <th scope="col">Status</th>
                      <th scope="col">Manage</th>
                    </tr>
                  </thead>
                  <tbody>
                    {announcementList
                      .reverse()
                      .slice(0, 5)
                      .map((item, index) => (
                        <tr>
                          <td>{item.name}</td>
                          <td>{item.description}</td>
                          <td>{item.featuredPhoto}</td>
                          <td>{item.status}</td>
                          <td>
                            <Link to="/admin-announcements">View</Link> 
                             {/* |{" "} */}
                            {/* <Link to="/admin-announcements">Edit</Link> |{" "}
                            <Link to="/admin-announcements">Delete</Link> */}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
              <Link to="/admin-announcements" className="admin-seeall">
                See all...
              </Link>
            </div>
            <br></br>
            <br></br>
            {/* Property Listings */}
            <div className="col-lg-12">
              <div className="section-heading">
                <h2 className="sec__title font-size-24">Property Listings</h2>
                <Link to="/admin-addproperty" className="admin-add">
                  <FaPlusCircle />
                </Link>
              </div>
              <div className="ledger-details-payment table-responsive">
                <table className="table mb-0">
                  <thead>
                    <tr>
                      <th scope="col">Property Name</th>
                      <th scope="col">Lot</th>
                      <th scope="col">Block</th>
                      <th scope="col">Listing Price</th>
                      <th scope="col">Agent</th>
                      <th scope="col">Status</th>
                      <th scope="col">Manage</th>
                    </tr>
                  </thead>
                  <tbody>
                    {propertyList
                      .reverse()
                      .slice(0, 5)
                      .map((item, index) => (
                        <tr>
                          <td>{item.name}</td>
                          <td>{item.lotStatus}</td>
                          <td>{item.blockID}</td>
                          <td>{item.price}</td>
                          {/* <td>{item.agentID}</td> */}
                          <td>{item.userID}</td>
                          <td>{item.status}</td>
                          <td>
                            <Link to="/admin-properties">View</Link> |{" "}
                            <Link to="/admin-properties">Edit</Link> |{" "}
                            <Link to="/admin-properties">Delete</Link>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
              <Link to="/admin-properties" className="admin-seeall">
                See all...
              </Link>
            </div>
            <br></br>
            <br></br>
            {/* User Forms */}
            {/* <div className="col-lg-12">
              <div className="section-heading">
                <h2 className="sec__title font-size-24">User Forms</h2>
                <Link to="/admin-adduserform" className="admin-add">
                  <FaPlusCircle />
                </Link>
              </div>
              <div className="ledger-details-payment table-responsive">
                <table className="table mb-0">
                  <thead>
                    <tr>
                      <th scope="col">Form Name</th>
                      <th scope="col">Description</th>
                      <th scope="col">Link</th>
                      <th scope="col">Manage</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Form Name</td>
                      <td>Description</td>
                      <td>Link</td>
                      <td>
                        <Link to="/admin-userforms">Edit</Link> |{" "}
                        <Link to="/admin-userforms">Delete</Link>
                      </td>
                    </tr>
                    <tr>
                      <td>Form Name</td>
                      <td>Description</td>
                      <td>Link</td>
                      <td>
                        <Link to="/admin-userforms">Edit</Link> |{" "}
                        <Link to="/admin-userforms">Delete</Link>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <Link to="/admin-userforms" className="admin-seeall">
                See all...
              </Link>
            </div>
            <br></br>
            <br></br>
            {/* Legal Documents */}
            {/* <div className="col-lg-12">
              <div className="section-heading">
                <h2 className="sec__title font-size-24">Legal Documents</h2>
                <Link to="/admin-addlegaldocument" className="admin-add">
                  <FaPlusCircle />
                </Link>
              </div>
              <div className="ledger-details-payment table-responsive">
                <table className="table mb-0">
                  <thead>
                    <tr>
                      <th scope="col">Location</th>
                      <th scope="col">Description</th>
                      <th scope="col">File</th>
                      <th scope="col">Manage</th>
                    </tr>
                  </thead>
                  <tbody>
                    {documentList.map((item, index) => (
                      <tr>
                        <td>{item.name}</td>
                        <td>{item.description}</td>
                        <td>{item.link}</td>
                        <td>
                          <Link to="/admin-legaldocuments">Edit</Link> |{" "}
                          <Link to="/admin-legaldocuments">Delete</Link>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <Link to="/admin-legaldocuments" className="admin-seeall">
                See all...
              </Link>
            </div>
            <br></br>
            <br></br> */}
            {/* Maps */}
            <div className="col-lg-12">
              <div className="section-heading">
                <h2 className="sec__title font-size-24">Locations</h2>
                {/* <Link to="/admin-addmap" className="admin-add">
                  <FaPlusCircle />
                </Link> */}
              </div>
              <div className="ledger-details-payment table-responsive">
                <table className="table mb-0">
                  <thead>
                    <tr>
                      <th scope="col">Document</th>
                      <th scope="col">Description</th>
                      <th scope="col">Map</th>
                      <th scope="col">Images</th>
                      {/* <th scope="col">Application Details</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {mapList
                      // .reverse()
                      .slice(0, 5)
                      .map((item, index) => (
                        <tr>
                          <td>{item.name}</td>
                          <td>{item.description}</td>
                          <td>{item.mapPhoto}</td>
                          <td>{item.featuredPhoto}</td>
                          {/* <td>
                            <Link to="/admin-maps">Edit</Link> |{" "}
                            <Link to="/admin-maps">Delete</Link>
                          </td> */}
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
              {/* <Link to="/admin-maps" className="admin-seeall">
                See all...
              </Link> */}
            </div>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
          </div>
        </div>
      </main>
    </>
  );
}

export default AdminSiteManager;
