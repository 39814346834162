import React from "react";
import Navbar from "./Navbar";
import HeaderAuthorAccess from "../other/account/HeaderAuthorAccess";
import Logo from "./Logo";
import logosrc from "../../assets/images/logo3.png";

export default function GeneralHeader() {
  return (
    <>
      <header className="header-area">
        <div className="header-menu-wrapper header-fixed">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="menu-full-width">
                  {/* Logo */}
                  <div className="logo">
                    <Logo url={logosrc} />
                  </div>

                  {/* Navbar */}
                  <Navbar />

                  {/* Author Access */}
                  <HeaderAuthorAccess />
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
}
