import React from "react";
import GeneralHeader from "../components/common/GeneralHeader";
import About2 from "../components/about/About2";
import About3 from "../components/about/About3";
import Footer from "../components/common/footer/Footer";
import ScrollTopBtn from "../components/common/ScrollTopBtn";
import ADCsubs from "../assets/images/roxasCity.jpg";
import RoxasMap from "../components/about/RoxasMap";

function About() {
  return (
    <main className="about-page">
      {/* Header */}
      <GeneralHeader />

      <div className="listing-details-banner"></div>

      {/* About Intro */}
      <section className="row section-title-width text-center padding-left-15px padding-right-15px">
        <div className="container">
          <About3 />
        </div>
      </section>

      <section className="about-area padding-bottom-100px">
        <div className="container">
          <About2 />
        </div>
      </section>

      {/* <section className="about-area padding-bottom-100px">
        <div className="container">
          <div className="col-lg-12">
            <img
              className="map-full"
              src={ADCsubs}
              alt="Projects in Roxas City"
            />
          </div>
        </div>
      </section> */}

      <RoxasMap />

      {/* Footer */}
      <Footer />

      <ScrollTopBtn />
    </main>
  );
}

export default About;
