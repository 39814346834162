import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useCookies } from "react-cookie";
import axios from "axios";

function SignUpBox({ title, subtitle }) {
  const [accountData, setAccountData] = useState({
    fname: "",
    lname: "",
    email: "",
    password: "",
    confirmPass: "",
  });
  const [showPassError, setShowPassError] = useState(false);
  const [error, setError] = useState({ message: "", status: false });
  const [sucess, setSucess] = useState(false);
  const [cookies, setCookie] = useCookies(["Authorization"]);

  function register(event) {
    event.preventDefault();
    console.log("registering!");
    console.log(accountData);

    // checking password
    if (checkPassword(accountData.password, accountData.confirmPass)) {
      const formData = new FormData();
      for (const [key, value] of Object.entries(accountData)) {
        formData.append(key, value);
      } // note theres an extra entry (confirmPass)

      setError({ message: "", status: false });
      setSucess(false);

      axios.post("/api/registration/save", formData).then((res) => {
        console.log(res.data);

        if (res.data.status === 1) {
          // accounts created need to have emails validated
          setSucess(true);

          // reset form
          setAccountData({
            fname: "",
            lname: "",
            email: "",
            password: "",
            confirmPass: "",
          });
        } else {
          setError({ message: res.data.message, status: true });
        }
      });
    }
  }

  function checkPassword(pass1, pass2) {
    if (pass1 === pass2) {
      // do nothing or remove errors
      setShowPassError(false);
      return true;
    } else {
      setShowPassError(true);
      return false;
    }
  }

  useEffect(() => {
    // clean up accountData
  }, [accountData]);

  return (
    <>
      <div className="billing-form-item mb-0">
        <div className="billing-title-wrap border-bottom-0 pr-0 pl-0 pb-0 text-center">
          <h3 className="widget-title font-size-28 pb-0">{title}</h3>
          <p className="font-size-16 font-weight-medium">{subtitle}</p>
        </div>
        <div className="billing-content">
          <div className="contact-form-action">
            <form method="post">
              <div className="row">
                <div className="col-lg-12">
                  <div className="input-box">
                    <label className="label-text">First Name</label>
                    <div className="form-group">
                      <input
                        className="form-control"
                        type="text"
                        name="fname"
                        placeholder="First Name"
                        onChange={(e) =>
                          setAccountData({
                            ...accountData,
                            fname: e.target.value,
                          })
                        }
                        value={accountData.fname}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="input-box">
                    <label className="label-text">Last Name</label>
                    <div className="form-group">
                      <input
                        className="form-control"
                        type="text"
                        name="lname"
                        placeholder="Last Name"
                        onChange={(e) =>
                          setAccountData({
                            ...accountData,
                            lname: e.target.value,
                          })
                        }
                        value={accountData.lname}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-lg-12">
                  <div className="input-box">
                    <label className="label-text">Email</label>
                    <div className="form-group">
                      <input
                        className="form-control"
                        type="email"
                        name="email"
                        placeholder="E-mail"
                        onChange={(e) =>
                          setAccountData({
                            ...accountData,
                            email: e.target.value,
                          })
                        }
                        value={accountData.email}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="input-box">
                    <label className="label-text">Password</label>
                    <div className="form-group">
                      <input
                        className="form-control"
                        type="password"
                        name="password"
                        placeholder="Password"
                        onChange={(e) =>
                          setAccountData({
                            ...accountData,
                            password: e.target.value,
                          })
                        }
                        value={accountData.password}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="input-box">
                    <label className="label-text">Confirm Password</label>
                    <div className="form-group">
                      <input
                        className="form-control"
                        type="password"
                        name="confirmPass"
                        placeholder="Confirm Password"
                        onChange={(e) =>
                          setAccountData({
                            ...accountData,
                            confirmPass: e.target.value,
                          })
                        }
                        value={accountData.confirmPass}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-12">
                  {showPassError && (
                    <div className="error-message color-text">
                      The passwords you have entered does not match.
                    </div>
                  )}
                  {error.status && (
                    <div className="error-message">{error.message}</div>
                  )}
                  {sucess && (
                    <div>
                      Your account has been registered. Please validate your
                      email to login.
                    </div>
                  )}
                </div>
                <div className="col-lg-12">
                  <div className="btn-box margin-bottom-20px">
                    <button
                      className="button icon-box-button"
                      type="submit"
                      onClick={(e) => register(e)}
                    >
                      Register
                    </button>
                  </div>
                </div>
                <div className="col-lg-12">
                  <p className="font-weight-medium padding-bottom-20px">
                    <Link to="/agent-application" className="color-text-4">
                      Apply as an Agent
                    </Link>
                  </p>
                </div>

                <div className="col-lg-12">
                  <p className="font-weight-medium">
                    Already have an account?{" "}
                    <Link to="/login" className="color-text-4">
                      Login
                    </Link>
                  </p>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default SignUpBox;
