import React from "react";
import GeneralHeader from "../../components/common/GeneralHeader";
import Footer from "../../components/common/footer/Footer";
import SectionsHeading from "../../components/common/SectionsHeading";
import { Link } from "react-router-dom";

function AgentSubmission() {
  return (
    <main className="agent-application-page">
      {/* Header */}
      <GeneralHeader />

      <div className="listing-details-banner"></div>

      <div className="container application-submitted d-flex align-items-center flex-column">
        <SectionsHeading title="Application Submitted!" desc={``} />
        <div>
          <p>
            Thank you for your interest in becoming one of our sales
            representatives!
          </p>
          <br></br>
          <p>
            ADC Staff will review your application, and you will recieve a reply
            via email.
          </p>
        </div>
        <Link to="/">
          <button className="application-button">Return Home</button>
        </Link>
      </div>

      <Footer />
    </main>
  );
}

export default AgentSubmission;
