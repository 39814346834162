import React, { useState } from "react";
import { useCookies } from "react-cookie";
import axios from "axios";
import Select from "react-select";

import { FaRegCalendarPlus, FaUserTie } from "react-icons/fa";
import { Link } from "react-router-dom";
import { BsHouse } from "react-icons/bs";

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import { FaPlus, FaMinus } from "react-icons/fa";

function ListingDetailsSidebar({ propertyData, ledger }) {
  const [cookies] = useCookies(["Authorization"]);
  const [requestMessage, setRequestMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [paymentForm, setPaymentForm] = useState(false);
  const [paymentData, setPaymentData] = useState({
    propertyID: propertyData.propertyID,
    paymentType: "",
    bankName: "",
    paypalEmail: "",
  });
  const [paymentError, setPaymentError] = useState(false);
  const [paymentSucess, setPaymentSucess] = useState(false);

  const typeLegend = {
    0: "All Types",

    1: "Residential Lot for sale",

    2: "House and Lot",

    3: "Residential Lot",

    4: "With Commercial Potential",

    5: "Lot for lease",

    6: "House for Construction",

    7: "RFO House",

    8: "House for Rent",
  };

  const pricingLegend = {
    0: "NA",
    1: "REGULAR",
    2: "PRIME",
    3: "SPECIAL",
    4: "COMMERCIAL",
    5: "CORNER",
  };

  function requestPaymentHistory() {
    setRequestMessage(false);
    setErrorMessage(false);

    const formData = new FormData();
    formData.append("propertyID", propertyData.propertyID);

    axios
      .post("/api/user/request_payment_history", formData, { headers: cookies })
      .then((res) => {
        // console.log(res.data)
        if (res.data.status === 1) {
          setRequestMessage(true);
        } else {
          setErrorMessage(true);
        }
      });
  }

  function requestQuote() {
    setRequestMessage(false);
    setErrorMessage(false);

    const formData = new FormData();
    formData.append("propertyID", propertyData.propertyID);

    axios
      .post("/api/property/get_quote", formData, { headers: cookies })
      .then((res) => {
        console.log(res.data);
        if (res.data.status === 1) {
          setRequestMessage(true);
        } else {
          setErrorMessage(true);
        }
      });
  }

  function openPaymentForm() {
    setPaymentForm(!paymentForm);
    setRequestMessage(false);
    setErrorMessage(false);
    console.log(propertyData);
  }

  function sendReservationRequest() {
    setPaymentError(false);
    // setPaymentSucess(false)

    if (
      paymentData.paymentType === "Paypal" &&
      paymentData.paypalEmail.length === 0
    ) {
      setPaymentError(true);
    } else if (
      paymentData.paymentType === "Bank" &&
      paymentData.bankName.length === 0
    ) {
      setPaymentError(true);
    } else {
      const formData = new FormData();
      for (const [key, value] of Object.entries(paymentData)) {
        formData.append(key, value);
      }
      axios
        .post("/api/property/make_a_reservation", formData, {
          headers: cookies,
        })
        .then((res) => {
          // console.log(res.data)
          setPaymentSucess(true);
        });
    }
  }

  function sendPaymentRequest() {
    setRequestMessage(false);
    setErrorMessage(false);
    setPaymentError(false);
    setPaymentSucess(false);

    if (
      paymentData.paymentType === "Paypal" &&
      paymentData.paypalEmail.length === 0
    ) {
      setPaymentError(true);
    } else if (
      paymentData.paymentType === "Bank" &&
      paymentData.bankName.length === 0
    ) {
      setPaymentError(true);
    } else {
      const formData = new FormData();
      for (const [key, value] of Object.entries(paymentData)) {
        formData.append(key, value);
      }
      axios
        .post("/api/user/make_a_payment", formData, { headers: cookies })
        .then((res) => {
          // console.log(res.data)
          setPaymentSucess(true);
        });
    }
  }

  return (
    <>
      <div className="author-verified-badge margin-bottom-20px">
        <div
          className="author__verified-badge"
          data-toggle="tooltip"
          data-placement="top"
          title="Listing has been verified and belongs the business owner or manager"
        >
          <span className="d-inline-block"></span>
        </div>
      </div>
      <div className="sidebar">
        <h4 className="card-title">{propertyData.locationName}</h4>
        <p className="card-sub">
          Location ID: {propertyData.locationID}, {propertyData.blockName}
        </p>
        <div className="card-details">
          <p>{propertyData.address}</p>
          <p>[Short Description] {propertyData.description}</p>
        </div>
        <ul className="info-list padding-top-20px">
          {/* <li>
            <span className="la d-inline-block">₱</span> {propertyData.price}
          </li> */}
          {/* <li>
            <span className="la d-inline-block">
              <FaUserTie />
            </span>{" "}
            Agent ID: {propertyData.agentID}
          </li> */}
          <li>
            <span className="la d-inline-block">
              <FaRegCalendarPlus />
            </span>{" "}
            Pricing Type: {pricingLegend[propertyData.pricing]}
          </li>
          <li>
            <span className="la d-inline-block">
              <BsHouse />
            </span>{" "}
            Project Type: {typeLegend[propertyData.projectType]}
          </li>
        </ul>
        <div className="d-flex justify-content-around align-items-center margin-top-20px flex-column">
          {ledger ? (
            // <Link to={"/"}>
            <button className="listing-button" onClick={requestPaymentHistory}>
              Request Payment History
            </button>
          ) : (
            // </Link>
            // <Link to={"/"}>
            <button className="listing-button" onClick={requestQuote}>
              Get a Quote
            </button>
            // </Link>
          )}
          <button className="listing-button" onClick={openPaymentForm}>
            {ledger ? "Make a Payment" : "Reserve Lot"}
          </button>
          {paymentForm && (
            <div className="input-box w-100">
              {/* <label className="label-text">Your name</label> */}
              <div className="form-group">
                <Select
                  placeholder="Select Payment Type"
                  options={[
                    { value: "Paypal", label: "Paypal" },
                    { value: "Bank", label: "Bank" },
                  ]}
                  onChange={(e) =>
                    setPaymentData({ ...paymentData, paymentType: e.value })
                  }
                />
                {paymentData.paymentType && (
                  <div className="input-box">
                    {/* <label className="label-text">{paymentData.paymentType}</label> */}
                    <div className="form-group pt-2">
                      {paymentData.paymentType === "Paypal" ? (
                        <input
                          className="form-control"
                          type="text"
                          name="paymentAccount"
                          placeholder="Please enter your Paypal Email"
                          onChange={(e) =>
                            setPaymentData({
                              ...paymentData,
                              paypalEmail: e.target.value,
                            })
                          }
                          value={paymentData.paypalEmail}
                        />
                      ) : (
                        <Select
                          placeholder="Select Bank Type"
                          options={[
                            { value: "METROBANK", label: "METROBANK" },
                            { value: "BPI", label: "BPI" },
                            {
                              value: "ROBINSONS BANK",
                              label: "ROBINSONS BANK",
                            },
                            { value: "UCPB", label: "UCPB" },
                            { value: "DBP", label: "DBP" },
                          ]}
                          onChange={(e) =>
                            setPaymentData({
                              ...paymentData,
                              bankName: e.value,
                            })
                          }
                        />
                      )}
                      {ledger ? (
                        <button
                          className="listing-button bg-5 mt-3"
                          onClick={sendPaymentRequest}
                        >
                          Complete Payment Request
                        </button>
                      ) : (
                        <button
                          className="listing-button bg-5 mt-3"
                          onClick={sendReservationRequest}
                        >
                          Complete Reservation Request
                        </button>
                      )}
                      {paymentError && (
                        <div className="padding-bottom-20px d-flex justify-content-center">
                          Please fill in the form above.
                        </div>
                      )}
                      {paymentSucess && (
                        <div className="padding-bottom-20px d-flex justify-content-center">
                          Your Request has been set to your email.
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
        {errorMessage && (
          <div className="error-message margin-bottom-20px">
            <h3>Request Error</h3>
            <p>There is an issue with your request. </p>
            <p>Please try to contact ADC staff for assistance.</p>
          </div>
        )}
        {requestMessage && (
          <div className="padding-bottom-20px d-flex justify-content-center">
            Your request has been sent.
          </div>
        )}
      </div>
    </>
  );
}

export default ListingDetailsSidebar;
