import React, { useEffect, useState } from "react";
import GeneralHeader from "../../components/common/GeneralHeader";
import Footer from "../../components/common/footer/Footer";
import ScrollTopBtn from "../../components/common/ScrollTopBtn";
import { Link } from "react-router-dom";
import axios from "axios";
import { useCookies } from "react-cookie";

function PropertyMaps() {
  const [maps, setMaps] = useState([]);
  const [cookies] = useCookies(["Authorization"]);

  useEffect(() => {
    axios
      .post("/api/location/load_list", [], { headers: cookies })
      .then((res) => {
        console.log(res.data);
        setMaps(res.data.records);
      });
  }, []);

  return (
    <main className="listing-details">
      {/* Header */}
      <GeneralHeader />

      <div className="listing-details-banner"></div>

      <section className="single-listing-area">
        <div className="container">
          <div className="row">
            <div className="col-12-lg">
              <div className="section-heading">
                <h2 className="sec__title font-size-24">ADC Property Maps</h2>
              </div>
            </div>
          </div>
          <div className="row">
            {maps.map((item, index) => {
              return (
                <div className="col-lg-4 column-td-6" key={index}>
                  <div className="card-item">
                    <div className="card-image-wrap">
                      <Link
                        to={{
                          pathname: "/property-maps-details",
                          state: { details: item },
                        }}
                      >
                        <div className="card-image map-image">
                          <img
                            src={"/api/" + item.featuredPhoto}
                            className="card__img"
                            alt="Place"
                          />
                        </div>
                      </Link>
                    </div>
                    <div className="d-flex align-items-center justify-content-center padding-top-20px">
                      <Link
                        to={{
                          pathname: "/property-maps-details",
                          state: { details: item },
                        }}
                      >
                        <h4 className="card-title">{item.name}</h4>
                      </Link>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>

      <Footer />

      <ScrollTopBtn />
    </main>
  );
}

export default PropertyMaps;
