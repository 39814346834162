import React, { useState } from "react";
import GeneralHeader from "../components/common/GeneralHeader";
import Footer from "../components/common/footer/Footer";
import ScrollTopBtn from "../components/common/ScrollTopBtn";

import { useLocation } from "react-router-dom";
import axios from "axios";
import { useCookies } from "react-cookie";

function ResetPassword() {
  const [passwords, setPasswords] = useState({
    new: "",
    confirm: "",
  });
  const [showPasswordUpdate, setPasswordUpdate] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [error, setError] = useState({ message: "", status: false });
  const [cookies] = useCookies(["Authorization"]);

  const key = new URLSearchParams(useLocation().search);

  function checkPassword(pass1, pass2) {
    if (pass1 === pass2) {
      setPasswordError(false);
      return true;
    } else {
      setPasswordError(true);
      return false;
    }
  }

  function resetPassword(e) {
    e.preventDefault();
    // incase updating twice in a row
    setPasswordUpdate(false);
    setError({ ...error, status: false });
    // console.log(passwords);
    if (checkPassword(passwords.new, passwords.confirm)) {
      console.log("passwords match");
      console.log(key.get("key"));
      const formData = new FormData();
      formData.append("password", passwords.new);
      formData.append("resetpswdKey", key.get("key"));
      axios
        .post("/user/reset_password", formData, { headers: cookies })
        .then((res) => {
          console.log(res.data);
          if (res.data.status === 1) {
            console.log(res.data);
            setPasswordUpdate(true);
          } else {
            setError({ message: res.data.message, status: true });
          }
        });
    }
  }

  return (
    <main className="recover-pass-page">
      {/* Header */}
      <GeneralHeader />
      <div className="listing-details-banner"></div>

      <section className="form-shared padding-top-40px padding-bottom-100px">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 mx-auto">
              <div className="billing-form-item mb-0">
                <div className="billing-title-wrap">
                  <h3 className="widget-title font-size-28">Reset Password!</h3>
                  <p className="font-size-16 font-weight-medium"></p>
                </div>
                <div className="billing-content">
                  <div className="contact-form-action">
                    <form method="post">
                      <div className="input-box">
                        <label className="label-text">New Password</label>
                        <div className="form-group">
                          <input
                            className="form-control"
                            type="password"
                            name="newPassword"
                            placeholder="New Password"
                            onChange={(e) =>
                              setPasswords({
                                ...passwords,
                                new: e.target.value,
                              })
                            }
                            value={passwords.new}
                          />
                        </div>
                      </div>
                      <div className="input-box">
                        <label className="label-text">
                          Confirm New Password
                        </label>
                        <div className="form-group">
                          <input
                            className="form-control"
                            type="password"
                            name="confirmNewPassword"
                            placeholder="Confirm New Password"
                            onChange={(e) =>
                              setPasswords({
                                ...passwords,
                                confirm: e.target.value,
                              })
                            }
                            value={passwords.confirm}
                          />
                        </div>
                      </div>
                      <div className="btn-box margin-top-20px margin-bottom-20px">
                        <button
                          className="theme-btn border-0"
                          type="submit"
                          onClick={(e) => resetPassword(e)}
                        >
                          reset password
                        </button>
                      </div>
                      {showPasswordUpdate && (
                        <div className="margin-bottom-10px">
                          Your password has successfully been updated!
                        </div>
                      )}

                      {passwordError && (
                        <div className="error-message color-text margin-bottom-10px">
                          The passwords you have entered does not match.
                        </div>
                      )}
                      {error.status && (
                        <div className="error-message">
                          <h3>Submission Error</h3>
                          <p>Error Message: {error.message}</p>
                          <p>
                            Please try to contact ADC staff for assistance with
                            your submission.
                          </p>
                        </div>
                      )}
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Footer */}
      <Footer />

      <ScrollTopBtn />
    </main>
  );
}

export default ResetPassword;
