import React from "react";
import GeneralHeader from "../../components/common/GeneralHeader";
import ListingDetailsSidebar from "../../components/sidebars/ListingDetailsSidebar";
import ListingDetailsGallery from "../../components/sliders/ListingDetailsGallery";
import Footer from "../../components/common/footer/Footer";
import ScrollTopBtn from "../../components/common/ScrollTopBtn";

function ListingDetailsLedger({ location }) {
  const propertyData = { ...location.state.details };
  // console.log('this is propertyData', propertyData)

  return (
    <main className="listing-details">
      {/* Header */}
      <GeneralHeader />

      <div className="listing-details-banner"></div>

      <section className="single-listing-area padding-top-35px">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="single-listing-wrap padding-bottom-50px">
                <ListingDetailsGallery
                  ledger={true}
                  propertyData={propertyData}
                />
              </div>
            </div>
            <div className="col-lg-4">
              <ListingDetailsSidebar
                ledger={true}
                propertyData={propertyData}
              />
            </div>
          </div>
        </div>
      </section>

      <Footer />

      <ScrollTopBtn />
    </main>
  );
}

export default ListingDetailsLedger;
