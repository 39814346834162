import React, { useState, useEffect } from "react";
import AdminSidePanel from "./AdminSidePanel";
import { Link } from "react-router-dom";
import { AiOutlineExport } from "react-icons/ai";
import { useCookies } from "react-cookie";
import axios from "axios";

function AdminClients() {
  const [cookies, removeCookie] = useCookies(["Authorization"]);
  const [clientList, setClientList] = useState([]);

  useEffect(() => {
    axios.get("/api/user/load_list", { headers: cookies }).then((res) => {
      console.log("res.data for clientss in adminclients is", res.data);
      setClientList(res.data.records);
    });
  }, []);



  //Delete Data

  // useEffect((id) => {
  //   axios.delete("/api/user/delete", { headers: cookies }).then((res) => {
  //     console.log("res.data for DELETE clients in adminclients is", res.data);
  //     setClientList(res.data.records);
  //   });
  // }, []);




  return (
    <>
      <main className="admin-panel">
        <div className="row">
          <div className="col-lg-2">
            <AdminSidePanel />
          </div>
          <div className="col-lg-9">
            <div className="admin-sitemap">
              Admin Panel {">"} Accounts {">"} Clients
            </div>
            <br></br>
            <br></br>
            {/* Clients */}
            <div className="col-lg-12">
              <div className="section-heading">
                <h2 className="sec__title font-size-24">Clients</h2>
                <Link to="/admin-exportclients" className="admin-export">
                  Export <AiOutlineExport />
                </Link>
              </div>
              <div className="ledger-details-payment table-responsive">
                <table className="table mb-0">
                  <thead>
                    <tr>
                      <th scope="col">Name</th>
                      <th scope="col">Email</th>
                      <th scope="col">Contact #</th>
                      <th scope="col">Status</th>
                      <th scope="col">Location</th>
                      <th scope="col">Ledger</th>
                      <th scope="col">Manage</th>
                    </tr>
                  </thead>
                  <tbody>
                    {clientList
                      // .reverse()
                      .filter((item) => item.userType === "")
                      .map((item) => (
                        <tr>
                          <td>
                            {item.fname} {item.mnam} {item.lname}
                          </td>
                          <td>{item.email}</td>
                          <td>{item.contactNo}</td>
                          <td>{item.status}</td>
                          <td>{item.address}</td>
                          <td>
                            <Link to="/dashboard">View Ledger</Link>
                          </td>
                          <td>
                            <Link
                              to={{
                                pathname: "/admin-clients-view",
                                state: { details: item },
                              }}
                            >
                              View
                            </Link>{" "}
                            |{" "}
                            <Link
                              to={{
                                pathname: "/admin-clients-edit",
                                state: { details: item },
                              }}
                            >
                              Edit
                            </Link>{" "}
                            | <Link to="/admin-clients">Delete</Link>
                          </td>
                        </tr>
                      ))}

                    {/* <tr>
                      <td>Name</td>
                      <td>Email</td>
                      <td>Contact #</td>
                      <td>Status</td>
                      <td>Location</td>
                      <td>
                        <Link to="/dashboard">View Ledger</Link>
                      </td>
                      <td>
                        <Link to="/admin-clients">View</Link> | <Link to="/admin-clients">Edit</Link> | <Link to="/admin-clients">Delete</Link>
                      </td>
                    </tr>
                    <tr>
                      <td>Name</td>
                      <td>Email</td>
                      <td>Contact #</td>
                      <td>Status</td>
                      <td>Location</td>
                      <td>
                        <Link to="/dashboard">View Ledger</Link>
                      </td>
                      <td>
                        <Link to="/admin-clients">View</Link> | <Link to="/admin-clients">Edit</Link> | <Link to="/admin-clients">Delete</Link>
                      </td>
                    </tr>
                    <tr>
                      <td>Name</td>
                      <td>Email</td>
                      <td>Contact #</td>
                      <td>Status</td>
                      <td>Location</td>
                      <td>
                        <Link to="/dashboard">View Ledger</Link>
                      </td>
                      <td>
                        <Link to="/admin-clients">View</Link> | <Link to="/admin-clients">Edit</Link> | <Link to="/admin-clients">Delete</Link>
                      </td>
                    </tr>
                    <tr>
                      <td>Name</td>
                      <td>Email</td>
                      <td>Contact #</td>
                      <td>Status</td>
                      <td>Location</td>
                      <td>
                        <Link to="/dashboard">View Ledger</Link>
                      </td>
                      <td>
                        <Link to="/admin-clients">View</Link> | <Link to="/admin-clients">Edit</Link> | <Link to="/admin-clients">Delete</Link>
                      </td>
                    </tr>
                    <tr>
                      <td>Name</td>
                      <td>Email</td>
                      <td>Contact #</td>
                      <td>Status</td>
                      <td>Location</td>
                      <td>
                        <Link to="/dashboard">View Ledger</Link>
                      </td>
                      <td>
                        <Link to="/admin-clients">View</Link> | <Link to="/admin-clients">Edit</Link> | <Link to="/admin-clients">Delete</Link>
                      </td>
                    </tr>
                    <tr>
                      <td>Name</td>
                      <td>Email</td>
                      <td>Contact #</td>
                      <td>Status</td>
                      <td>Location</td>
                      <td>
                        <Link to="/dashboard">View Ledger</Link>
                      </td>
                      <td>
                        <Link to="/admin-clients">View</Link> | <Link to="/admin-clients">Edit</Link> | <Link to="/admin-clients">Delete</Link>
                      </td>
                    </tr>
                    <tr>
                      <td>Name</td>
                      <td>Email</td>
                      <td>Contact #</td>
                      <td>Status</td>
                      <td>Location</td>
                      <td>
                        <Link to="/dashboard">View Ledger</Link>
                      </td>
                      <td>
                        <Link to="/admin-clients">View</Link> | <Link to="/admin-clients">Edit</Link> | <Link to="/admin-clients">Delete</Link>
                      </td>
                    </tr> */}
                  </tbody>
                </table>
              </div>
              <Link to="/admin-clients" className="admin-seeall">
                Page 1 of 1 Next {">>"}
              </Link>
            </div>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
          </div>
        </div>
      </main>
    </>
  );
}

export default AdminClients;
