import React, { useState, useEffect } from "react";
import { FiRefreshCw } from "react-icons/fi";
import GeneralHeader from "../../components/common/GeneralHeader";
import Breadcrumb from "../../components/common/Breadcrumb";
import PlaceListing from "../../components/places/PlaceListing";
import ListingListSidebar from "../../components/sidebars/ListingListSidebar";
import Footer from "../../components/common/footer/Footer";
import ScrollTopBtn from "../../components/common/ScrollTopBtn";
import GenericHeader from "../../components/common/GenericHeader";
import breadcrumbimg from "../../assets/images/bread-bg.jpg";
import bannerImg from "../../assets/images/listing-hero-bg.png";
import Button from "../../components/common/Button";

import axios from "axios";
import { useCookies } from "react-cookie";
import { Link } from "react-router-dom";

const state = {
  breadcrumbimg: breadcrumbimg,
};
function ListingList({ location }) {
  const [cookies] = useCookies(["Authorization"]);
  const [propertyList, setPropertyList] = useState([]);
  const [paginationL, setPaginationL] = useState({
    limit: 3,
    offset: 0,
    page: 1,
  });
  const [emptyMessage, setEmptyMessage] = useState(false);

  const [filterParams, setFilterParams] = useState({
    locationName: location.state?.filter.locationName
      ? location.state?.filter.locationName
      : "",
    projectType: location.state?.filter.projectType
      ? location.state?.filter.projectType
      : "",
    locationID: location.state?.filter.locationID
      ? location.state?.filter.locationID
      : "",
    sortOrder: location.state?.filter.sortOrder
      ? location.state?.filter.sortOrder
      : "",
    sortBy: location.state?.filter.sortBy ? location.state?.filter.sortBy : "",
    searchValue: location.state?.filter.searchValue
      ? location.state?.filter.searchValue
      : "",
  });

  // console.log("this is filter for the page >>>>", filterParams)
  // console.log('this is the pagination       >>>', pagination)

  function nextPagination() {
    // set pagination config for next page to request
    const newOffset = paginationL.limit * paginationL.page;
    const newPage = paginationL.page + 1;
    setPaginationL({ ...paginationL, page: newPage, offset: newOffset });
  }

  function loadMore() {
    // console.log("triggering more");
    nextPagination();
    setEmptyMessage(false);

    const formData = new FormData();
    for (const [key, value] of Object.entries(filterParams)) {
      formData.append(key, value);
    }
    formData.append("limit", paginationL.limit);
    formData.append("offset", paginationL.offset);

    // console.log("offset >>>", pagination.offset);

    axios.post("/api/property/load_list", formData).then((res) => {
      // console.log(res.data);
      const newList = propertyList.concat(res.data.records);
      console.log("NEW LIST >>>", newList);
      setPropertyList(newList);
      if (res.data.records.length === 0) {
        setEmptyMessage(true);
      } else {
        setEmptyMessage(false);
      }
    });
  }

  function filter() {
    console.log("sending new list with filter!!");
    setEmptyMessage(false);

    // take in params
    const formData = new FormData();
    for (const [key, value] of Object.entries(filterParams)) {
      formData.append(key, value);
    }
    // reset pagination in request
    formData.append("limit", 3);
    formData.append("offset", 0);

    // axios call
    axios.post("/api/property/load_list", formData).then((res) => {
      // console.log(res.data);
      // reset pagination and set up for next page
      setPaginationL({ limit: 3, offset: 3, page: 2 });
      // replace the whole properties state
      setPropertyList(res.data.records);

      if (res.data.records.length === 0) {
        setEmptyMessage(true);
      } else {
        setEmptyMessage(false);
      }
    });
  }

  useEffect(() => {
    //show what pagination is after every change in propertyGrid
    console.log("filter paginatoin reset >>>>", paginationL);
  }, [setPaginationL]);

  useEffect(() => {
    const formData = new FormData();
    for (const [key, value] of Object.entries(filterParams)) {
      formData.append(key, value);
    }
    // reset offset for request
    formData.append("limit", 3);
    formData.append("offset", 0);

    axios.post("/api/property/load_list", formData).then((res) => {
      // console.log(res.data);
      // reset pagination and set up for next page
      setPaginationL({ limit: 3, offset: 3, page: 2 });
      setPropertyList(res.data.records);

      if (res.data.records.length === 0) {
        setEmptyMessage(true);
      } else {
        setEmptyMessage(false);
      }
    });
  }, []);

  return (
    <main className="listing-list">
      {/* Header */}
      <GeneralHeader />

      {/* Breadcrumb */}
      <Breadcrumb
        CurrentPgTitle="Properties"
        MenuPgTitle="Listings"
        img={bannerImg}
      />

      {/* Place List */}
      <section className="card-area padding-top-40px padding-bottom-100px">
        <div className="container d-flex align-items-center flex-column">
          <div
            className={
              !cookies.Authorization || cookies.Authorization?.length < 10
                ? "user-blur padding-bottom-30px"
                : ""
            }
          >
            <div className="row">
              <div className="col-lg-12">
                <GenericHeader
                  filterParams={filterParams}
                  setFilterParams={setFilterParams}
                />
              </div>

              <div className="col-lg-8">
                <PlaceListing listitems={propertyList} />
                {emptyMessage && (
                  <div>
                    There are 0 properties that matches the filter selection.
                    Please change the filter selection.
                  </div>
                )}
              </div>

              <div className="col-lg-4">
                <ListingListSidebar
                  filterParams={filterParams}
                  setFilterParams={setFilterParams}
                  filterList={filter}
                />
                <div className="row">
                  <Link to={"/property-maps"}>
                    <p className="map-link">View Location Map</p>
                  </Link>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="button-shared text-center">
                  <button className="theme-btn border-0" onClick={loadMore}>
                    <span className="d-inline-block padding-right-15px">
                      <FiRefreshCw />
                    </span>
                    Load More
                  </button>
                </div>
              </div>
            </div>
          </div>
          {(!cookies.Authorization || cookies.Authorization?.length < 10) && (
            <Button
              url="/login"
              text="Login to see Details"
              className="blur-overlay-button"
            ></Button>
          )}
        </div>
      </section>

      {/* Footer */}
      <Footer />

      <ScrollTopBtn />
    </main>
  );
}

export default ListingList;
