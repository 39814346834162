import React from "react";
import { RiHotelBedLine, RiPlaneLine, RiReplyLine } from "react-icons/ri";
import { GiChickenOven, GiPositionMarker } from "react-icons/gi";
import {
  BsBookmark,
  BsCheckCircle,
  BsEye,
  BsListCheck,
  BsMap,
  BsPencil,
} from "react-icons/bs";
import { MdClose, MdStar, MdStarBorder, MdStarHalf } from "react-icons/md";
import img1 from "../assets/images/img1.jpg"; // 263*175
import img2 from "../assets/images/house2.png"; // listing property house
import authorimg from "../assets/images/small-team1.jpg"; // 67*60
import symble1 from "../assets/images/symble1.png";
import symble2 from "../assets/images/symble2.png";
import symble3 from "../assets/images/symble3.png";
import symble4 from "../assets/images/symble4.png";
import { TiArrowUnsorted } from "react-icons/ti";
import {
  IoIosCheckmarkCircle,
  IoIosRocket,
  IoMdMusicalNotes,
  IoMdPaperPlane,
  IoMdStar,
  IoMdStarHalf,
} from "react-icons/io";
import {
  FiBookOpen,
  FiCheck,
  FiCheckCircle,
  FiExternalLink,
  FiFilter,
  FiHeadphones,
  FiHeart,
  FiPlus,
  FiThumbsUp,
  FiUsers,
} from "react-icons/fi";
import {
  AiOutlineEllipsis,
  AiOutlineHome,
  AiOutlineQuestionCircle,
} from "react-icons/ai";
import team1 from "../assets/images/team2.jpg"; // 111*100
import team2 from "../assets/images/testi-img1.jpg"; // 106*95
import team3 from "../assets/images/testi-img2.jpg"; // 95*85
import team4 from "../assets/images/testi-img3.jpg"; // 83*75
import team6 from "../assets/images/testi-img4.jpg"; // 78*70
import { GoFile } from "react-icons/go";
import cta2mobileimg from "../assets/images/mobile.png";
//  sister companies
import sister1 from "../assets/images/sister-abai.jpg";
import sister2 from "../assets/images/sister-abocgo.png";
import sister3 from "../assets/images/sister-sanantonio.png";
import sister4 from "../assets/images/sister-vbgi.png";
import sister5 from "../assets/images/sister-capiz.png";
import darklogo from "../assets/images/logo3.png";
import {
  FaBehance,
  FaCogs,
  FaDribbble,
  FaFacebookF,
  FaFacebookSquare,
  FaHome,
  FaInstagram,
  FaLinkedinIn,
  FaMinus,
  FaPlus,
  FaQuoteRight,
  FaRegComment,
  FaRegEdit,
  FaRegEnvelope,
  FaRegHeart,
  FaRegMap,
  FaRegMoneyBillAlt,
  FaRegTrashAlt,
  FaSearch,
  FaSearchPlus,
  FaSort,
  FaTag,
  FaTwitter,
  FaTwitterSquare,
  FaYoutube,
} from "react-icons/fa";

import destinationimg6 from "../assets/images/img7.jpg";

import hiw3videoimg from "../assets/images/video-img2.jpg";
import { FcLineChart } from "react-icons/fc";
import dreamimg from "../assets/images/img6.jpg"; // 263*165
import hiw5videoimg from "../assets/images/video-img3.jpg";

import listingdetailsvideoimg from "../assets/images/img4.jpg";
import similarimg from "../assets/images/img8.jpg"; // 90*90
import breadcrumbimg from "../assets/images/bread-bg.jpg"; //1920*838
import userimage from "../assets/images/team1.jpg"; // 368*331
import imgboximg from "../assets/images/img10.jpg"; // 570*345
import about2img from "../assets/images/img2.jpg"; //389*259
import mainimage from "../assets/images/video-img.jpg"; // 750*500
// import ADCsubs from "../assets/images/ADCsubs.jpg";
import ADCMap from "../assets/images/ADCMap.png";
import roxas1 from "../assets/images/model1.jpg";
// import roxas2 from "../assets/images/model2.jpg";
import roxas2 from "../assets/images/model2.jpg";
import roxas3 from "../assets/images/model3.jpg";
import roxas4 from "../assets/images/hero-bg.png";

const sectiondata = {
  headermenu: [
    {
      title: "Home",
      path: "/",
    },
    {
      title: "about",
      path: "/about",
    },
    {
      title: "Properties",
      path: "/listing-grid",
    },
    {
      title: "contact us",
      path: "/contact",
    },
    {
      title: "FAQs",
      path: "/faq",
    },
  ],
  herobanners: {
    banner1: {
      // bgimage: ADCsubs,
      bgimage1: roxas1,
      bgimage2: roxas2,
      bgimage3: roxas3,
    },
  },
  annoucement:
    "PLACEHOLDER FOR ANOUCEMENTS Proin dapibus nisl ornare diam varius ecos tempus. Aenean a quam luctus, finibus tellus ut, convallis eros.",
  howitworks: {
    hiw1: {
      sectitle: "Work with ADC",
      seccontent: "",
      items: [
        {
          id: 1,
          icon: <FaHome />,
          title: "Buyers",
          button: "Login/Sign Up",
          path: "/login",
          desc: "Once you have decided on what you want, you can pay here.",
        },
        {
          id: 2,
          icon: <FaSearch />,
          title: "Agents",
          button: "Apply as Agent",
          path: "/agent-application",
          desc: "Big Income opportunities await those who want to become sales agents/representatives",
        },
        {
          id: 3,
          icon: <FaTag />,
          title: "Partners",
          button: "Apply as Partner",
          path: "/contact-business",
          desc: "If you have properties to lease, sell, joint venture, click here.",
        },
      ],
    },
    hiw2: {
      sectitle: "What We Offer",
      seccontent:
        "Morbi convallis bibendum urna ut viverra. Maecenas quis consequat libero, a feugiat eros. Nunc ut lacinia tortors.",
      items: [
        {
          active: false,
          icon: <BsMap />,
          title: "Great Places",
          description:
            "There are many variations of passages of Lorem Ipsum available.",
        },
        {
          active: false,
          icon: <GiChickenOven />,
          title: "Great Restaurants",
          description:
            "There are many variations of passages of Lorem Ipsum available.",
        },
        {
          active: false,
          icon: <RiHotelBedLine />,
          title: "Great Hotels",
          description:
            "There are many variations of passages of Lorem Ipsum available.",
        },
        {
          active: true,
          icon: <AiOutlineEllipsis />,
          title: "And More...",
          description:
            "There are many variations of passages of Lorem Ipsum available.",
        },
      ],
    },
    hiw3: {
      sectitle: "How Can I Earn Money with Dirto?",
      seccontent:
        "Dirto provides the various ways that help you can earn money from your site. Morbi convallis bibendum urna ut viverra.",
      videobtn: "watch how it works",
      videoid: "R2kiP9Qu7Pc",
      videoImg: hiw3videoimg,
      items: [
        {
          id: 1,
          icon: <FaRegMoneyBillAlt />,
          title: "Paid Listings",
          desc: "Proin dapibus nisl ornare diam varius ecos tempus. Aenean a quam luctus, finibus tellus ut, convallis eros.",
        },
        {
          id: 2,
          icon: <FcLineChart />,
          title: "Promoted Listings",
          desc: "Proin dapibus nisl ornare diam varius ecos tempus. Aenean a quam luctus, finibus tellus ut, convallis eros.",
        },
        {
          id: 3,
          icon: <FaRegHeart />,
          title: "Paid Claim Listings",
          desc: "Proin dapibus nisl ornare diam varius ecos tempus. Aenean a quam luctus, finibus tellus ut, convallis eros.",
        },
      ],
    },
    hiw4: {
      sectitle: "Why Choose Us",
      seccontent:
        "Morbi convallis bibendum urna ut viverra. Maecenas quis consequat libero, a feugiat eros. Nunc ut lacinia tortors.",
      items: [
        {
          icon: <FiCheck />,
          title: "We Are Professional",
          desc: "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour,",
        },
        {
          icon: <FiCheck />,
          title: "Best Service Guarantee",
          desc: "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour,",
        },
        {
          icon: <FiCheck />,
          title: "We Are Trusted and Secured",
          desc: "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour,",
        },
        {
          icon: <FiCheck />,
          title: "Online Support 24/7 ",
          desc: "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour,",
        },
      ],
    },
    hiw5: {
      sectitle: "Get Started With Dirto!",
      seccontent:
        "Hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis at vero.",
      items: [
        {
          id: 1,
          icon: <BsPencil />,
          title: "Pick a Keyword",
          desc: "Proin dapibus nisl ornare diam varius ecos tempus. Aenean a quam",
        },
        {
          id: 2,
          icon: <GiPositionMarker />,
          title: "Select Location",
          desc: "Proin dapibus nisl ornare diam varius ecos tempus. Aenean a quam",
        },
        {
          id: 3,
          icon: <TiArrowUnsorted />,
          title: "Select Category",
          desc: "Proin dapibus nisl ornare diam varius ecos tempus. Aenean a quam",
        },
        {
          id: 4,
          icon: <FiCheck />,
          title: "View Results",
          desc: "Proin dapibus nisl ornare diam varius ecos tempus. Aenean a quam",
        },
      ],
      videoBg: hiw5videoimg,
      videoBtn: "watch how it works",
      videoid: "R2kiP9Qu7Pc",
    },
    hiw6: {
      sectitle: "How Dirto Works",
      seccontent:
        "Morbi convallis bibendum urna ut viverra. Maecenas quis consequat libero, a feugiat eros. Nunc ut lacinia tortors.",
      items: [
        {
          id: 1,
          icon: <FaSearchPlus />,
          title: "Find a Place",
          description:
            "Proin dapibus nisl ornare diam varius ecos tempus. Aenean a quam",
        },
        {
          id: 2,
          icon: <FaRegMap />,
          title: "Select a location",
          description:
            "Proin dapibus nisl ornare diam varius ecos tempus. Aenean a quam",
        },
        {
          id: 3,
          icon: <FaSort />,
          title: "Select a Category",
          description:
            "Proin dapibus nisl ornare diam varius ecos tempus. Aenean a quam",
        },
        {
          id: 4,
          icon: <FiCheckCircle />,
          title: "See Result",
          description:
            "Proin dapibus nisl ornare diam varius ecos tempus. Aenean a quam",
        },
      ],
    },
    hiw7: {
      sectitle: "Why Choose Us",
      seccontent:
        "Morbi convallis bibendum urna ut viverra. Maecenas quis consequat libero, a feugiat eros. Nunc ut lacinia tortors.",
      items: [
        {
          icon: <FiHeadphones />,
          title: "24/7 Hours Support",
          desc: "There are many variations of passages of Lorem Ipsum available. Aenean a quam luctus, finibus tellus",
        },
        {
          icon: <FaCogs />,
          title: "Admin Panel",
          desc: "There are many variations of passages of Lorem Ipsum available. Aenean a quam luctus, finibus tellus",
        },
        {
          icon: <FiThumbsUp />,
          title: "Mobile friendly",
          desc: "There are many variations of passages of Lorem Ipsum available. Aenean a quam luctus, finibus tellus",
        },
      ],
    },
  },
  placesgrid: [
    {
      bedge: "New",
      title: "Property Name",
      status: "badge",
      titleUrl: "/listing-details",
      stitle: "Location, Block",
      image: img2,
      number: "Listing Price",
      website: "Agent in Charge",
      date: "Post date",
    },
    {
      bedge: "Re-opened",
      title: "Property Name",
      status: "badge badge-reopened",
      titleUrl: "/listing-details",
      stitle: "Location, Block",
      image: img2,
      number: "Listing Price",
      website: "Agent in Charge",
      date: "Post date",
    },
    {
      bedge: "Sold",
      title: "Property Name",
      status: "badge badge-closed",
      titleUrl: "/listing-details",
      stitle: "Location, Block",
      image: img2,
      number: "Listing Price",
      website: "Agent in Charge",
      date: "Post date",
    },
    {
      bedge: "New",
      title: "Hotel Govendor",
      status: "badge",
      titleUrl: "/listing-details",
      stitle: "Bishop Avenue, New York",
      image: img2,
      cardType: "Hotel",
      cardTypeIcon: <RiHotelBedLine />,
      author: authorimg,
      authorUrl: "#",
      number: "(492) 492-4828",
      website: "www.mysitelink.com",
      date: "Posted 1 month ago",
      view: "248",
      ratings: [
        <IoMdStar />,
        <IoMdStar />,
        <IoMdStar />,
        <IoMdStarHalf />,
        <IoMdStar className="last-star" />,
      ],
      ratingNum: "4.6",
    },
    {
      bedge: "New",
      title: "Hotel Govendor",
      status: "badge",
      titleUrl: "/listing-details",
      stitle: "Bishop Avenue, New York",
      image: img2,
      cardType: "Hotel",
      cardTypeIcon: <RiHotelBedLine />,
      author: authorimg,
      authorUrl: "#",
      number: "(492) 492-4828",
      website: "www.mysitelink.com",
      date: "Posted 1 month ago",
      view: "248",
      ratings: [
        <IoMdStar />,
        <IoMdStar />,
        <IoMdStar />,
        <IoMdStarHalf />,
        <IoMdStar className="last-star" />,
      ],
      ratingNum: "4.6",
    },
    {
      bedge: "New",
      title: "Hotel Govendor",
      status: "badge",
      titleUrl: "/listing-details",
      stitle: "Bishop Avenue, New York",
      image: img2,
      cardType: "Hotel",
      cardTypeIcon: <RiHotelBedLine />,
      author: authorimg,
      authorUrl: "#",
      number: "(492) 492-4828",
      website: "www.mysitelink.com",
      date: "Posted 1 month ago",
      view: "248",
      ratings: [
        <IoMdStar />,
        <IoMdStar />,
        <IoMdStar />,
        <IoMdStarHalf />,
        <IoMdStar className="last-star" />,
      ],
      ratingNum: "4.6",
    },
  ],
  placesgridmoreitems: [
    {
      bedge: "New Open",
      title: "Favorite Place Food Bank",
      status: "badge",
      titleUrl: "/listing-details",
      stitle: "Bishop Avenue, New York",
      image: img1,
      cardType: "Restaurant",
      cardTypeIcon: <GiChickenOven />,
      author: authorimg,
      authorUrl: "#",
      number: "(492) 492-4828",
      website: "www.mysitelink.com",
      date: "Posted 1 month ago",
      view: "204",
      ratings: [
        <IoMdStar />,
        <IoMdStar />,
        <IoMdStar />,
        <IoMdStarHalf />,
        <IoMdStar className="last-star" />,
      ],
      ratingNum: "4.5",
    },
    {
      bedge: "Closed",
      title: "beach blue boardwalk",
      status: "badge",
      titleUrl: "/listing-details",
      stitle: "Bishop Avenue, New York",
      image: img2,
      cardType: "Travel",
      cardTypeIcon: <RiPlaneLine />,
      author: authorimg,
      authorUrl: "#",
      number: "(492) 492-4828",
      website: "www.mysitelink.com",
      date: "Posted 1 month ago",
      view: "248",
      ratings: [
        <IoMdStar />,
        <IoMdStar />,
        <IoMdStar />,
        <IoMdStarHalf />,
        <IoMdStar className="last-star" />,
      ],
      ratingNum: "4.6",
    },
    {
      bedge: "New Open",
      title: "Hotel Govendor",
      status: "badge",
      titleUrl: "/listing-details",
      stitle: "Bishop Avenue, New York",
      image: img2,
      cardType: "Hotel",
      cardTypeIcon: <RiHotelBedLine />,
      author: authorimg,
      authorUrl: "#",
      number: "(492) 492-4828",
      website: "www.mysitelink.com",
      date: "Posted 1 month ago",
      view: "248",
      ratings: [
        <IoMdStar />,
        <IoMdStar />,
        <IoMdStar />,
        <IoMdStarHalf />,
        <IoMdStar className="last-star" />,
      ],
      ratingNum: "4.6",
    },
    {
      bedge: "New Open",
      title: "Favorite Place Food Bank",
      status: "badge",
      titleUrl: "/listing-details",
      stitle: "Bishop Avenue, New York",
      image: img2,
      cardType: "Restaurant",
      cardTypeIcon: <GiChickenOven />,
      author: authorimg,
      authorUrl: "#",
      number: "(492) 492-4828",
      website: "www.mysitelink.com",
      date: "Posted 1 month ago",
      view: "204",
      ratings: [
        <IoMdStar />,
        <IoMdStar />,
        <IoMdStar />,
        <IoMdStarHalf />,
        <IoMdStar className="last-star" />,
      ],
      ratingNum: "4.5",
    },
    {
      bedge: "Closed",
      title: "beach blue boardwalk",
      status: "badge",
      titleUrl: "/listing-details",
      stitle: "Bishop Avenue, New York",
      image: img2,
      cardType: "Travel",
      cardTypeIcon: <RiPlaneLine />,
      author: authorimg,
      authorUrl: "#",
      number: "(492) 492-4828",
      website: "www.mysitelink.com",
      date: "Posted 1 month ago",
      view: "248",
      ratings: [
        <IoMdStar />,
        <IoMdStar />,
        <IoMdStar />,
        <IoMdStarHalf />,
        <IoMdStar className="last-star" />,
      ],
      ratingNum: "4.6",
    },
    {
      bedge: "New Open",
      title: "Hotel Govendor",
      status: "badge",
      titleUrl: "/listing-details",
      stitle: "Bishop Avenue, New York",
      image: img2,
      cardType: "Hotel",
      cardTypeIcon: <RiHotelBedLine />,
      author: authorimg,
      authorUrl: "#",
      number: "(492) 492-4828",
      website: "www.mysitelink.com",
      date: "Posted 1 month ago",
      view: "248",
      ratings: [
        <IoMdStar />,
        <IoMdStar />,
        <IoMdStar />,
        <IoMdStarHalf />,
        <IoMdStar className="last-star" />,
      ],
      ratingNum: "4.6",
    },
    {
      bedge: "Closed",
      title: "beach blue boardwalk",
      status: "badge badge-closed",
      titleUrl: "/listing-details",
      stitle: "Bishop Avenue, New York",
      image: img2,
      cardType: "Travel",
      cardTypeIcon: <RiPlaneLine />,
      author: authorimg,
      authorUrl: "#",
      number: "(492) 492-4828",
      website: "www.mysitelink.com",
      date: "Posted 1 month ago",
      view: "248",
      ratings: [
        <IoMdStar />,
        <IoMdStar />,
        <IoMdStar />,
        <IoMdStarHalf />,
        <IoMdStar className="last-star" />,
      ],
      ratingNum: "4.6",
    },
    {
      bedge: "New Open",
      title: "Hotel Govendor",
      titleIcon: <IoIosCheckmarkCircle />,
      titleUrl: "/listing-details",
      stitle: "Bishop Avenue, New York",
      image: img2,
      cardType: "Hotel",
      cardTypeIcon: <RiHotelBedLine />,
      author: authorimg,
      authorUrl: "#",
      number: "(492) 492-4828",
      website: "www.mysitelink.com",
      date: "Posted 1 month ago",
      view: "248",
      ratings: [
        <IoMdStar />,
        <IoMdStar />,
        <IoMdStar />,
        <IoMdStarHalf />,
        <IoMdStar className="last-star" />,
      ],
      ratingNum: "4.6",
    },
  ],
  listing: {
    sidebar: {
      widgetFilterCategory: {
        title: "Filter by Category",
        items: [
          {
            id: 1,
            cat: "Travel",
            catNum: 11,
          },
          {
            id: 2,
            cat: "Arts",
            catNum: 22,
          },
          {
            id: 3,
            cat: "Technology",
            catNum: 9,
          },
          {
            id: 4,
            cat: "Fashion",
            catNum: 11,
          },
          {
            id: 5,
            cat: "Technology",
            catNum: 12,
          },
          {
            id: 6,
            cat: "Lifestyle",
            catNum: 48,
          },
          {
            id: 7,
            cat: "Design",
            catNum: 23,
          },
          {
            id: 8,
            cat: "Computer",
            catNum: 28,
          },
          {
            id: 9,
            cat: "Jobs",
            catNum: 24,
          },
          {
            id: 10,
            cat: "Real Estates",
            catNum: 40,
          },
          {
            id: 11,
            cat: "Clothing",
            catNum: 23,
          },
          {
            id: 12,
            cat: "Beauty & Spa",
            catNum: 18,
          },
          {
            id: 13,
            cat: "Event",
            catNum: 65,
          },
          {
            id: 14,
            cat: "Health & Fitness",
            catNum: 34,
          },
          {
            id: 15,
            cat: "Restaurants",
            catNum: 20,
          },
        ],
      },
      widgetFilterTags: {
        title: "Filter by Tags",
        tags: [
          {
            path: "#",
            title: "technology",
          },
          {
            path: "#",
            title: "fashion",
          },
          {
            path: "#",
            title: "art",
          },
          {
            path: "#",
            title: "design",
          },
          {
            path: "#",
            title: "food",
          },
          {
            path: "#",
            title: "development",
          },
          {
            path: "#",
            title: "marketing",
          },
          {
            path: "#",
            title: "video",
          },
          {
            path: "#",
            title: "music",
          },
          {
            path: "#",
            title: "lifestyle",
          },
          {
            path: "#",
            title: "adventure",
          },
        ],
      },
      widgetFilterFeatures: {
        title: "Filter by Features",
        features: [
          {
            id: 1,
            text: "Elevator in building",
          },
          {
            id: 2,
            text: "Friendly Workspace",
          },
          {
            id: 3,
            text: "Instant Book",
          },
          {
            id: 4,
            text: "Wireless Internet",
          },
          {
            id: 5,
            text: "Free Parking On Premises",
          },
          {
            id: 6,
            text: "Free Parking On Street",
          },
          {
            id: 7,
            text: "Smoking Allowed",
          },
          {
            id: 8,
            text: "Events",
          },
          {
            id: 9,
            text: "Wheelchair Accessible",
          },
          {
            id: 10,
            text: "Alarm System",
          },
          {
            id: 11,
            text: "Electricity",
          },
          {
            id: 12,
            text: "Attached Garage",
          },
          {
            id: 13,
            text: "Security Cameras",
          },
        ],
      },
      widgetSortby: {
        title: "Sort by",
        items: [
          {
            id: 1,
            title: "Best sellers",
          },
          {
            id: 2,
            title: "Newest",
          },
          {
            id: 3,
            title: "Best Rated",
          },
          {
            id: 4,
            title: "Oldest",
          },
        ],
      },
      widgetPostedby: {
        title: "Posted by",
        items: [
          {
            title: "Dealer",
          },
          {
            title: "individual",
          },
          {
            title: "Reseller",
          },
        ],
      },
    },
    listinglists: [
      {
        bedge: "New",
        title: "Property Name",
        status: "badge",
        titleUrl: "/listing-details",
        stitle: "Location, Block",
        image: img2,
        number: "Listing Price",
        website: "Agent in Charge",
        date: "Post date",
      },
      {
        bedge: "Re-opened",
        title: "Property Name",
        status: "badge badge-reopened",
        titleUrl: "/listing-details",
        stitle: "Location, Block",
        image: img2,
        number: "Listing Price",
        website: "Agent in Charge",
        date: "Post date",
      },
      {
        bedge: "Sold",
        title: "Property Name",
        status: "badge badge-closed",
        titleUrl: "/listing-details",
        stitle: "Location, Block",
        image: img2,
        number: "Listing Price",
        website: "Agent in Charge",
        date: "Post date",
      },
      {
        bedge: "New",
        title: "Hotel Govendor",
        status: "badge",
        titleUrl: "/listing-details",
        stitle: "Bishop Avenue, New York",
        image: img2,
        cardType: "Hotel",
        cardTypeIcon: <RiHotelBedLine />,
        author: authorimg,
        authorUrl: "#",
        number: "(492) 492-4828",
        website: "www.mysitelink.com",
        date: "Posted 1 month ago",
        view: "248",
        ratings: [
          <IoMdStar />,
          <IoMdStar />,
          <IoMdStar />,
          <IoMdStarHalf />,
          <IoMdStar className="last-star" />,
        ],
        ratingNum: "4.6",
      },
    ],
  },
  listingDetails: {
    title: "Tasty Hand-Pulled Noodles",
    description:
      "Nemo ucxqui officia voluptatem accu santium doloremque laudantium, totam rem ape dicta sunt dose explicabo. Nemo enim ipsam voluptatem quia voluptas. Excepteur sint occaecat cupidatat non proident, sunt in culpa kequi officia deserunt mollit anim id est laborum. Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusan tium dolorem que laudantium, totam rem aperiam the eaque ipsa quae abillo was inventore veritatis keret quasi aperiam architecto beatae vitae dicta sunt explicabo. Lorem ipsum dolor sit amet, consectetur adipisicing elit.",
    descriptiontitle: "Description",
    featurestitle: "Features",
    featureslists: [
      {
        icon: <BsCheckCircle />,
        title: "Instant Noodles",
      },
      {
        icon: <BsCheckCircle />,
        title: "Smoking Allowed",
      },
      {
        icon: <BsCheckCircle />,
        title: "Bike Parking",
      },
      {
        icon: <BsCheckCircle />,
        title: "Wireless Internet",
      },
      {
        icon: <BsCheckCircle />,
        title: "Street Parking",
      },
      {
        icon: <BsCheckCircle />,
        title: "Accepts Credit Cards",
      },
    ],
    videotitle: "Video",
    videoImg: listingdetailsvideoimg,
    videoid: "R2kiP9Qu7Pc",
    videobtn: "Watch Video",
    contactinfos: {
      title: "Contact Information",
      address: "101 East Parkview Road, New York",
      email: "example@gmail.com",
      number: "+7(111)123456789",
      website: "www.techydevs.com",
      websiteUrl: "https://techydevs.com",
      socials: [
        {
          icon: <FaFacebookF />,
          title: "facebook",
          url: "https://facebook.com",
        },
        {
          icon: <FaTwitter />,
          title: "twitter",
          url: "https://twitter.com",
        },
        {
          icon: <FaInstagram />,
          title: "instagram",
          url: "https://instagram.com",
        },
        {
          icon: <FaLinkedinIn />,
          title: "linkedinIn",
          url: "https://linkedin.com",
        },
        {
          icon: <FaYoutube />,
          title: "youtube",
          url: "https://youtube.com",
        },
      ],
    },
    comments: [
      {
        img: team1,
        name: "Adam Smith",
        date: "Reviewed 2 Days ago",
        content:
          "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation. Curabitur non nulla sit amet nisl tempus",
        stars: [<MdStar />, <MdStar />, <MdStar />, <MdStar />, <MdStarHalf />],
        replyBtn: "Reply",
        replyBtnIcon: <RiReplyLine />,
        replyComments: [
          {
            img: team1,
            name: "Julian Smith",
            date: "Reviewed 3 Days ago",
            content:
              "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation. Curabitur non nulla sit amet nisl tempus",
            stars: [
              <MdStar />,
              <MdStar />,
              <MdStar />,
              <MdStar />,
              <MdStarHalf />,
            ],
            replyBtn: "Reply",
            replyBtnIcon: <RiReplyLine />,
          },
        ],
      },
      {
        img: team1,
        name: "Matt Derry",
        date: "Reviewed 4 Days ago",
        content:
          "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation. Curabitur non nulla sit amet nisl tempus",
        stars: [<MdStar />, <MdStar />, <MdStar />, <MdStar />, <MdStar />],
        replyBtn: "Reply",
        replyBtnIcon: <RiReplyLine />,
        replyComments: [],
      },
    ],
    sidebar: {
      widgetAuthor: {
        authorImg: team2,
        authorName: "Mark Williamson",
        date: "Posted 3 Days ago",
        address: "101 Parkview, New York",
        email: "example@gmail.com",
        number: "+7(111)123456789",
        website: "www.techydevs.com",
        websiteUrl: "https://techydevs.com",
        socials: [
          {
            icon: <FaFacebookF />,
            url: "https://facebook.com",
          },
          {
            icon: <FaTwitter />,
            url: "https://twitter.com",
          },
          {
            icon: <FaInstagram />,
            url: "https://instagram.com",
          },
          {
            icon: <FaDribbble />,
            url: "https://dribbble.com",
          },
          {
            icon: <FaBehance />,
            url: "https://behance.be",
          },
        ],
      },
      widgetStaticsInfo: {
        title: "Statics info",
        lists: [
          {
            icon: <BsListCheck />,
            text: "12 Listings",
          },
          {
            icon: <MdStarBorder />,
            text: "4.4 Ratings",
          },
          {
            icon: <BsBookmark />,
            text: "24 Bookmark",
          },
          {
            icon: <BsEye />,
            text: "745 Views",
          },
          {
            icon: <FiExternalLink />,
            text: "120 Share",
          },
          {
            icon: <FaRegComment />,
            text: "20 Comments",
          },
          {
            icon: <FiHeart />,
            text: "120 Likes",
          },
        ],
      },
      widgetOpenHours: {
        title: "Opening Hours",
        stitle: "now open",
        items: [
          {
            day: "Monday",
            time: "9am - 5pm",
            close: false,
          },
          {
            day: "Tuesday",
            time: "9am - 5pm",
            close: false,
          },
          {
            day: "Wednesday",
            time: "9am - 5pm",
            close: false,
          },
          {
            day: "Thursday",
            time: "9am - 5pm",
            close: false,
          },
          {
            day: "Friday",
            time: "9am - 5pm",
            close: false,
          },
          {
            day: "Sat-Sun",
            time: "Closed",
            close: true,
          },
        ],
      },
      widgetCategories: {
        title: "Categories",
        lists: [
          {
            cat: "Restaurant",
            catNum: 11,
            url: "#",
          },
          {
            cat: "Shop",
            catNum: 32,
            url: "#",
          },
          {
            cat: "Fitness",
            catNum: 21,
            url: "#",
          },
          {
            cat: "Event",
            catNum: 24,
            url: "#",
          },
          {
            cat: "Bar",
            catNum: 14,
            url: "#",
          },
          {
            cat: "Salon",
            catNum: 11,
            url: "#",
          },
          {
            cat: "Beauty",
            catNum: 10,
            url: "#",
          },
        ],
      },
      widgetTags: {
        title: "Tags Cloud",
        lists: [
          {
            text: "Travel",
            url: "#",
          },
          {
            text: "Restaurant",
            url: "#",
          },
          {
            text: "Gym",
            url: "#",
          },
          {
            text: "Food",
            url: "#",
          },
          {
            text: "Office",
            url: "#",
          },
          {
            text: "Bar",
            url: "#",
          },
          {
            text: "Health",
            url: "#",
          },
          {
            text: "Car Parking",
            url: "#",
          },
          {
            text: "Nightlife",
            url: "#",
          },
          {
            text: "Doctor",
            url: "#",
          },
          {
            text: "Bike Parking",
            url: "#",
          },
        ],
      },
      widgetSimilarListing: {
        title: "Similar Listings",
        items: [
          {
            img: similarimg,
            title: "The best sale marketer of the next year",
            titleUrl: "/blog-single",
            price: "$19.00",
            cat: "Gym & Fitness",
            catUrl: "#",
            stars: [
              <MdStar />,
              <MdStar />,
              <MdStar />,
              <MdStar />,
              <MdStarHalf />,
            ],
          },
          {
            img: similarimg,
            title: "How to make your ideas became true",
            titleUrl: "/blog-single",
            price: "$20.00",
            cat: "Restaurant",
            catUrl: "#",
            stars: [
              <MdStar />,
              <MdStar />,
              <MdStar />,
              <MdStar />,
              <MdStarHalf />,
            ],
          },
          {
            img: similarimg,
            title: "What gets in the way of strategy",
            titleUrl: "/blog-single",
            price: "$19.00",
            cat: "Art & Design",
            catUrl: "#",
            stars: [
              <MdStar />,
              <MdStar />,
              <MdStar />,
              <MdStar />,
              <MdStarHalf />,
            ],
          },
          {
            img: similarimg,
            title: "What gets in the way of strategy",
            titleUrl: "/blog-single",
            price: "$19.00",
            cat: "Outdoors",
            catUrl: "#",
            stars: [
              <MdStar />,
              <MdStar />,
              <MdStar />,
              <MdStar />,
              <MdStarHalf />,
            ],
          },
        ],
      },
      widgetFollowConnect: {
        title: "Follow & Connect",
        socials: [
          {
            icon: <FaFacebookF />,
            url: "https://facebook.com",
          },
          {
            icon: <FaTwitter />,
            url: "https://twitter.com",
          },
          {
            icon: <FaInstagram />,
            url: "https://instagram.com",
          },
          {
            icon: <FaDribbble />,
            url: "https://dribbble.com",
          },
          {
            icon: <FaBehance />,
            url: "https://behance.be",
          },
        ],
      },
    },
  },
  userprofile: {
    sidebar: {
      img: team2,
      name: "Mark Williamson",
      date: "Joined 4 years ago",
      address: "101 Parkview, New York",
      number: "+7(111)123456789",
      email: "example@gmail.com",
      website: "www.techydevs.com",
      websiteUrl: "https://techydevs.com",
      socials: [
        {
          icon: <FaFacebookF />,
          url: "https://facebook.com",
        },
        {
          icon: <FaTwitter />,
          url: "https://twitter.com",
        },
        {
          icon: <FaInstagram />,
          url: "https://instagram.com",
        },
        {
          icon: <FaDribbble />,
          url: "https://dribbble.com",
        },
        {
          icon: <FaBehance />,
          url: "https://behance.be",
        },
      ],
    },
  },
  dashboard: {
    breadcrumbimg: breadcrumbimg,
    cards: [
      {
        img: img2,
        title: "Favorite Place Food Bank",
        subtitle: "Bishop Avenue, New York",
        editTxt: "Edit",
        editIcon: <FaRegEdit />,
        deleteTxt: "Delete",
        deleteIcon: <FaRegTrashAlt />,
        cardLink: "/listing-details",
      },
      {
        img: img2,
        title: "Beach Blue Boardwalk",
        subtitle: "Bishop Avenue, New York",
        editTxt: "Edit",
        editIcon: <FaRegEdit />,
        deleteTxt: "Delete",
        deleteIcon: <FaRegTrashAlt />,
        cardLink: "/listing-details",
      },
      {
        img: img2,
        title: "Hotel Govendor",
        subtitle: "Bishop Avenue, New York",
        editTxt: "Edit",
        editIcon: <FaRegEdit />,
        deleteTxt: "Delete",
        deleteIcon: <FaRegTrashAlt />,
        cardLink: "/listing-details",
      },
      {
        img: img2,
        title: "Favorite Place Food Bank",
        subtitle: "Bishop Avenue, New York",
        editTxt: "Edit",
        editIcon: <FaRegEdit />,
        deleteTxt: "Delete",
        deleteIcon: <FaRegTrashAlt />,
        cardLink: "/listing-details",
      },
      {
        img: img2,
        title: "Beach Blue Boardwalk",
        subtitle: "Bishop Avenue, New York",
        editTxt: "Edit",
        editIcon: <FaRegEdit />,
        deleteTxt: "Delete",
        deleteIcon: <FaRegTrashAlt />,
        cardLink: "/listing-details",
      },
      {
        img: img2,
        title: "Hotel Govendor",
        subtitle: "Bishop Avenue, New York",
        editTxt: "Edit",
        editIcon: <FaRegEdit />,
        deleteTxt: "Delete",
        deleteIcon: <FaRegTrashAlt />,
        cardLink: "/listing-details",
      },
    ],
    userImg: userimage,
    userName: "Mark Williamson",
    userbio:
      "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium",
    address: "101 Parkview, New York",
    phoneNum: "+7(111)123456789",
    website: "www.techydevs.com",
  },
  teamdata: {
    sectitle: "Our Expert Team Members",
    seccontent:
      "Morbi convallis bibendum urna ut viverra. Maecenas quis consequat libero, a feugiat eros. Nunc ut lacinia tortors.",
    teams: [
      {
        img: userimage,
        title: "Kamal Ahmed",
        titleUrl: "#",
        stitle: "Business Consultant",
        socials: [
          {
            icon: <FaFacebookF />,
            url: "https://facebook.com",
          },
          {
            icon: <FaTwitter />,
            url: "https://twitter.com",
          },
          {
            icon: <FaInstagram />,
            url: "https://instagram.com",
          },
          {
            icon: <FaLinkedinIn />,
            url: "https://linkedin.com",
          },
        ],
      },
      {
        img: userimage,
        title: "Kamran Ahmed",
        titleUrl: "#",
        stitle: "Photographer",
        socials: [
          {
            icon: <FaFacebookF />,
            url: "https://facebook.com",
          },
          {
            icon: <FaTwitter />,
            url: "https://twitter.com",
          },
          {
            icon: <FaInstagram />,
            url: "https://instagram.com",
          },
          {
            icon: <FaLinkedinIn />,
            url: "https://linkedin.com",
          },
        ],
      },
      {
        img: userimage,
        title: "Tanbir Ahmed",
        titleUrl: "#",
        stitle: "Co-manager associated",
        socials: [
          {
            icon: <FaFacebookF />,
            url: "https://facebook.com",
          },
          {
            icon: <FaTwitter />,
            url: "https://twitter.com",
          },
          {
            icon: <FaInstagram />,
            url: "https://instagram.com",
          },
          {
            icon: <FaLinkedinIn />,
            url: "https://linkedin.com",
          },
        ],
      },
      {
        img: userimage,
        title: "Juhen Ahmed",
        titleUrl: "#",
        stitle: "Director",
        socials: [
          {
            icon: <FaFacebookF />,
            url: "https://facebook.com",
          },
          {
            icon: <FaTwitter />,
            url: "https://twitter.com",
          },
          {
            icon: <FaInstagram />,
            url: "https://instagram.com",
          },
          {
            icon: <FaLinkedinIn />,
            url: "https://linkedin.com",
          },
        ],
      },
    ],
  },
  pricingplan: [
    {
      icon: <IoMdPaperPlane />,
      title: "Basic Plan",
      price: "49",
      currency: "$",
      mo: "Per Month",
      features: [
        {
          icon: <FiCheck />,
          iconClr: "text-success",
          title: "One Listing",
        },
        {
          icon: <FiCheck />,
          iconClr: "text-success",
          title: "90 Days Availability",
        },
        {
          icon: <FiCheck />,
          iconClr: "text-success",
          title: "Non-Featured",
        },
        {
          icon: <FiCheck />,
          iconClr: "text-success",
          title: "Limited Support",
        },
        {
          icon: <MdClose />,
          iconClr: "text-danger",
          title: "Average Price Range",
        },
        {
          icon: <MdClose />,
          iconClr: "text-danger",
          title: "Business Hours",
        },
        {
          icon: <MdClose />,
          iconClr: "text-danger",
          title: "Lifetime Availability",
        },
        {
          icon: <MdClose />,
          iconClr: "text-danger",
          title: "Featured In Search Results",
        },
      ],
      buttonTxt: "Continue",
      buttonUrl: "#",
      active: false,
    },
    {
      icon: <RiPlaneLine />,
      title: "Advanced Plan",
      price: "99",
      currency: "$",
      mo: "Per Month",
      features: [
        {
          icon: <FiCheck />,
          iconClr: "text-success",
          title: "One Listing",
        },
        {
          icon: <FiCheck />,
          iconClr: "text-success",
          title: "90 Days Availability",
        },
        {
          icon: <FiCheck />,
          iconClr: "text-success",
          title: "Non-Featured",
        },
        {
          icon: <FiCheck />,
          iconClr: "text-success",
          title: "Limited Support",
        },
        {
          icon: <FiCheck />,
          iconClr: "text-success",
          title: "Average Price Range",
        },
        {
          icon: <FiCheck />,
          iconClr: "text-success",
          title: "Business Hours",
        },
        {
          icon: <MdClose />,
          iconClr: "text-danger",
          title: "Lifetime Availability",
        },
        {
          icon: <MdClose />,
          iconClr: "text-danger",
          title: "Featured In Search Results",
        },
      ],
      buttonTxt: "Continue",
      buttonUrl: "#",
      active: true,
    },
    {
      icon: <IoIosRocket />,
      title: "Enterprise Plan",
      price: "149",
      currency: "$",
      mo: "Per Month",
      features: [
        {
          icon: <FiCheck />,
          iconClr: "text-success",
          title: "One Listing",
        },
        {
          icon: <FiCheck />,
          iconClr: "text-success",
          title: "90 Days Availability",
        },
        {
          icon: <FiCheck />,
          iconClr: "text-success",
          title: "Non-Featured",
        },
        {
          icon: <FiCheck />,
          iconClr: "text-success",
          title: "Limited Support",
        },
        {
          icon: <FiCheck />,
          iconClr: "text-success",
          title: "Average Price Range",
        },
        {
          icon: <FiCheck />,
          iconClr: "text-success",
          title: "Business Hours",
        },
        {
          icon: <FiCheck />,
          iconClr: "text-success",
          title: "Lifetime Availability",
        },
        {
          icon: <FiCheck />,
          iconClr: "text-success",
          title: "Featured In Search Results",
        },
      ],
      buttonTxt: "Continue",
      buttonUrl: "#",
      active: false,
    },
  ],
  aboutdata: {
    imagebox: [
      {
        img: imgboximg,
        title: "Careers",
        desc: "Sed ut perspiciatis unde omnis iste natus error sit voluptatem eaque ipsa quae ab illo",
        cardLink: "#",
      },
      {
        img: imgboximg,
        title: "Newsroom",
        desc: "Sed ut perspiciatis unde omnis iste natus error sit voluptatem eaque ipsa quae ab illo",
        cardLink: "#",
      },
      {
        img: imgboximg,
        title: "Investor Relations",
        desc: "Sed ut perspiciatis unde omnis iste natus error sit voluptatem eaque ipsa quae ab illo",
        cardLink: "#",
      },
    ],
    aboutst2: {
      title:
        "Welcome to the Dirto Business Directory Service and a Public Company",
      content1:
        "Ut euismod ultricies sollicitudin. Curabitur sed dapibus nulla. Nulla eget iaculis lectus. Mauris ac maximus neque. Nam in mauris quis libero sodales eleifend.",
      content2:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas in pulvinar neque. Nulla finibus lobortis pulvinar.",
      items: [
        {
          img: about2img,
          boxClass: "mt-4",
        },
        {
          img: about2img,
          boxClass: "",
        },
        {
          img: about2img,
          boxClass: "mt-4",
        },
        {
          img: about2img,
          boxClass: "",
        },
      ],
    },
  },
  mostvisitedplaces: {
    sectitle: "Most Visited Places",
    seccontent:
      "Morbi convallis bibendum urna ut viverra. Maecenas quis consequat libero, a feugiat eros. Nunc ut lacinia tortors.",
    places: [
      {
        bedge: "New Open",
        title: "Hotel Govendor",
        titleIcon: <IoIosCheckmarkCircle />,
        titleUrl: "/listing-details",
        stitle: "Bishop Avenue, New York",
        image: img1,
        cardType: "Hotel",
        cardTypeIcon: <RiHotelBedLine />,
        author: authorimg,
        authorUrl: "#",
        number: "(492) 492-4828",
        website: "www.mysitelink.com",
        websiteUrl: "https://example.com",
        date: "Posted 1 month ago",
        view: "204",
        ratings: [
          <IoMdStar />,
          <IoMdStar />,
          <IoMdStar />,
          <IoMdStarHalf />,
          <IoMdStar className="last-star" />,
        ],
        ratingNum: "4.7",
      },
      {
        bedge: "New Open",
        title: "Favorite Place Food Bank",
        titleIcon: <IoIosCheckmarkCircle />,
        titleUrl: "/listing-details",
        stitle: "Bishop Avenue, New York",
        image: img2,
        cardType: "Restaurant",
        cardTypeIcon: <GiChickenOven />,
        author: authorimg,
        authorUrl: "#",
        number: "(492) 492-4828",
        website: "www.mysitelink.com",
        date: "Posted 1 month ago",
        view: "204",
        ratings: [
          <IoMdStar />,
          <IoMdStar />,
          <IoMdStar />,
          <IoMdStarHalf />,
          <IoMdStar className="last-star" />,
        ],
        ratingNum: "4.5",
      },
      {
        bedge: "Closed",
        title: "beach blue boardwalk",
        titleIcon: "",
        titleUrl: "/listing-details",
        stitle: "Bishop Avenue, New York",
        image: img2,
        cardType: "Travel",
        cardTypeIcon: <GiChickenOven />,
        author: authorimg,
        authorUrl: "#",
        number: "(492) 492-4828",
        website: "www.mysitelink.com",
        date: "Posted 1 month ago",
        view: "248",
        ratings: [
          <IoMdStar />,
          <IoMdStar />,
          <IoMdStar />,
          <IoMdStarHalf />,
          <IoMdStar className="last-star" />,
        ],
        ratingNum: "4.6",
      },
      {
        bedge: "New Open",
        title: "Hotel Govendor",
        titleIcon: <IoIosCheckmarkCircle />,
        titleUrl: "/listing-details",
        stitle: "Bishop Avenue, New York",
        image: img2,
        cardType: "Hotel",
        cardTypeIcon: <RiHotelBedLine />,
        author: authorimg,
        authorUrl: "#",
        number: "(492) 492-4828",
        website: "www.mysitelink.com",
        date: "Posted 1 month ago",
        view: "248",
        ratings: [
          <IoMdStar />,
          <IoMdStar />,
          <IoMdStar />,
          <IoMdStarHalf />,
          <IoMdStar className="last-star" />,
        ],
        ratingNum: "4.6",
      },
      {
        bedge: "New Open",
        title: "sticky band party",
        titleIcon: <IoIosCheckmarkCircle />,
        titleUrl: "/listing-details",
        stitle: "Bishop Avenue, New York",
        image: img2,
        cardType: "Event",
        cardTypeIcon: <IoMdMusicalNotes />,
        author: authorimg,
        authorUrl: "#",
        number: "(492) 492-4828",
        website: "www.mysitelink.com",
        date: "Posted 1 month ago",
        view: "248",
        ratings: [
          <IoMdStar />,
          <IoMdStar />,
          <IoMdStar />,
          <IoMdStarHalf />,
          <IoMdStar className="last-star" />,
        ],
        ratingNum: "4.4",
      },
      {
        bedge: "Closed",
        title: "Sena Clothing Shopping Mall",
        titleIcon: "",
        titleUrl: "/listing-details",
        stitle: "Bishop Avenue, New York",
        image: img2,
        cardType: "Shop",
        cardTypeIcon: <GiChickenOven />,
        author: authorimg,
        authorUrl: "#",
        number: "(492) 492-4828",
        website: "www.mysitelink.com",
        date: "Posted 1 month ago",
        view: "248",
        ratings: [
          <IoMdStar />,
          <IoMdStar />,
          <IoMdStar />,
          <IoMdStarHalf />,
          <IoMdStar className="last-star" />,
        ],
        ratingNum: "4.6",
      },
      {
        bedge: "New Open",
        title: "Hotel Govendor",
        titleIcon: <IoIosCheckmarkCircle />,
        titleUrl: "/listing-details",
        stitle: "Bishop Avenue, New York",
        image: img2,
        cardType: "Hotel",
        cardTypeIcon: <RiHotelBedLine />,
        author: authorimg,
        authorUrl: "#",
        number: "(492) 492-4828",
        website: "www.mysitelink.com",
        date: "Posted 1 month ago",
        view: "204",
        ratings: [
          <IoMdStar />,
          <IoMdStar />,
          <IoMdStar />,
          <IoMdStarHalf />,
          <IoMdStar className="last-star" />,
        ],
        ratingNum: "4.7",
      },
      {
        bedge: "New Open",
        title: "Favorite Place Food Bank",
        titleIcon: <IoIosCheckmarkCircle />,
        titleUrl: "/listing-details",
        stitle: "Bishop Avenue, New York",
        image: img2,
        cardType: "Restaurant",
        cardTypeIcon: <GiChickenOven />,
        author: authorimg,
        authorUrl: "#",
        number: "(492) 492-4828",
        website: "www.mysitelink.com",
        date: "Posted 1 month ago",
        view: "204",
        ratings: [
          <IoMdStar />,
          <IoMdStar />,
          <IoMdStar />,
          <IoMdStarHalf />,
          <IoMdStar className="last-star" />,
        ],
        ratingNum: "4.5",
      },
    ],
  },
  recommendedplaces: {
    sectitle: "Places We Recommend You Visit",
    seccontent:
      "Handpicked places by our team,Morbi convallis bibendum urna ut viverra. Maecenas quis consequat libero,",
    items: [
      {
        bedge: "New Open",
        title: "Favorite Place Food Bank",
        titleIcon: <IoIosCheckmarkCircle />,
        titleUrl: "/listing-details",
        stitle: "Bishop Avenue, New York",
        image: destinationimg6,
        cardType: "Restaurant",
        cardTypeIcon: <GiChickenOven />,
        author: authorimg,
        authorUrl: "#",
        number: "(492) 492-4828",
        website: "www.mysitelink.com",
        date: "Posted 1 month ago",
        view: "204",
        ratings: [
          <IoMdStar />,
          <IoMdStar />,
          <IoMdStar />,
          <IoMdStarHalf />,
          <IoMdStar className="last-star" />,
        ],
        ratingNum: "4.5",
      },
      {
        bedge: "Closed",
        title: "beach blue boardwalk",
        titleIcon: "",
        titleUrl: "/listing-details",
        stitle: "Bishop Avenue, New York",
        image: destinationimg6,
        cardType: "Travel",
        cardTypeIcon: <RiPlaneLine />,
        author: authorimg,
        authorUrl: "#",
        number: "(492) 492-4828",
        website: "www.mysitelink.com",
        date: "Posted 1 month ago",
        view: "248",
        ratings: [
          <IoMdStar />,
          <IoMdStar />,
          <IoMdStar />,
          <IoMdStarHalf />,
          <IoMdStar className="last-star" />,
        ],
        ratingNum: "4.6",
      },
      {
        bedge: "New Open",
        title: "Hotel Govendor",
        titleIcon: <IoIosCheckmarkCircle />,
        titleUrl: "/listing-details",
        stitle: "Bishop Avenue, New York",
        image: destinationimg6,
        cardType: "Hotel",
        cardTypeIcon: <RiHotelBedLine />,
        author: authorimg,
        authorUrl: "#",
        number: "(492) 492-4828",
        website: "www.mysitelink.com",
        date: "Posted 1 month ago",
        view: "248",
        ratings: [
          <IoMdStar />,
          <IoMdStar />,
          <IoMdStar />,
          <IoMdStarHalf />,
          <IoMdStar className="last-star" />,
        ],
        ratingNum: "4.6",
      },
    ],
  },
  dreamplaces: {
    sectitle: "Explore Your Dream Places",
    seccontent:
      "Explore most popular destination and places, Morbi convallis bibendum urna ut viverra. Maecenas,",
    places: [
      {
        img: dreamimg,
        country: "united states",
        badge: "Explore",
        placeNum: 5,
        listingNum: 250,
        cardColumn: 6,
        cardLink: "#",
      },
      {
        img: dreamimg,
        country: "united kingdom",
        badge: "Explore",
        placeNum: 7,
        listingNum: 220,
        cardColumn: 6,
        cardLink: "#",
      },
      {
        img: dreamimg,
        country: "Australia",
        badge: "Explore",
        placeNum: 8,
        listingNum: 80,
        cardColumn: 4,
        cardLink: "#",
      },
      {
        img: dreamimg,
        country: "New zealand",
        badge: "Explore",
        placeNum: 9,
        listingNum: 190,
        cardColumn: 4,
        cardLink: "#",
      },
      {
        img: dreamimg,
        country: "Russia",
        badge: "Explore",
        placeNum: 11,
        listingNum: 110,
        cardColumn: 4,
        cardLink: "#",
      },
    ],
    loadmoretext: "Load More",
  },
  accordions: {
    sectitle: "Frequently Asked Questions",
    seccontent: "",
    items: [
      {
        title: "What do the color coded ADC lot Types mean?",
        desc: `REGULAR (Green)  - usually interior lots, with access to smallest road in subdivision`,
        desc1: `PRIME (blue) - lots located near subdivision amenities like the park, playground, community facility, or water
        features and along wider roads`,
        desc2: `SPECIAL(pink) - lots located at the corner of subdivision blocks with extended frontage and access to wider roads`,
        desc3: `COMMERCIAL-RESIDENTIAL (Orange) - lots located along the main road of the subdivision especiallyu near the
        entrance of the subdivision`,
        desc4: `PRIME COMMERCIAL RESIDENTIAL  (Yellow) - commercial-residential lots that are also corner lots located along
        entrance/main road in subdivision`,
        plus: <FaPlus />,
        minus: <FaMinus />,
        cardClass: "mb-3",
      },
      {
        title: "When will house construction begin?",
        desc: "House construction will begin when:",
        desc1:
          "a) the downpayment/equity has been initially been paid, with the balance coverd by post-dated checks, and there is initial release of loan from the financing institution",
        desc2:
          "b) the 40% downpayment or equity has been fully paid and the notice of approval from the banks/financing institution",
        plus: <FaPlus />,
        minus: <FaMinus />,
        cardClass: "mb-3",
      },
      {
        title: "Are there houses that are ready for occupancy?",
        desc: "Yes there are. Please contact us for inquiries.",
        plus: <FaPlus />,
        minus: <FaMinus />,
        cardClass: "mb-3",
      },
      {
        title: "What are your payment terms?",
        desc: "We allow downpaymnt terms of 20% of the price to be paid in monthly installment up to 6 months.",
        plus: <FaPlus />,
        minus: <FaMinus />,
        cardClass: "mb-3",
      },
      {
        title: "Do you offer discounts?",
        desc: " you pay the total price all at once you get a discount of 5%. You may also avail of 5% discount on the downpayment if it is paid all at once and not in installments.",
        plus: <FaPlus />,
        minus: <FaMinus />,
        cardClass: "mb-3",
      },
      {
        title: "Is there more affordable financing available?",
        desc: "Home loans are offered by local banks , by  Pagibig/HDMF with your property as collateral, as additional options to in-house financing and progressive billing (for house construction)",
        plus: <FaPlus />,
        minus: <FaMinus />,
        cardClass: "mb-3",
      },
      {
        title: "How much is the interest charged in in-house financing?",
        desc: "Lots purchased on instalments have 22-24 % interest per annum. Houses financing have 16% interest per annum. ",
        plus: <FaPlus />,
        minus: <FaMinus />,
        cardClass: "mb-3",
      },
      {
        title: "How much Interest do banks and pagibig charge?",
        desc: "Their interest ranges from 6-9% per annum with terms of 1-30 years",
        plus: <FaPlus />,
        minus: <FaMinus />,
        cardClass: "mb-3",
      },
      {
        title:
          "How much do we need to pay so the house construction can begin?",
        desc: "Banks require 20% equity/downpayment to be paid to the contractor/developer before the loan release.",
        plus: <FaPlus />,
        minus: <FaMinus />,
        cardClass: "mb-3",
      },
      {
        title: "How long does it take to transfer title of ownership?",
        desc: "It takes 3-5  months upon completion of requirements to transfer titles of ownership.",
        plus: <FaPlus />,
        minus: <FaMinus />,
        cardClass: "mb-3",
      },
      {
        title: "Are payments refundable?",
        desc: "Reservation fees paid are not refundable. Instalment payments may be refundable under Maceda Law.",
        plus: <FaPlus />,
        minus: <FaMinus />,
        cardClass: "mb-3",
        maceda: true,
      },
      {
        title: "Where are ADC projects located? ",
        desc: "We have properties for sale (and lease) in :",
        plus: <FaPlus />,
        minus: <FaMinus />,
        cardClass: "mb-3",
        propertyLocated: true,
      },
      {
        title: "How much do we pay to reserve property?",
        desc: "Select the property you are interested and you can request the reservation.",
        plus: <FaPlus />,
        minus: <FaMinus />,
        cardClass: "mb-3",
        propertyReserve: true,
      },
      {
        title: "May I chat with an agent?",
        desc: "Click the link below to connect on Facebook Messenger.",
        link: "https://www.facebook.com/AncorDevelopmentCorp",
        plus: <FaPlus />,
        minus: <FaMinus />,
        cardClass: "mb-3",
      },
      {
        title: "What will the house look like on the inside when turned over?",
        desc: "Click the link below to view the virtual tour. ",
        link: "https://www.youtube.com/watch?v=N-JER7TXpvQ",
        plus: <FaPlus />,
        minus: <FaMinus />,
        cardClass: "mb-3",
      },
      {
        title: "What do other buyers have to say re the project/s? ",
        desc: "Click the link below for Buyer's Testimonials.",
        link: "https://www.youtube.com/watch?v=OycSi_kxA9Q",
        plus: <FaPlus />,
        minus: <FaMinus />,
        cardClass: "mb-3",
      },
      {
        title:
          "How far is the project to the city proper? What does the project site look like?",
        desc: "Click the link below for each project.",
        plus: <FaPlus />,
        minus: <FaMinus />,
        cardClass: "mb-3",
        vlog: true,
      },
      {
        title:
          "Are houses customisable?  Can  I  make revisions on the floor plan and perspectives of thr house?",
        desc: "We allow clients to customize the houses and we can discuss upgrades or downgrades depending on your budget. ",
        plus: <FaPlus />,
        minus: <FaMinus />,
        cardClass: "mb-3",
      },
      {
        title:
          "What environmentally friendly house features are available for house buyers?",
        desc: "Available house features as inclusions / upgrades are LED lights, solar lights, solar panels, and inverter appliances.",
        plus: <FaPlus />,
        minus: <FaMinus />,
        cardClass: "mb-3",
      },
      {
        title:
          "What typhoon /earthquake resistant house features are available for buyers?",
        desc: "As inclusions/ upgrades we have tempered glass windows (and other glassworks), 6-in Concrete Hollow Blocks, and poured concrete technology.",
        plus: <FaPlus />,
        minus: <FaMinus />,
        cardClass: "mb-3",
      },
      {
        title: "What rust-reistant technology is available for house features?",
        desc: "We can use Stainless steel railings and metal frames, and synthetic plastic and fiberglass substitutes.",
        plus: <FaPlus />,
        minus: <FaMinus />,
        cardClass: "mb-3",
      },
    ],
  },
  contactdata: {
    title: "Our Office",
    img: ADCMap,
    desc: "Mauris aliquet eu quam id ornare. Morbi ac quam enim. Cras vitae nulla condimentum, semper dolor non, faucibus dolor. Praesent eros turpis, commodo vel justo at",
    address:
      "UNIT 1, SAN ANTONIO APARTMENTS, ZAMORA ST. BRGY.V, ROXAS CITY, CAPIZ PHILIPPINES 5800",
    phoneNum: "(036) 621-66499",
    email: "Sarvi.sales@gmail.com",
    opendays: "Monday To Saturday",
    opendaytime: "8am - 5pm",
    closeday: "Sunday",
  },

  clientlogos: [
    {
      name: "ABAI Inc.",
      img: sister1,
      link: "https://www.facebook.com/ADC-Builders-and-Aggregates-Inc-1562405007337219",
      desc: "ADC Builders and Aggregates, Inc. Is the developer of horizontal constructions and provider of aggregates.",
    },
    {
      name: "ABOCGO Land INc.",
      img: sister2,
      link: "https://www.facebook.com/AboCgo-Land-Inc-101745674628807",
      desc: "ABOCGO Land, Inc. Is the property manager for any real estate property, able to lease out and maintain any property for sustained income.",
    },
    {
      name: "San Antonio Resort",
      img: sister3,
      link: "https://www.facebook.com/SanAntonioResort",
      desc: "San Antonio Resort is the number 1 resort in Roxas City that fronts the beautiful Byabay (beach) that has 50 rooms , its own cafe restaurant, inifinity pool, lap pool, and various venues appropriate for any occasion.",
      // link: "https://www.sanantoniobeachresort.com/index.php",
    },
    {
      name: "VBGI Construction Services",
      img: sister4,
      link: "https://www.facebook.com/VBGI-Construction-Services-107558260922818",
      desc: "Villa Bago Grande Inc. Is the contractor for vertical/ house constructions.",
    },
    {
      name: "Capiz Times",
      img: sister5,
      link: "https://www.facebook.com/VBGI-Construction-Services-107558260922818",
      desc: "The Capiz Times Description to go here!! and update the link",
    },
  ],
  footerdata: {
    footerlogo: darklogo,
    footerdesc1: `ZAMORA ST. BRGY.V, ROXAS CITY, CAPIZ PHILIPPINES`,
    footerdesc2: `Sarvi.sales@gmail.com | (036) 621-66499 / +639399391973`,
    sociallinks: [
      {
        icon: <FaFacebookSquare />,
        url: "https://www.facebook.com/AncorDevelopmentCorp",
      },
      {
        icon: <FaInstagram />,
        url: "https://www.instagram.com/ancordevtcorp/",
      },
      {
        icon: <FaTwitterSquare />,
        url: "https://twitter.com/AncorDevtCorp",
      },
    ],
    footerquicklinks: {
      title: "Quick Links",
      links: [
        {
          path: "/",
          text: "home",
        },
        {
          path: "/about",
          text: "about",
        },
        {
          path: "/listing-grid",
          text: "Properties",
        },
        {
          path: "/contact",
          text: "contact us",
        },
        {
          path: "/faq",
          text: "FAQs",
        },
        {
          path: "/sign-up",
          text: "sign up",
        },
      ],
    },
  },
  forms: [
    {
      name: "Service Request Form",
      link: "https://forms.gle/GutPMwhDoUttcHEbA",
      userType: "", // in place of 'customer' as the sample customer users have type ''
    },
    {
      name: "Buyer Request Form",
      link: "https://forms.gle/MeZSTtb6dtP1VWmbA",
      userType: "",
    },
    {
      name: "Inquiry Form",
      link: "https://forms.gle/n89iggAWDxV18NaF6",
      userType: "",
      userStatus: "2",
    },
    {
      name: "Discount Card Application",
      link: "https://forms.gle/sfEis4YRs1QkpaVi6",
      userType: "",
    },
    {
      name: "Service Acknowledgement Form",
      link: "https://forms.gle/38rjTEX5bWbJMnxs7",
      userType: "",
      userStatus: "2",
    },
    {
      name: "Buyer Feedback Form",
      link: "https://forms.gle/Zqsun3vQLFmBfK1a8",
      userType: "",
    },
    {
      name: "Reservation Application Form",
      link: "https://forms.gle/e13fKmJ8ViVN7jKR7",
      userType: "",
      userStatus: "2",
    },
    {
      name: "Sales Activity Form",
      link: "https://forms.gle/92TGtQT1auycDGmu6",
      userType: "agent",
    },
    {
      name: "Service Acknowledgement Form",
      link: "https://forms.gle/38rjTEX5bWbJMnxs7",
      userType: "agent",
    },
    {
      name: "Sales Representative Application",
      link: "https://forms.gle/wCAfRV7Wt9TAocFj7",
      userType: "agent",
    },
    {
      name: "Commission Request Form",
      link: "https://forms.gle/VgxPyeDvKFbKFz4y6",
      userType: "agent",
    },
    {
      name: "Authority to sell",
      link: "https://forms.gle/t77VWLiKD3eSe4PX9",
      userType: "partner",
    },
  ],
};
export default sectiondata;
