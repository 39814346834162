import React, { useState, useEffect } from "react";
import AdminSidePanel from "./AdminSidePanel";
import { Link } from "react-router-dom";
import { useCookies } from "react-cookie";
import axios from "axios";

function AdminOverview() {
  const [cookies, removeCookie] = useCookies(["Authorization"]);
  const [appointmentList, setAppointmentList] = useState([]);
  const [userList, setUserList] = useState([]);

  useEffect(() => {
    axios
      .get("/api/appointment/load_list", { headers: cookies })
      .then((res) => {
        console.log("res.data for appointments in adminoverview is", res.data);
        setAppointmentList(res.data.records);
      });
  }, []);

  useEffect(() => {
    axios.get("/api/user/load_list", { headers: cookies }).then((res) => {
      console.log("res.data for USERS in adminoverview is", res.data);
      setUserList(res.data.records);
    });
  }, []);

  return (
    <main className="admin-panel">
      <div className="row">
        <div className="col-lg-2">
          <AdminSidePanel />
        </div>
        <div className="col-lg-9">
          <div className="admin-sitemap">Admin Panel {">"} Overivew</div>
          <br></br>
          {/* Appointments Requests */}
          <div className="col-lg-12">
            <div className="section-heading">
              <h2 className="sec__title font-size-24">Appointments Requests</h2>
            </div>
            <div className="ledger-details-payment table-responsive">
              <table className="table mb-0">
                <thead>
                  <tr>
                    <th scope="col">Booking #</th>
                    <th scope="col">Date and Time</th>
                    {/* <th scope="col">Time</th> */}
                    <th scope="col">Customer</th>
                    <th scope="col">Meeting Type</th>
                    <th scope="col">Subject</th>
                    <th scope="col">Status</th>
                  </tr>
                </thead>
                <tbody>
                  {appointmentList
                    .reverse()
                    .slice(0, 2)
                    .map((item, index) => (
                      <tr>
                        <td>{item.bookingNo}</td>
                        <td>{item.bookingDate}</td>
                        {/* <td>hh:mm</td> */}
                        <td>{item.userID}</td>
                        <td>{item.type}</td>
                        <td>{item.subject}</td>
                        <td>{item.status}</td>
                        {/* <td className="admin-appointments-confirmed">
                          Confirmed
                        </td> */}
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
            <Link to="/admin-appointments" className="admin-seeall">
              See all...
            </Link>
          </div>

          {/* Clients */}
          <div className="col-lg-12">
            <div className="section-heading">
              <h2 className="sec__title font-size-24">Clients</h2>
            </div>
            <div className="ledger-details-payment table-responsive">
              <table className="table mb-0">
                <thead>
                  <tr>
                    <th scope="col">Name</th>
                    <th scope="col">Email</th>
                    <th scope="col">Contact #</th>
                    <th scope="col">Status</th>
                    <th scope="col">Location</th>
                    {/* <th scope="col">Ledger</th> */}
                    <th scope="col">Manage</th>
                  </tr>
                </thead>
                <tbody>
                  {userList
                    .reverse()
                    .filter((item) => item.userType === "")
                    .slice(0, 2)
                    .map((item) => (
                      <tr>
                        <td>
                          {item.fname} {item.mnam} {item.lname}
                        </td>
                        <td>{item.email}</td>
                        <td>{item.contactNo}</td>
                        <td>{item.status}</td>
                        <td>{item.address}</td>
                        {/* <td>
                          <Link to="/dashboard">View Ledger</Link>
                        </td> */}
                        <td>
                          <Link to="/admin-clients">View</Link> |{" "}
                          <Link to="/admin-clients">Edit</Link> |{" "}
                          <Link to="/admin-clients">Delete</Link>
                        </td>
                      </tr>
                    ))}
                  {/* <tr>
                    <td>Name</td>
                    <td>Email</td>
                    <td>Contact #</td>
                    <td>Status</td>
                    <td>Location</td>
                    <td>
                      <Link to="/dashboard">View Ledger</Link>
                    </td>
                    <td>
                      <Link to="/admin-clients">View</Link> | <Link to="/admin-clients">Edit</Link> | <Link to="/admin-clients">Delete</Link>
                    </td>
                  </tr>
                  <tr>
                    <td>Name</td>
                    <td>Email</td>
                    <td>Contact #</td>
                    <td>Status</td>
                    <td>Location</td>
                    <td>
                      <Link to="/dashboard">View Ledger</Link>
                    </td>
                    <td>
                      <Link to="/admin-clients">View</Link> | <Link to="/admin-clients">Edit</Link> | <Link to="/admin-clients">Delete</Link>
                    </td>
                  </tr> */}
                </tbody>
              </table>
            </div>
            <Link to="/admin-clients" className="admin-seeall">
              See all...
            </Link>
          </div>

          {/* Agents */}
          <div className="col-lg-12">
            <div className="section-heading">
              <h2 className="sec__title font-size-24">Agents</h2>
            </div>
            <div className="ledger-details-payment table-responsive">
              <table className="table mb-0">
                <thead>
                  <tr>
                    <th scope="col">Name</th>
                    <th scope="col">Email</th>
                    <th scope="col">Contact #</th>
                    <th scope="col">Recruited By</th>
                    <th scope="col">Position</th>
                    <th scope="col">Status</th>
                    <th scope="col">License</th>
                    <th scope="col">Details</th>
                  </tr>
                </thead>
                <tbody>
                  {userList
                    .reverse()
                    .filter((item) => item.userType === "agent")
                    .slice(0, 2)
                    .map((item) => (
                      <tr>
                        <td>
                          {item.fname} {item.mnam} {item.lname}
                        </td>
                        <td>{item.email}</td>
                        <td>{item.contactNo}</td>
                        <td>{item.recruitedBy}</td>
                        <td>{item.position}</td>
                        <td>{item.status}</td>
                        <td>{item.license}</td>
                        <td>
                          <Link to="/admin-agents">View</Link>
                        </td>
                      </tr>
                    ))}

                  {/* <tr>
                    <td>Name</td>
                    <td>Email</td>
                    <td>Contact #</td>
                    <td>Recruited By</td>
                    <td>Position</td>
                    <td>Status</td>
                    <td>License</td>
                    <td><Link to="/admin-agents">View</Link></td>
                  </tr> */}
                </tbody>
              </table>
            </div>
            <Link to="/admin-agents" className="admin-seeall">
              See all...
            </Link>
          </div>

          {/* Partner Applications */}
          <div className="col-lg-12">
            <div className="section-heading">
              <h2 className="sec__title font-size-24">Partner Applications</h2>
            </div>
            <div className="ledger-details-payment table-responsive">
              <table className="table mb-0">
                <thead>
                  <tr>
                    <th scope="col">Name</th>
                    <th scope="col">Email</th>
                    <th scope="col">Contact #</th>
                    <th scope="col">Purpose of Contact</th>
                    <th scope="col">Application Details</th>
                  </tr>
                </thead>
                <tbody>
                  {userList
                    .reverse()
                    .filter((item) => item.userType === "business partner")
                    .slice(0, 2)
                    .map((item) => (
                      <tr>
                        <td>
                          {item.fname} {item.mnam} {item.lname}
                        </td>
                        <td>{item.email}</td>
                        <td>{item.contactNo}</td>
                        <td>{item.reason}</td>
                        <td>
                          <Link to="/admin-businesspartners">View</Link>
                        </td>
                      </tr>
                    ))}

                  {/* <tr>
                    <td>Name</td>
                    <td>Email</td>
                    <td>Contact #</td>
                    <td>Recruited By</td>
                    <td><Link to="/partners">View</Link></td>
                  </tr> */}
                </tbody>
              </table>
            </div>
            <Link to="/admin-businesspartners" className="admin-seeall">
              See all...
            </Link>
          </div>

          {/* Payments History */}
          {/* <div className="col-lg-12">
            <div className="section-heading">
              <h2 className="sec__title font-size-24">Payments History</h2>
            </div>
            <div className="ledger-details-payment table-responsive">
              <table className="table mb-0">
                <thead>
                  <tr>
                    <th scope="col">Account Code</th>
                    <th scope="col">Lot</th>
                    <th scope="col">Block</th>
                    <th scope="col">Customer</th>
                    <th scope="col">Email</th>
                    <th scope="col">Payment Date</th>
                    <th scope="col">Ammount</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>######</td>
                    <td>#</td>
                    <td>#</td>
                    <td>Name</td>
                    <td>Email</td>
                    <td>mm/dd/yy</td>
                    <td>Amount</td>
                  </tr>
                  <tr>
                    <td>######</td>
                    <td>#</td>
                    <td>#</td>
                    <td>Name</td>
                    <td>Email</td>
                    <td>mm/dd/yy</td>
                    <td>Amount</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <Link to="/payments" className="admin-seeall">See all...</Link>
          </div> */}

          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
        </div>
      </div>
    </main>
  );
}

export default AdminOverview;
