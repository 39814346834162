import React, { useRef, useEffect } from "react";
import { useLocation, Switch } from "react-router-dom";
import AppRoute from "./utils/AppRoute";
import ScrollReveal from "./utils/ScrollReveal";

// Layouts
import LayoutDefault from "./layouts/LayoutDefault";

// Pages
import Home from "./pages/homes/Home";
import ListingGrid from "./pages/listings/ListingGrid";
import ListingList from "./pages/listings/ListingList";
import ListingDetails from "./pages/listings/ListingDetails1";
import Dashboard from "./pages/dashboard/Dashboard";
import About from "./pages/About";
import Contact from "./pages/Contact";
import RecoverPassword from "./pages/RecoverPassword";
import ResetPassword from "./pages/ResetPassword";
import Login from "./pages/Login";
import SignUp from "./pages/SignUp";
import Error from "./pages/Error";
import AgentApplication from "./pages/agent/AgentApplication";
import AgentSubmission from "./pages/agent/AgentSubmission";
import ContactSubmission from "./pages/agent/ContactSubmission";
import ListingDetailsLedger from "./pages/listings/ListingDetailsLedger";
import BusinessPartners from "./pages/BusinessPartners";
import AdminOverview from "./pages/admin/AdminOverview";
import AdminSiteManager from "./pages/admin/AdminSiteManager";
import AdminAddProperty from "./pages/admin/AdminAddProperty";
import AdminAddAnnouncement from "./pages/admin/AdminAddAnnouncement";
import AdminAccounts from "./pages/admin/AdminAccounts";
import AdminAnnouncements from "./pages/admin/AdminAnnouncements";
import AdminProperties from "./pages/admin/AdminProperties";
import AdminProperty from "./pages/admin/AdminProperty";
import AdminAppointments from "./pages/admin/AdminAppointments";
import AdminClients from "./pages/admin/AdminClients";
import AdminClientView from "./pages/admin/AdminClientView";
import AdminClientEdit from "./pages/admin/AdminClientEdit";
import AdminAgents from "./pages/admin/AdminAgents";
import AdminAgentView from "./pages/admin/AdminAgentView";
import AdminBusinessPartners from "./pages/admin/AdminBusinessPartners";
import AdminBusinessPartnerView from "./pages/admin/AdminBusinessPartnerView";
import AdminUserForms from "./pages/admin/AdminUserForms";
import AdminLegalDocuments from "./pages/admin/AdminLegalDocuments";
import AdminStaff from "./pages/admin/AdminStaff";
import AdminStaffView from "./pages/admin/AdminStaffView";
import AdminAddAgent from "./pages/admin/AdminAddAgent";
import AdminAddUserForm from "./pages/admin/AdminAddUserForm";
import AdminAddLegalDocuments from "./pages/admin/AdminAddLegalDocument";
import AdminAddMap from "./pages/admin/AdminAddMap";
import AdminAddStaff from "./pages/admin/AdminAddStaff";
import PropertyMaps from "./pages/listings/PropertyMaps";
import PropertyMapsDetails from "./pages/listings/PropertyMapsDetails";
import Faq from "./pages/FAQ";

const App = () => {
  const childRef = useRef();
  let location = useLocation();

  useEffect(() => {
    document.body.classList.add("is-loaded");
    childRef.current.init();
  }, [location]);

  return (
    <>
      <ScrollReveal
        ref={childRef}
        children={() => (
          <Switch>
            <AppRoute exact path="/" component={Home} layout={LayoutDefault} />
            <AppRoute path="/faq" component={Faq} />
            <AppRoute path="/listing-grid" component={ListingGrid} />
            <AppRoute path="/listing-list" component={ListingList} />
            <AppRoute path="/listing-details" component={ListingDetails} />
            <AppRoute
              path="/listing-details-ledger"
              component={ListingDetailsLedger}
            />
            <AppRoute path="/property-maps" component={PropertyMaps} />
            <AppRoute
              path="/property-maps-details"
              component={PropertyMapsDetails}
            />
            <AppRoute path="/dashboard" component={Dashboard} />
            <AppRoute path="/about" component={About} />
            <AppRoute path="/contact" component={Contact} />
            <AppRoute path="/recover" component={RecoverPassword} />
            <AppRoute path="/reset_password" component={ResetPassword} />
            <AppRoute path="/login" component={Login} />
            <AppRoute path="/sign-up" component={SignUp} />
            <AppRoute path="/agent-application" component={AgentApplication} />
            <AppRoute
              path="/agent-application-submitted"
              component={AgentSubmission}
            />
            <AppRoute path="/admin-overview" component={AdminOverview} />
            <AppRoute path="/admin-sitemanager" component={AdminSiteManager} />
            <AppRoute path="/admin-addproperty" component={AdminAddProperty} />
            <AppRoute path="/admin-addannouncement" component={AdminAddAnnouncement} />
            <AppRoute path="/admin-accounts" component={AdminAccounts} />
            <AppRoute path="/admin-announcements" component={AdminAnnouncements} />
            <AppRoute path="/admin-properties" component={AdminProperties} />
            <AppRoute path="/admin-property" component={AdminProperty} />
            <AppRoute path="/admin-appointments" component={AdminAppointments} />
            <AppRoute path="/admin-clients" component={AdminClients} />
            <AppRoute path="/admin-clients-view" component={AdminClientView} />
            <AppRoute path="/admin-clients-edit" component={AdminClientEdit} />

            <AppRoute path="/admin-client-edit" component={AdminClientEdit} />
            <AppRoute path="/admin-agents" component={AdminAgents} />
            <AppRoute path="/admin-agents-view" component={AdminAgentView} />
            <AppRoute
              path="/admin-businesspartners"
              component={AdminBusinessPartners}
            />
            <AppRoute
              path="/admin-businesspartner-view"
              component={AdminBusinessPartnerView}
            />
            <AppRoute path="/admin-userforms" component={AdminUserForms} />
            <AppRoute
              path="/admin-legaldocuments"
              component={AdminLegalDocuments}
            />
            <AppRoute path="/admin-staff" component={AdminStaff} />
            <AppRoute path="/admin-staff-view" component={AdminStaffView} />
            <AppRoute path="/admin-addagent" component={AdminAddAgent} />
            <AppRoute path="/admin-adduserform" component={AdminAddUserForm} />
            <AppRoute
              path="/admin-addlegaldocument"
              component={AdminAddLegalDocuments}
            />
            <AppRoute path="/admin-addmap" component={AdminAddMap} />
            <AppRoute path="/admin-addstaff" component={AdminAddStaff} />
            <AppRoute path="/contact-business" component={BusinessPartners} />
            <AppRoute path="/message-sent" component={ContactSubmission} />
            <AppRoute component={Error} />
          </Switch>
        )}
      />
    </>
  );
};

export default App;
