import React from "react";
import GeneralHeader from "../../components/common/GeneralHeader";
import Footer from "../../components/common/footer/Footer";
import SectionsHeading from "../../components/common/SectionsHeading";
import { Link } from "react-router-dom";

function ContactSubmission() {
  return (
    <div>
      <main className="agent-application-page">
        {/* Header */}
        <GeneralHeader />

        <div className="listing-details-banner"></div>

        <div className="container application-submitted d-flex align-items-center flex-column">
          <SectionsHeading title="Message Sent!" desc={``} />
          <div>
            <p>
              Thank you for your message! Our staff will respond to you via your
              provided email within the next 24-48 hours.
            </p>
          </div>
          <Link to="/">
            <button className="application-button">Return Home</button>
          </Link>
        </div>

        <Footer />
      </main>
    </div>
  );
}

export default ContactSubmission;
