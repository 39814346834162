import React from "react";
import { FaSearchLocation } from "react-icons/fa";
import { Link } from "react-router-dom";

function LocationPin({ text, filter }) {
  return (
    <div className="pin">
      <Link
        to={{
          pathname: "/listing-grid",
          state: { filter: filter },
        }}
        style={{ color: "#FFF" }}
      >
        <div className="pin-icon">
          <FaSearchLocation />
        </div>
        <p className="pin-text">{text}</p>
      </Link>
    </div>
  );
}

export default LocationPin;
