import React, { useState, useEffect } from "react";
import AdminSidePanel from "./AdminSidePanel";
import { Link } from "react-router-dom";
import { FaPlusCircle } from "react-icons/fa";
import { useCookies } from "react-cookie";
import axios from "axios";
import propertyPhoto from "../../assets/images/house2.png";


function AdminBusinessPartnerView({ location }) {

  const [cookies, removeCookie] = useCookies(["Authorization"]);

  console.log("location.state in AdminBusinessPartnerView page is", location.state)
  const clientData = { ...location.state.details };
  console.log(clientData);


  return (
    <div>

      <main className="admin-panel">
        <div className="row">
          <div className="col-lg-2">
            <AdminSidePanel />
          </div>
          <div className="col-lg-9">
            <div className="admin-sitemap">
              Admin Panel {">"} Site Manager {">"} Buisness Partners {">"} Buisness Partner
            </div>
            <br></br>
            <br></br>
          </div>
        </div>
        <div className="property-info">
          <div className="property-info-text">
            <br></br><br></br>
            <h3>Buisness Partner Name: {clientData.fname} </h3>
            <br></br><br></br>
            <h4>Buisness Partner Email: {clientData.email}</h4>
            <br></br><br></br>
            <h4>Buisness Partner Contact Number: {clientData.contactNo}</h4>
            <br></br><br></br>
            <h4>Buisness Partner Status: {clientData.status}</h4>
            <br></br><br></br>
            <h4>Buisness Partner Address: {clientData.address}</h4>
          </div>
        </div>
        <br></br><br></br><br></br><br></br>
      </main>



    </div>
  )
}

export default AdminBusinessPartnerView;