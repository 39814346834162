import React, { useState } from "react";
import AdminSidePanel from "./AdminSidePanel";
import Select from "react-select";
import { Link } from "react-router-dom";
import { useCookies } from "react-cookie";
import { useHistory } from "react-router-dom";
import axios from "axios";

function AdminAddStaff() {
  const [cookies, removeCookie] = useCookies(["Authorization"]);

  // const [staffData, setStaffData] = useState({
  //   name: "",
  //   userID: "",
  //   email: "",
  //   password: "123456",
  //   contactNo: "",
  //   cityID: "",
  //   facebookName: "",
  //   // projectTypes: "",
  //   // pricingTypes: "",
  //   userType: "staff",
  // });



  const [staffData, setStaffData] = useState({
    fname: "",
    mname: "",
    lname: "",
    address: "",
    birthDate: "",
    gender: "",
    civilStatus: "",
    email: "",
    facebookName: "",
    contactNo: "",
    citizenship: "",
    occupation: "",
    employer: "",
    position: "",
    officeAddress: "",
    spouseName: "",
    spouseOccupation: "",
    nameOfChildren: "",
    fatherName: "",
    motherName: "",
    bankAccountNo: "",
    bankAccountName: "",
    bankName: "",
    sourceOfFunds: "",
    userType: "staff",
    educationalAttainment: "",
    workExperience: "",
    license: "",
    position: "",
    paymentHistoryLink: "",
    applicationType: "",
    actionBy: "",
    actionDate: "",
    password: "123456",
  });





  const history = useHistory();

  function handleSubmit(event) {
    event.preventDefault();

    console.log("staffData is: ", staffData);

    const formData = new FormData();
    for (const [key, value] of Object.entries(staffData)) {
      formData.append(key, value);
    }

    axios
      .post("api/user/save", formData, { headers: cookies })
      .then((res) => {
        console.log("res.data in adminaddstaff page is:", res.data);

        history.push("/admin-staff");
      })
      .catch((err) => {
        console.log("admin add staff error: ", err);
      });
  }

  return (
    <>
      <main className="admin-panel">
        <div className="row">
          <div className="col-lg-2">
            <AdminSidePanel />
          </div>
          <div className="col-lg-9">
            <div className="admin-sitemap">
              Admin Panel {">"} Accounts {">"} Staff {">"} Add Staff
            </div>
            <br></br>
            <br></br>
            <div className="admin-addproperty-title">Add Staff</div>
            <form method="POST" action="/admin-properties">
              <div className="row">
                <div className="col-lg-12">
                  <div className="input-box">
                    <label className="admin-form-label">Name</label>
                    <div className="form-group">
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Name"
                        value={staffData.name}
                        onChange={(e) =>
                          setStaffData({ ...staffData, name: e.target.value })
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="input-box">
                    <label className="admin-form-label">Employee #</label>
                    <div className="form-group">
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Employee #"
                        value={staffData.userID}
                        onChange={(e) =>
                          setStaffData({ ...staffData, userID: e.target.value })
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="input-box">
                    <label className="admin-form-label">Email</label>
                    <div className="form-group">
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Email"
                        value={staffData.email}
                        onChange={(e) =>
                          setStaffData({ ...staffData, email: e.target.value })
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="input-box">
                    <label className="admin-form-label">Contact #</label>
                    <div className="form-group">
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Contact #"
                        value={staffData.contactNo}
                        onChange={(e) =>
                          setStaffData({ ...staffData, contactNo: e.target.value })
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="input-box">
                    <label className="admin-form-label">City</label>
                    <div className="form-group">
                      <input
                        className="form-control"
                        type="text"
                        placeholder="City"
                        value={staffData.cityID}
                        onChange={(e) =>
                          setStaffData({ ...staffData, cityID: e.target.value })
                        }
                      />
                    </div>
                  </div>
                </div>
                {/* <div className="col-lg-12">
                  <div className="input-box">
                    <label className="admin-form-label">Access Level</label>
                    <div className="form-group">
                      <Select
                        type="text"
                        placeholder="Access Level"
                        // onChange={e => e.target.value }
                        // value={}
                      />
                    </div>
                  </div>
                </div> */}
              </div>
              <button
                type="submit"
                to="/admin-staff"
                className="button icon-box-button border-0"
                onClick={(event) => handleSubmit(event)}
              >
                Add Staff
              </button>
              <Link to="/admin-addstaff" className="admin-addproperty-cancel">
                Cancel
              </Link>
            </form>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
          </div>
        </div>
      </main>
    </>
  );
}

export default AdminAddStaff;
