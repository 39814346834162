import React from "react";
import AdminSidePanel from "./AdminSidePanel";
import { Link } from "react-router-dom";
import { FaPlusCircle } from "react-icons/fa";
import { useCookies } from "react-cookie";
import axios from "axios";

function AdminUserForms() {
  const [cookies, removeCookie] = useCookies(["Authorization"]);

  return (
    <>
      <main className="admin-panel">
        <div className="row">
          <div className="col-lg-2">
            <AdminSidePanel />
          </div>
          <div className="col-lg-9">
            <div className="admin-sitemap">
              Admin Panel {">"} Site Manager {">"} User Forms
            </div>
            <br></br>
            <br></br>
            <div className="col-lg-12">
              <div className="section-heading">
                <h2 className="sec__title font-size-24">User Forms</h2>
                <Link to="/admin-adduserforms" className="admin-add">
                  <FaPlusCircle />
                </Link>
              </div>
              <div className="ledger-details-payment table-responsive">
                <table className="table mb-0">
                  <thead>
                    <tr>
                      <th scope="col">Form Name</th>
                      <th scope="col">Description</th>
                      <th scope="col">Link</th>
                      <th scope="col">User Access</th>
                      <th scope="col">Manage</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Form Name</td>
                      <td>Description</td>
                      <td>Link</td>
                      <td>Client</td>
                      <td>
                        <Link to="/admin-userforms">Edit</Link> |{" "}
                        <Link to="/admin-userforms">Delete</Link>
                      </td>
                    </tr>
                    <tr>
                      <td>Form Name</td>
                      <td>Description</td>
                      <td>Link</td>
                      <td>Client</td>
                      <td>
                        <Link to="/admin-userforms">Edit</Link> |{" "}
                        <Link to="/admin-userforms">Delete</Link>
                      </td>
                    </tr>
                    <tr>
                      <td>Form Name</td>
                      <td>Description</td>
                      <td>Link</td>
                      <td>Client</td>
                      <td>
                        <Link to="/admin-userforms">Edit</Link> |{" "}
                        <Link to="/admin-userforms">Delete</Link>
                      </td>
                    </tr>
                    <tr>
                      <td>Form Name</td>
                      <td>Description</td>
                      <td>Link</td>
                      <td>Agent</td>
                      <td>
                        <Link to="/admin-userforms">Edit</Link> |{" "}
                        <Link to="/admin-userforms">Delete</Link>
                      </td>
                    </tr>
                    <tr>
                      <td>Form Name</td>
                      <td>Description</td>
                      <td>Link</td>
                      <td>Agent</td>
                      <td>
                        <Link to="/admin-userforms">Edit</Link> |{" "}
                        <Link to="/admin-userforms">Delete</Link>
                      </td>
                    </tr>
                    <tr>
                      <td>Form Name</td>
                      <td>Description</td>
                      <td>Link</td>
                      <td>Agent</td>
                      <td>
                        <Link to="/admin-userforms">Edit</Link> |{" "}
                        <Link to="/admin-userforms">Delete</Link>
                      </td>
                    </tr>
                    <tr>
                      <td>Form Name</td>
                      <td>Description</td>
                      <td>Link</td>
                      <td>Client</td>
                      <td>
                        <Link to="/admin-userforms">Edit</Link> |{" "}
                        <Link to="/admin-userforms">Delete</Link>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <Link to="/admin-userforms" className="admin-seeall">
                Page 1 of 1 Next {">>"}
              </Link>
            </div>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
          </div>
        </div>
      </main>
    </>
  );
}

export default AdminUserForms;
