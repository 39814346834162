import React from 'react';
import WidgetSearch from "./widgets/WidgetSearch";
import { BsChevronRight } from 'react-icons/bs'

function ListingGridFilter({filterParams, setFilterParams, filterList}) {
    return (
        <>
            <div className="header-filter">
                <WidgetSearch filterParams={filterParams} setFilterParams={setFilterParams}/>
                <div>
                    <div className="btn-box">
                            <button className="theme-btn d-block w-100 text-center border-0" onClick={(e) => filterList()}>
                                <span className="d-inline-block"><BsChevronRight /></span>
                                Filter
                            </button>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ListingGridFilter;
