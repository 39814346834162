import React from "react";
import { GoLocation } from "react-icons/go";
import { FiPhone } from "react-icons/fi";
import { FaRegEnvelope } from "react-icons/fa";

function ContactSidebar({ contactinfo }) {
  return (
    <>
      <div className="billing-form-item">
        <div className="billing-title-wrap">
          <h3 className="widget-title pb-0">{contactinfo.title}</h3>
          <div className="title-shape margin-top-10px"></div>
        </div>
        <div className="billing-content">
          <div className="our-office-content">
            {/* <img
              src={contactinfo.img}
              alt="office image"
              className="w-100 radius-round"
            /> */}
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d488.5706251470087!2d122.74973303589837!3d11.583010043281636!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x58e7993200fe9aa4!2sSan%20Antonio%20Apartments!5e0!3m2!1sen!2sca!4v1632776047517!5m2!1sen!2sca"
              width="600"
              height="450"
              style={{ border: 0 }}
              allowfullscreen=""
              loading="lazy"
              className="w-100 radius-round"
            ></iframe>
            <div className="section-heading mt-4 mb-4">
              {/* <p className="sec__desc font-size-15 line-height-24">
                                {contactinfo.desc}
                            </p> */}
            </div>
            <ul className="info-list">
              <li className="d-inline-block mb-2 mr-2">
                <span className="la">
                  <GoLocation />
                </span>{" "}
                UNIT 1, SAN ANTONIO APARTMENTS, ZAMORA ST. BRGY.V, ROXAS CITY,
                CAPIZ PHILIPPINES 5800
              </li>
              <li className="d-inline-block mb-2 mr-2">
                <span className="la">
                  <FiPhone />
                </span>{" "}
                (036) 621-66499 / +639399391973
              </li>
              <li className="d-inline-block mb-2 mr-2">
                <span className="la">
                  <FaRegEnvelope />
                </span>{" "}
                Sarvi.sales@gmail.com
              </li>
            </ul>
          </div>
        </div>
        <div className="billing-title-wrap pt-0">
          <h3 className="widget-title pb-0">Working Hours</h3>
          <div className="title-shape margin-top-10px"></div>
        </div>
        <div className="billing-content">
          <ul className="list-items">
            <li className="d-flex align-items-center justify-content-between color-text-2">
              <strong className="font-weight-medium">
                {contactinfo.opendays}
              </strong>
              <strong className="font-weight-medium color-text-3">
                {contactinfo.opendaytime}
              </strong>
            </li>
            <li className="d-flex align-items-center justify-content-between color-text-2">
              <strong className="font-weight-medium">
                {contactinfo.closeday}
              </strong>
              <strong className="color-text">Closed</strong>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}

export default ContactSidebar;
