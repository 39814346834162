import React, { useState, useEffect } from "react";
import AdminSidePanel from "./AdminSidePanel";
import { Link } from "react-router-dom";
import { FaPlusCircle } from "react-icons/fa";
import { useCookies } from "react-cookie";
import axios from "axios";
import propertyPhoto from "../../assets/images/house2.png";


function AdminAgentView({ location }) {

  const [cookies, removeCookie] = useCookies(["Authorization"]);

  console.log("location.state in AdminAgentView page is", location.state)
  const agentData = { ...location.state.details };
  console.log(agentData);


  return (
    <div>

      <main className="admin-panel">
        <div className="row">
          <div className="col-lg-2">
            <AdminSidePanel />
          </div>
          <div className="col-lg-9">
            <div className="admin-sitemap">
              Admin Panel {">"} Site Manager {">"} Agents {">"} Agent
            </div>
            <br></br>
            <br></br>
          </div>
        </div>
        <div className="property-info">
          <div className="property-info-text">
            <br></br><br></br>
            <h3>Agent Name: {agentData.fname} </h3>
            <br></br><br></br>
            <h4>Agent Email: {agentData.email}</h4>
            <br></br><br></br>
            <h4>Agent Contact Number: {agentData.contactNo}</h4>
            <br></br><br></br>
            <h4>Agent Status: {agentData.status}</h4>
            <br></br><br></br>
            <h4>Agent Address: {agentData.address}</h4>
          </div>
        </div>
        <br></br><br></br><br></br><br></br>
      </main>



    </div>
  )
}

export default AdminAgentView;