import React, { useState } from "react";
import GeneralHeader from "../../components/common/GeneralHeader";
import Footer from "../../components/common/footer/Footer";
import SectionsHeading from "../../components/common/SectionsHeading";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import axios from "axios";
import { Link } from "react-router-dom";

const state = {
  civilStatus: [
    { value: "single", label: "Single" },
    { value: "married", label: "Married" },
    { value: "separated", label: "Separated" },
    { value: "widow", label: "Widow" },
    { value: "widower", label: "Widower" },
    { value: "other", label: "Other" },
  ],
  educationAttainment: [
    { value: "elementaryLevel", label: "Elementary Level" },
    { value: "elementaryGraduate", label: "Elementary Graduate" },
    { value: "highSchoolLevel", label: "High School Level" },
    { value: "highSchoolGraduate", label: "High School Graduate" },
    { value: "collegeLevel", label: "College Level" },
    { value: "collegelGraduate", label: "College Graduate" },
    { value: "other", label: "Other" },
  ],
  positionApplied: [
    { value: "salesRepresentative", label: "Sales Representative" },
    { value: "branchManager", label: "Branch Manager" },
    { value: "divisionManager", label: "Division Manager" },
    { value: "other", label: "Other" },
  ],
  seminarDate: [
    { value: 1, label: "January" },
    { value: 2, label: "February" },
    { value: 3, label: "March" },
    { value: 4, label: "April" },
    { value: 5, label: "May" },
    { value: 6, label: "June" },
    { value: 7, label: "July" },
    { value: 8, label: "August" },
    { value: 9, label: "September" },
    { value: 10, label: "October" },
    { value: 11, label: "November" },
    { value: 12, label: "Decemeber" },
  ],
};

function AgentApplication() {
  const [agentData, setAgentData] = useState({
    fname: "",
    mname: "",
    lname: "",
    email: "",
    facebookName: "",
    contactNo: "",
    // password: '',
    address: "",
    birthDate: "",
    civilStatus: "",
    spouseName: "",
    educationalAttainment: "",
    position: "",
    workExperience: "",
    recruitedBy: "",
    seminarDate: "",
  });
  const [error, setError] = useState({ message: "", status: false });

  let history = useHistory();

  function application(event) {
    event.preventDefault();
    setError({ message: "", status: false });

    // console.log("agent applying!");
    // console.log(agentData);

    const formData = new FormData();
    for (const [key, value] of Object.entries(agentData)) {
      formData.append(key, value);
    }

    axios.post("/api/registration/agent_save", formData).then((res) => {
      console.log(res.data);

      // add application error checking and redirect correctly
      // showcase error message.
      if (res.data.status === 1) {
        // redirect to submission page
        history.push("/agent-application-submitted");
      } else {
        setError({ status: true, message: res.data.message });
      }
    });
  }

  return (
    <main className="agent-application-page">
      {/* Header */}
      <GeneralHeader />

      <div className="listing-details-banner"></div>

      <div className="container d-flex flex-column align-items-center margin-top-50px margin-bottom-40px">
        <div className="text-center">
          <SectionsHeading title="Apply as an ADC Sales Agent" desc={""} />
        </div>
        <div className="contact-form-action agent-application-form">
          <form method="POST" action="/registration/agent_save">
            <div className="row">
              <div className="col-lg-12">
                <div className="input-box">
                  <label className="label-text">First Name</label>
                  <div className="form-group">
                    <input
                      className="form-control"
                      type="text"
                      name="fname"
                      placeholder="First Name"
                      onChange={(e) =>
                        setAgentData({ ...agentData, fname: e.target.value })
                      }
                      value={agentData.fname}
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="input-box">
                  <label className="label-text">Middle Name</label>
                  <div className="form-group">
                    <input
                      className="form-control"
                      type="text"
                      name="mname"
                      placeholder="Middle Name"
                      onChange={(e) =>
                        setAgentData({ ...agentData, mname: e.target.value })
                      }
                      value={agentData.mname}
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="input-box">
                  <label className="label-text">Last Name</label>
                  <div className="form-group">
                    <input
                      className="form-control"
                      type="text"
                      name="lname"
                      placeholder="Last Name"
                      onChange={(e) =>
                        setAgentData({ ...agentData, lname: e.target.value })
                      }
                      value={agentData.lname}
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="input-box">
                  <label className="label-text">E-mail</label>
                  <div className="form-group">
                    <input
                      className="form-control"
                      type="email"
                      name="text"
                      placeholder="E-mail"
                      onChange={(e) =>
                        setAgentData({ ...agentData, email: e.target.value })
                      }
                      value={agentData.email}
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="input-box">
                  <label className="label-text">
                    Preferred Seminar Date (2nd Sat Every Month, 9AM)
                  </label>
                  <div className="form-group">
                    <Select
                      placeholder="Preferred Seminar Date"
                      options={state.seminarDate}
                      onChange={(e) =>
                        setAgentData({ ...agentData, seminarDate: e.value })
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="input-box">
                  <label className="label-text">Facebook Name</label>
                  <div className="form-group">
                    <input
                      className="form-control"
                      type="text"
                      name="facebookName"
                      placeholder="Facebook Name"
                      onChange={(e) =>
                        setAgentData({
                          ...agentData,
                          facebookName: e.target.value,
                        })
                      }
                      value={agentData.facebookName}
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="input-box">
                  <label className="label-text">Contact #</label>
                  <div className="form-group">
                    <input
                      className="form-control"
                      type="number"
                      name="contactNo"
                      placeholder="Contact #"
                      onChange={(e) =>
                        setAgentData({
                          ...agentData,
                          contactNo: e.target.value,
                        })
                      }
                      value={agentData.contactNo}
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="input-box">
                  <label className="label-text">Address</label>
                  <div className="form-group">
                    <input
                      className="form-control"
                      type="text"
                      name="address"
                      placeholder="Address"
                      onChange={(e) =>
                        setAgentData({ ...agentData, address: e.target.value })
                      }
                      value={agentData.address}
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="input-box">
                  <label className="label-text">Date of Birth</label>
                  <div className="form-group">
                    <input
                      className="form-control"
                      type="date"
                      name="birthDate"
                      placeholder="Date of Birth"
                      onChange={(e) =>
                        setAgentData({
                          ...agentData,
                          birthDate: e.target.value,
                        })
                      }
                      value={agentData.birthDate}
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="input-box">
                  <label className="label-text">Civil Status</label>
                  <div className="form-group">
                    <Select
                      placeholder="Civil Status"
                      options={state.civilStatus}
                      onChange={(e) =>
                        setAgentData({ ...agentData, civilStatus: e.value })
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="input-box">
                  <label className="label-text">Spouse's Name</label>
                  <div className="form-group">
                    <input
                      className="form-control"
                      type="text"
                      name="spouseName"
                      placeholder="Spouse's Name"
                      onChange={(e) =>
                        setAgentData({
                          ...agentData,
                          spouseName: e.target.value,
                        })
                      }
                      value={agentData.spouseName}
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="input-box">
                  <label className="label-text">Educational Attainment</label>
                  <div className="form-group">
                    <Select
                      placeholder="Education Attainment"
                      options={state.educationAttainment}
                      onChange={(e) =>
                        setAgentData({
                          ...agentData,
                          educationalAttainment: e.value,
                        })
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="input-box">
                  <label className="label-text">Position Applied For</label>
                  <div className="form-group">
                    <Select
                      placeholder="Position Applied For"
                      options={state.positionApplied}
                      onChange={(e) =>
                        setAgentData({ ...agentData, positionApplied: e.value })
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="input-box">
                  <label className="label-text">Work Experience</label>
                  <div className="form-group">
                    <input
                      className="form-control"
                      type="text"
                      name="workExperience"
                      placeholder="Work Experience"
                      onChange={(e) =>
                        setAgentData({
                          ...agentData,
                          workExperience: e.target.value,
                        })
                      }
                      value={agentData.workExperience}
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="input-box">
                  <label className="label-text">Recruited/Invited By</label>
                  <div className="form-group">
                    <input
                      className="form-control"
                      type="text"
                      name="recruitedBy"
                      placeholder="Recruited/Invited By"
                      onChange={(e) =>
                        setAgentData({
                          ...agentData,
                          recruitedBy: e.target.value,
                        })
                      }
                      value={agentData.recruitedBy}
                    />
                  </div>
                </div>
              </div>
            </div>
            <button
              className="application-button"
              onClick={(e) => application(e)}
            >
              Submit Application
            </button>
          </form>
          <div className="padding-top-20px">
            <p className="font-weight-medium">
              <Link to="/sign-up" className="color-text-4">
                Register for a Customer Account
              </Link>
            </p>
            <p className="font-weight-medium">
              Already have an account?{" "}
              <Link to="/login" className="color-text-4">
                Login
              </Link>
            </p>
          </div>
          {error.status && (
            <div className="error-message">
              <h3>Application Error</h3>
              <p>Error Message: {error.message}.</p>
              <p>
                Please try to contact ADC staff for assistance with your
                submission.
              </p>
            </div>
          )}
        </div>
      </div>

      <Footer />
    </main>
  );
}

export default AgentApplication;
