import React from "react";
import { Link, useLocation, useHistory } from "react-router-dom";

import { useCookies } from "react-cookie";
import axios from "axios";

import { BsPower } from "react-icons/bs";

function FooterQuickLinkWidget({ footerquicklink }) {
  const [cookies, removeCookie] = useCookies(["Authorization"]);
  const location = useLocation();
  const history = useHistory();

  function signOut(event) {
    event.preventDefault();
    // console.log(cookies);
    axios.post("/api/profile/logout", { headers: cookies }).then(() => {
      removeCookie("Authorization"); // doesnt delete the cookie, just turned to undefined
      removeCookie("User");
      if (location.pathname === "/dashboard") {
        history.push("/");
      }
    });
  }
  return (
    <>
      <div className="col-lg-3 column-td-6">
        <div className="footer-item">
          <h4 className="footer__title">{footerquicklink.title}</h4>
          <ul className="list-items">
            {footerquicklink.links.map((link, index) => {
              return (
                <li key={index}>
                  <Link to={link.path}>{link.text}</Link>
                </li>
              );
            })}
            {cookies.Authorization?.length < 10 ? (
              <li>
                <Link to="/login">Login</Link>
              </li>
            ) : (
              <li onClick={(e) => signOut(e)}>
                <Link>Sign Out</Link>
              </li>
            )}
          </ul>
        </div>
      </div>
    </>
  );
}

export default FooterQuickLinkWidget;
