import React, { useEffect, useState } from "react";
import BannerOneHeroHeading from "./BannerOneHeroHeading";
import BannerOneSearchInput from "./BannerOneSearchInput";
import BannerOneCategories from "./BannerOneCategories";
import sectiondata from "../../../store/store";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import axios from "axios";
import { useCookies } from "react-cookie";

export default function BannerOne() {
  const [images, setImages] = useState([]);
  const [cookies] = useCookies(["Authorization"]);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    arrows: false,
  };

  useEffect(() => {
    axios
      .post("/api/announcement/load_carousel", [], { headers: cookies })
      .then((res) => {
        if (res.data.status !== 0) {
          console.log(res.data);
          setImages([...res.data]);
        }
      });
  }, []);

  return (
    <div className="hero-container">
      <section
        className="hero-wrapper"
        // style={{
        //   backgroundImage:
        //     "url(" + sectiondata.herobanners.banner1.bgimage3 + ")",
        // }}
      >
        <Slider {...settings}>
          <div>
            <img
              className="hero-wrapper"
              src={sectiondata.herobanners.banner1.bgimage1}
            ></img>
          </div>
          <div>
            <img
              className="hero-wrapper"
              src={sectiondata.herobanners.banner1.bgimage2}
            ></img>
          </div>{" "}
          <div>
            <img
              className="hero-wrapper"
              src={sectiondata.herobanners.banner1.bgimage3}
            ></img>
          </div>
        </Slider>
        <div className="hero-overlay">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 filter-block-container">
                {/* Banner One Search Input */}
                <BannerOneSearchInput />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
