import React, { useState, useEffect } from "react";
import AdminSidePanel from "./AdminSidePanel";
import { Link } from "react-router-dom";
import { AiOutlineExport } from "react-icons/ai";
import { useCookies } from "react-cookie";
import axios from "axios";

function AdminBusinessPartners() {
  const [cookies, removeCookie] = useCookies(["Authorization"]);
  const [userList, setUserList] = useState([]);

  useEffect(() => {
    axios.get("/api/user/load_list", { headers: cookies }).then((res) => {
      console.log("res.data for USERS in BuinessPartners is", res.data);
      setUserList(res.data.records);
    });
  }, []);

  return (
    <>
      <main className="admin-panel">
        <div className="row">
          <div className="col-lg-2">
            <AdminSidePanel />
          </div>
          <div className="col-lg-9">
            <div className="admin-sitemap">
              Admin Panel {">"} Accounts {">"} Business Partners
            </div>
            <br></br>
            <br></br>
            {/* Business Partners */}
            <div className="col-lg-12">
              <div className="section-heading">
                <h2 className="sec__title font-size-24">Business Partners</h2>
                <Link to="/admin-exportclients" className="admin-export">
                  Export <AiOutlineExport />
                </Link>
              </div>
              <div className="ledger-details-payment table-responsive">
                <table className="table mb-0">
                  <thead>
                    <tr>
                      <th scope="col">Name</th>
                      <th scope="col">Email</th>
                      <th scope="col">Contact #</th>
                      <th scope="col">Purpose of Contact</th>
                      <th scope="col">Application Details</th>
                    </tr>
                  </thead>
                  <tbody>
                    {userList
                      .filter((item) => item.userType === "business partner")
                      .map((item) => (
                        <tr>
                          <td>
                            {item.fname} {item.mnam} {item.lname}
                          </td>
                          <td>{item.email}</td>
                          <td>{item.contactNo}</td>
                          <td>{item.reason}</td>
                          <td>
                            <Link
                              to={{
                                pathname: "/admin-businesspartner-view",
                                state: { details: item },
                              }}
                            >
                              View</Link>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
              <Link to="/admin-businesspartners" className="admin-seeall">
                Page 1 of 1 Next {">>"}
              </Link>
            </div>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
          </div>
        </div>
      </main>
    </>
  );
}

export default AdminBusinessPartners;
