import React from "react";
import { Link } from "react-router-dom";
import { BsGrid, BsListUl } from "react-icons/bs";
import ListingGridFilter from "../../components/sidebars/ListingGridFilter";

const state = {
  selectedCatOp: null,
  title: "Showing 1 to 6 of 30 entries",
  navs: [
    {
      path: "/listing-grid",
      icon: <BsGrid />,
      active: true,
    },
    {
      path: "/listing-list",
      icon: <BsListUl />,
      active: false,
    },
  ],
};

function GenericHeader({ withSearch, setFilterParams, filterParams, filterList }) {
  return (
    <>
      <div className="generic-header justify-content-between">
        <div className="contact-form-action">
          <form>
            <div className="form-group mb-0">
              <input
                className="form-control"
                type="text"
                placeholder="What are you looking for?"
                onChange={(e) => setFilterParams({...filterParams, searchValue: e.target.value})}
                value={filterParams.searchValue}
              />
            </div>
          </form>
        </div>

        {/* for Listing-grid Page */}
        {withSearch && <ListingGridFilter filterParams={filterParams} setFilterParams={setFilterParams} filterList={filterList}/>}

        <ul className="generic-nav">
          {state.navs.map((item, index) => {
            return (
              <li key={index}>
                <Link to={{
                    pathname: item.path,
                    state: {filter: filterParams}                
                }} className={item.active ? "active" : " "} >
                  <span className="d-inline-block">{item.icon}</span>
                </Link>
              </li>
            );
          })}
        </ul>
      </div>
    </>
  );
}

export default GenericHeader;
