import React from "react";
import ReactDOM from "react-dom";
import { Router } from "react-router-dom";
import { createBrowserHistory } from "history";
import { CookiesProvider } from "react-cookie";

import App from "./App";
import * as serviceWorker from "./serviceWorker";

//import './App.css';
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/style.css";
import "./App.css";

const history = createBrowserHistory();

ReactDOM.render(
  <Router history={history}>
    <CookiesProvider>
      <App />
    </CookiesProvider>
  </Router>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
