import React from "react";
import { IoIosLink } from "react-icons/io";
import { Link } from "react-router-dom";
import { FiHeart, FiPhone } from "react-icons/fi";
import { FaRegCalendarPlus, FaUserTie } from "react-icons/fa";
import { AiOutlineEye } from "react-icons/ai";
import img2 from "../../assets/images/house2.png"; // listing property house
import { BsHouse } from "react-icons/bs";

function PlaceListing({ listitems, ownerID }) {
  const typeLegend = {
    0: "All Types",

    1: "Residential Lot for sale",

    2: "House and Lot",

    3: "Residential Lot",

    4: "With Commercial Potential",

    5: "Lot for lease",

    6: "House for Construction",

    7: "RFO House",

    8: "House for Rent",
  };

  const pricingLegend = {
    0: "NA",
    1: "REGULAR",
    2: "PRIME",
    3: "SPECIAL",
    4: "COMMERCIAL",
    5: "CORNER",
  };

  const tempData = {
    titleUrl: "/listing-details",
    image: img2,
  };

  function statusBadge(status) {
    if (status === "1") {
      return ["badge badge-reopened", "Re-opened"];
    } else if (status === "2") {
      return ["badge", "New"];
    } else {
      return ["badge badge-closed", "Closed"];
    }
  }

  return (
    <>
      {listitems.map((item, index) => {
        return (
          <div className="card-item card-listing d-flex" key={index}>
            <div className="card-image-wrap">
              <div className="card-image">
                <img
                  src={
                    item.featuredPhoto
                      ? "/api/" + item.featuredPhoto
                      : tempData.image
                  }
                  className="card__img"
                  alt="Place"
                />
                <span className={statusBadge(item.status)[0]}>
                  {statusBadge(item.status)[1]}
                </span>
              </div>
            </div>

            <div className="card-content-wrap d-flex justify-content-around flex-column">
              <div className="card-content">
                <h4 className="card-title">{item.name}</h4>
                <p className="card-sub">
                  {item.locationName}, {item.blockName}
                </p>
                <ul className="info-list padding-top-20px">
                  {/* <li>
                    <span className="la d-inline-block">₱</span> {item.price}
                  </li>
                  <li>
                    <span className="la d-inline-block">
                      <FaRegCalendarPlus />
                    </span>{" "}
                    {item.postDate?.slice(0, 10)}
                  </li> */}
                  <li>
                    <span className="la d-inline-block">
                      <FaRegCalendarPlus />
                    </span>{" "}
                    Pricing Type: {pricingLegend[item.pricing]}
                  </li>
                  <li>
                    <span className="la d-inline-block">
                      <BsHouse />
                    </span>{" "}
                    Project Type: {typeLegend[item.projectType]}
                  </li>
                </ul>
              </div>
              <div className="d-flex justify-content-around align-items-center">
                {ownerID ? (
                  <Link
                    to={{
                      pathname: "/listing-details-ledger",
                      state: { details: item },
                    }}
                  >
                    <button className="listing-button">More Information</button>
                  </Link>
                ) : (
                  <Link
                    to={{
                      pathname: "/listing-details",
                      state: { details: item },
                    }}
                  >
                    <button className="listing-button">More Information</button>
                  </Link>
                )}
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
}

export default PlaceListing;
