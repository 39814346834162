import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import axios from "axios";
import { useCookies } from "react-cookie";

function AskQuestionField({ title, userData }) {
  const [cookies] = useCookies(["Authorization"]);

  const [contactData, setContactData] = useState({
    name: userData ? `${userData.fname} ${userData.lname}` : "",
    email: userData ? userData.email : "",
    phone: userData ? userData.contactNo : "",
    subject: "",
    message: "",
  });
  const [error, setError] = useState({ message: "", status: false });
  let history = useHistory();

  function formSubmission(e) {
    e.preventDefault();
    console.log("contactData >>>>", contactData);

    // reset error message
    setError({ message: "", status: false });

    // create formData obj / data structure to send to email/server
    // is it going to the database or straight to email?
    const formData = new FormData();
    for (const [key, value] of Object.entries(contactData)) {
      formData.append(key, value);
    }
    // if user is logged in
    if (userData) {
      axios
        .post("/api/message/save", formData, { headers: cookies })
        .then((res) => {
          console.log(res.data);
          // if submission sucessful
          if (res.data.status === 1) {
            setContactData({
              subject: "",
              message: "",
            });
            history.push("/message-sent");
          } else {
            setError({ message: res.data.message, status: true });
          }
        })
        .catch((err) => {
          console.log(err);
          setError({ message: err.message, status: true });
        });
      // public form
    } else {
      axios
        .post("/api/message/public_save", formData)
        .then((res) => {
          console.log(res.data);
          // if submission sucessful
          if (res.data.status === 1) {
            setContactData({
              name: "",
              email: "",
              phone: "",
              subject: "",
              message: "",
            });
            history.push("/message-sent");
          } else {
            setError({ message: res.data.message, status: true });
          }
        })
        .catch((err) => {
          console.log(err);
          setError({ message: err.message, status: true });
        });
    }
  }

  return (
    <>
      <div className="faq-forum">
        <div className="billing-form-item">
          {title ? (
            <div className="billing-title-wrap d-flex ">
              <div>
                <h3 className="widget-title pb-0">{title}</h3>
                <div className="title-shape margin-top-10px"></div>
              </div>
            </div>
          ) : (
            ""
          )}
          <div className="billing-content">
            <div className="contact-form-action">
              <form method="post">
                <div className="input-box">
                  {/* <label className="label-text">Your name</label> */}
                  <div className="form-group">
                    {/* <span className="form-icon"><AiOutlineUser /></span> */}
                    <input
                      className="form-control"
                      type="text"
                      name="name"
                      placeholder="Name"
                      onChange={(e) =>
                        setContactData({ ...contactData, name: e.target.value })
                      }
                      value={contactData.name}
                    />
                  </div>
                </div>
                <div className="input-box">
                  {/* <label className="label-text">Your email</label> */}
                  <div className="form-group">
                    {/* <span className="form-icon"><FaRegEnvelope /></span> */}
                    <div className="row">
                      <div className="col-lg-6">
                        <input
                          className="form-control"
                          type="email"
                          name="email"
                          placeholder="Email"
                          onChange={(e) =>
                            setContactData({
                              ...contactData,
                              email: e.target.value,
                            })
                          }
                          value={contactData.email}
                        />
                      </div>
                      <div className="col-lg-6">
                        <input
                          className="form-control"
                          type="number"
                          name="phone"
                          placeholder="Phone"
                          onChange={(e) =>
                            setContactData({
                              ...contactData,
                              phone: e.target.value,
                            })
                          }
                          value={contactData.phone}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="input-box">
                  {/* <label className="label-text">Your name</label> */}
                  <div className="form-group">
                    {/* <span className="form-icon"><AiOutlineUser /></span> */}
                    <input
                      className="form-control"
                      type="text"
                      name="subject"
                      placeholder="Subject"
                      onChange={(e) =>
                        setContactData({
                          ...contactData,
                          subject: e.target.value,
                        })
                      }
                      value={contactData.subject}
                    />
                  </div>
                </div>
                <div className="input-box">
                  {/* <label className="label-text">message</label> */}
                  <div className="form-group">
                    <textarea
                      className="message-control form-control"
                      name="message"
                      placeholder="How can we help?"
                      onChange={(e) =>
                        setContactData({
                          ...contactData,
                          message: e.target.value,
                        })
                      }
                      value={contactData.message}
                    ></textarea>
                  </div>
                </div>
                <div className="btn-box d-flex justify-content-around">
                  {/* <Link to="/message-sent"> */}
                  <button
                    type="button"
                    className="theme-btn border-0"
                    onClick={formSubmission}
                  >
                    submit
                  </button>
                  {/* </Link> */}
                </div>
                {error.status && (
                  <div className="error-message">
                    <h3>Submission Error</h3>
                    <p>Error Message: {error.message}.</p>
                    <p>
                      Please try to contact ADC staff for assistance with your
                      submission.
                    </p>
                  </div>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AskQuestionField;
