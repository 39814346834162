import React from "react";
import AdminSidePanel from "./AdminSidePanel";
import Select from "react-select";
import { Link } from "react-router-dom";
import { useCookies } from "react-cookie";
import axios from "axios";

function AdminAddUserForm() {
  const [cookies, removeCookie] = useCookies(["Authorization"]);

  return (
    <>
      <main className="admin-panel">
        <div className="row">
          <div className="col-lg-2">
            <AdminSidePanel />
          </div>
          <div className="col-lg-9">
            <div className="admin-sitemap">
              Admin Panel {">"} Site Manager {">"} Add User Form
            </div>
            <br></br>
            <br></br>
            <div className="admin-addproperty-title">Add User Form</div>
            <form method="POST" action="/admin-properties">
              <div className="row">
                <div className="col-lg-12">
                  <div className="input-box">
                    <label className="admin-form-label">Form Name</label>
                    <div className="form-group">
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Form Name"
                        // onChange={e => e.target.value }
                        // value={}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="input-box">
                    <label className="admin-form-label">Description</label>
                    <div className="form-group">
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Description"
                        // onChange={e => e.target.value }
                        // value={}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="input-box">
                    <label className="admin-form-label">Link</label>
                    <div className="form-group">
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Link"
                        // onChange={e => e.target.value }
                        // value={}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="input-box">
                    <label className="admin-form-label">User Access</label>
                    <div className="form-group">
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Client/Agent"
                        // onChange={e => e.target.value }
                        // value={}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <button
                type="submit"
                to="/admin-userforms"
                className="button icon-box-button border-0"
                // onClick={}
              >
                Save User Form
              </button>
              <Link
                to="/admin-adduserform"
                className="admin-addproperty-cancel"
              >
                Cancel
              </Link>
            </form>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
          </div>
        </div>
      </main>
    </>
  );
}

export default AdminAddUserForm;
