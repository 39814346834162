import React, { useState, useEffect } from "react";
import AdminSidePanel from "./AdminSidePanel";
import { Link } from "react-router-dom";
import { AiOutlineExport } from "react-icons/ai";
import { FaPlusCircle } from "react-icons/fa";
import { useCookies } from "react-cookie";
import axios from "axios";

function AdminStaff() {
  const [cookies, removeCookie] = useCookies(["Authorization"]);
  const [userList, setUserList] = useState([]);

  useEffect(() => {
    axios.get("/api/user/load_list", { headers: cookies }).then((res) => {
      console.log("res.data for USERS in adminoverview is", res.data);
      setUserList(res.data.records);
    });
  }, []);

  return (
    <>
      <main className="admin-panel">
        <div className="row">
          <div className="col-lg-2">
            <AdminSidePanel />
          </div>
          <div className="col-lg-9">
            <div className="admin-sitemap">
              Admin Panel {">"} Accounts {">"} Staff
            </div>
            <br></br>
            <br></br>
            <div className="col-lg-12">
              <div className="section-heading">
                <h2 className="sec__title font-size-24">Staff</h2>
                <Link to="/admin-addstaff" className="admin-add">
                  <FaPlusCircle />
                </Link>
              </div>
              <div className="ledger-details-payment table-responsive">
                <table className="table mb-0">
                  <thead>
                    <tr>
                      <th scope="col">Employee #</th>
                      <th scope="col">Access Level</th>
                      <th scope="col">Name</th>
                      <th scope="col">Email</th>
                      <th scope="col">Contact #</th>
                      <th scope="col">Manage</th>
                    </tr>
                  </thead>

                  <tbody>
                    {userList.reverse().map((item) => (
                      <tr>
                        <td>{item.userID}</td>
                        <td>{item.userType}</td>
                        <td>
                          {item.fname} {item.mnam} {item.lname}
                        </td>
                        <td>{item.email}</td>
                        <td>{item.contactNo}</td>
                        <td>
                        <Link
                              to={{
                                pathname: "/admin-staff-view",
                                state: { details: item },
                              }}
                            >                            
                            View</Link> |{" "}
                          <Link to="/admin-staff">Edit</Link> |{" "}
                          <Link to="/admin-staff">Delete</Link>
                        </td>
                      </tr>
                    ))}
                  </tbody>

                  {/* <tbody>
                    <tr>
                      <td>Employee #</td>
                      <td>Admin</td>
                      <td>Name</td>
                      <td>Email</td>
                      <td>Contact #</td>
                      <td>
                        <Link to="/admin-staff">View</Link> | <Link to="/admin-staff">Edit</Link> | <Link to="/admin-staff">Delete</Link>
                      </td>
                    </tr>
                    <tr>
                      <td>Employee #</td>
                      <td>Staff</td>
                      <td>Name</td>
                      <td>Email</td>
                      <td>Contact #</td>
                      <td>
                        <Link to="/admin-staff">View</Link> | <Link to="/admin-staff">Edit</Link> | <Link to="/admin-staff">Delete</Link>
                      </td>
                    </tr>

                    <tr>
                      <td>Employee #</td>
                      <td>Staff</td>
                      <td>Name</td>
                      <td>Email</td>
                      <td>Contact #</td>
                      <td>
                        <Link to="/admin-staff">View</Link> | <Link to="/admin-staff">Edit</Link> | <Link to="/admin-staff">Delete</Link>
                      </td>
                    </tr>
                    <tr>
                      <td>Employee #</td>
                      <td>Staff</td>
                      <td>Name</td>
                      <td>Email</td>
                      <td>Contact #</td>
                      <td>
                        <Link to="/admin-staff">View</Link> | <Link to="/admin-staff">Edit</Link> | <Link to="/admin-staff">Delete</Link>
                      </td>
                    </tr>
                    <tr>
                      <td>Employee #</td>
                      <td>Staff</td>
                      <td>Name</td>
                      <td>Email</td>
                      <td>Contact #</td>
                      <td>
                        <Link to="/admin-staff">View</Link> | <Link to="/admin-staff">Edit</Link> | <Link to="/admin-staff">Delete</Link>
                      </td>
                    </tr>
                    <tr>
                      <td>Employee #</td>
                      <td>Staff</td>
                      <td>Name</td>
                      <td>Email</td>
                      <td>Contact #</td>
                      <td>
                        <Link to="/admin-staff">View</Link> | <Link to="/admin-staff">Edit</Link> | <Link to="/admin-staff">Delete</Link>
                      </td>
                    </tr>
                    <tr>
                      <td>Employee #</td>
                      <td>Staff</td>
                      <td>Name</td>
                      <td>Email</td>
                      <td>Contact #</td>
                      <td>
                        <Link to="/admin-staff">View</Link> | <Link to="/admin-staff">Edit</Link> | <Link to="/admin-staff">Delete</Link>
                      </td>
                    </tr>

                  </tbody> */}
                </table>
              </div>
              <Link to="/admin-staff" className="admin-seeall">
                Page 1 of 1 Next {">>"}
              </Link>
            </div>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
          </div>
        </div>
      </main>
    </>
  );
}

export default AdminStaff;
