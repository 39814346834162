import React from "react";
import GeneralHeader from "../components/common/GeneralHeader";
import RecoverPassBox from "../components/other/account/RecoverPassBox";
import Footer from "../components/common/footer/Footer";
import ScrollTopBtn from "../components/common/ScrollTopBtn";
import breadcrumbimg from "../assets/images/bread-bg.jpg";

const state = {
  breadcrumbimg: breadcrumbimg,
};
function RecoverPassword() {
  return (
    <main className="recover-pass-page">
      {/* Header */}
      <GeneralHeader />
      <div className="listing-details-banner"></div>

      <RecoverPassBox />

      {/* Footer */}
      <Footer />

      <ScrollTopBtn />
    </main>
  );
}

export default RecoverPassword;
