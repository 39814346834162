import axios from "axios";
import React, { useState } from "react";
import AdminSidePanel from "./AdminSidePanel";

function AdminClientEdit({ location }) {
  const clientData = { ...location.state.details };
  const [data, setData] = useState({
    fname: "",
    lname: clientData.lname,
  });

  console.log(clientData);

  function handleEdit(e) {
    e.preventDefault();

    const formData = new FormData();
    // becaue its POST it needs all the params or it gets replaced
    // file can be refractor to reduce user object to have the keyvalue pairs instead of breaking into two parts
    for (const [key, value] of Object.entries(data)) {
      formData.append(key, value);
    }

    // axios
    //   .post("/api/profile/update", formData, { headers: cookies })
    //   .then((res) => {
    //     // console.log(res);
    //   });
  }

  return (
    <>
      <main className="admin-panel">
        <div className="row">
          <div className="col-lg-2">
            <AdminSidePanel />
          </div>
          <div className="col-lg-9">
            <div className="admin-sitemap">
              Admin Panel {">"} Accounts {">"} Clients
            </div>
            <br></br>
            <br></br>
            {/* Clients */}
            <div className="col-lg-12">
              <div className="form-group">
                <input
                  className="form-control"
                  type="text"
                  name="fname"
                  placeholder="First Name"
                  value={clientData.fname}
                  onChange={(e) => setData({ ...data, fname: e.target.value })}
                />
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default AdminClientEdit;
