import React from "react";
import GeneralHeader from "../../components/common/GeneralHeader";
import Footer from "../../components/common/footer/Footer";
import ScrollTopBtn from "../../components/common/ScrollTopBtn";
import { IoIosArrowBack } from "react-icons/io";
import { Link } from "react-router-dom";

function PropertyMapsDetails({ location }) {
  const mapData = { ...location.state.details };

  return (
    <main className="listing-details">
      {/* Header */}
      <GeneralHeader />

      <div className="listing-details-banner"></div>

      <section className="single-listing-area">
        <div className="container">
          <div className="row padding-left-15px padding-top-20px">
            <div className="col-12-lg">
              <Link to={"/property-maps"}>
                <p className="back-link">
                  <span>
                    <IoIosArrowBack />
                  </span>
                  Back{" "}
                </p>
              </Link>
            </div>
          </div>
          <div className="row padding-left-15px">
            <div className="col-12-lg">
              <div className="section-heading">
                <h2 className="sec__title font-size-24">{mapData?.name}</h2>
                <p className="sec__desc">
                  [short description] <br />
                  {mapData?.description}
                </p>
              </div>
            </div>
          </div>
          <div className="col-8-lg">
            <div>
              <img
                className="map-full"
                src={"/api/" + mapData.mapPhoto}
                alt={`Map of ${mapData.name}`}
              />
            </div>
          </div>
        </div>
      </section>

      <Footer />

      <ScrollTopBtn />
    </main>
  );
}

export default PropertyMapsDetails;
