import React, { useState } from "react";
import AdminSidePanel from "./AdminSidePanel";
import Select from "react-select";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { useCookies } from "react-cookie";
import axios from "axios";
import Dropzone from "react-dropzone";

function AdminAddAnnouncement() {

  const [cookies, removeCookie] = useCookies(["Authorization"]);

  const [announcementData, setAnnouncementData] = useState({
    name: "",
    description: "",
    featuredPhoto: null,
    status: "",
  });

  const history = useHistory();


  // to convert image to base64
  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };
  const handleFileUpload = async (file) => {
    const base64 = await convertToBase64(file);
    const indexOfSplit = base64.indexOf(",");
    const newBase64 = base64.slice(indexOfSplit + 1);
    console.log(newBase64);
    setAnnouncementData({ ...announcementData, featuredPhoto: newBase64 });
  };

  function handleSubmit(event) {
    event.preventDefault();

    console.log("announcementData is: ", announcementData);


    const formData = new FormData();
    for (const [key, value] of Object.entries(announcementData)) {
      formData.append(key, value);
    }

    axios
      .post("api/announcement/save", formData, { headers: cookies })
      .then((res) => {
        console.log("res.data in POST adminaddannouncement page is:", res.data);

        history.push("/admin-announcements");
      })
      .catch((err) => {
        console.log("admin add announcement error: ", err);
      });

  }

  return (
    <>
      <main className="admin-panel">
        <div className="row">
          <div className="col-lg-2">
            <AdminSidePanel />
          </div>
          <div className="col-lg-9">
            <div className="admin-sitemap">
              Admin Panel {">"} Site Manager {">"} Add Announcement
            </div>
            <br></br>
            <br></br>
            <div className="admin-addproperty-title">Add New Announcement</div>
            <form method="POST" action="/admin-properties">
              <div className="row">
                <div className="col-lg-12">
                  <div className="input-box">
                    <label className="admin-form-label">Announcement Title</label>
                    <div className="form-group">
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Announcement"
                        value={announcementData.name}
                        onChange={(e) =>
                          setAnnouncementData({
                            ...announcementData,
                            name: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="input-box">
                    <label className="admin-form-label">Announcement Description</label>
                    <div className="form-group">
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Description"
                        value={announcementData.description}
                        onChange={(e) =>
                          setAnnouncementData({
                            ...announcementData,
                            name: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="input-box">
                    <label className="admin-form-label">Announcement Image</label>
                    {/* <div className="form-group">
                        <input
                          className="form-control"
                          type="text"
                          placeholder="Upload File"
                        // onChange={e => e.target.value }
                        // value={}
                        />
                      </div> */}

                    <div className="form-group">
                      {/* <input
                        className="form-control"
                        type="text"
                        placeholder="Drag and drop File Here to Upload"
                        // onChange={e => e.target.value }
                        // value={}
                      /> */}
                      <Dropzone
                        onDrop={(acceptedFiles) => {
                          console.log(acceptedFiles);
                          // setAnnouncementData({
                          //   ...announcementData,
                          //   featuredPhoto: acceptedFiles[0],
                          // });
                          setAnnouncementData({
                            ...announcementData,
                            featuredPhotoName: acceptedFiles[0].name,
                          });
                          handleFileUpload(acceptedFiles[0]);
                        }}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <section>
                            <div {...getRootProps()}>
                              <input {...getInputProps()} />
                              {announcementData.featuredPhoto ? (
                                <p>Photo uploaded</p>
                              ) : (
                                <p>
                                  Drag 'n' drop some files here, or click to
                                  select files
                                </p>
                              )}
                            </div>
                          </section>
                        )}
                      </Dropzone>
                    </div>
                  </div>
                </div>
                {/* </div>
                      <Dropzone mulitple onDrop={acceptedFiles => {
                        const name = acceptedFiles[0].name
                        console.log('file name >>>', name)
                        setContactFiles({...contactFiles, [name]: acceptedFiles[0]})
                      }}>
                      {({getRootProps, getInputProps}) => (
                          <section className="container">
                          <div {...getRootProps({className: 'dropzone'})}>
                            <input {...getInputProps()} />
                            <p className="text-left">(Optional) Upload files...<br/>
                            &nbsp;&nbsp;&nbsp;&nbsp;Title<br/>
                            &nbsp;&nbsp;&nbsp;&nbsp;Vicinity map<br/>
                            &nbsp;&nbsp;&nbsp;&nbsp;Tax Dex<br/>
                            &nbsp;&nbsp;&nbsp;&nbsp;ID with Signature and Photo</p>
                          </div>
                          <aside>
                            <h4>Files</h4>
                            <ul>{getFilenames()}</ul>
                          </aside>
                        </section>
                      )}
                      </Dropzone>
                    </div> */}
              </div>
              <button
                type="submit"
                to="/admin-announcements"
                className="button icon-box-button border-0"
                onClick={(event) => handleSubmit(event)}
              >
                Save New Announcement
              </button>
              <Link
                to="/admin-addannouncement"
                className="admin-addproperty-cancel"
              >
                Cancel
              </Link>
            </form>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
          </div>
        </div>
      </main>
    </>
  );
}

export default AdminAddAnnouncement;
