import React, { useState, useEffect } from "react";
import AdminSidePanel from "./AdminSidePanel";
import Select from "react-select";
import { Link } from "react-router-dom";
import { useCookies } from "react-cookie";
import axios from "axios";

function AdminAddLegalDocuments() {

  const [cookies, removeCookie] = useCookies(["Authorization"]);
  const [documentList, setDocumentList] = useState([]);

  useEffect(() => {
    axios.get("/api/document/load_list", { headers: cookies }).then((res) => {
      console.log("res.data in adminaddlegaldocument for document is", res.data);
      setDocumentList(res.data.records);
    });
  }, []);

  return (
    <>
      <main className="admin-panel">
        <div className="row">
          <div className="col-lg-2">
            <AdminSidePanel />
          </div>
          <div className="col-lg-9">
            <div className="admin-sitemap">
              Admin Panel {">"} Site Manager {">"} Add Legal Document
            </div>
            <br></br>
            <br></br>
            <div className="admin-addproperty-title">Add Legal Document</div>
            <form method="POST" action="/admin-properties">
              <div className="row">
                <div className="col-lg-12">
                  <div className="input-box">
                    <label className="admin-form-label">Form Name</label>
                    <div className="form-group">
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Form Name"
                        // onChange={e => e.target.value }
                        // value={}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="input-box">
                    <label className="admin-form-label">Description</label>
                    <div className="form-group">
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Description"
                        // onChange={e => e.target.value }
                        // value={}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="input-box">
                    <label className="admin-form-label">File</label>
                    <div className="form-group">
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Upload File"
                        // onChange={e => e.target.value }
                        // value={}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="input-box">
                    <label className="admin-form-label">User Access</label>
                    <div className="form-group">
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Client/Agent"
                        // onChange={e => e.target.value }
                        // value={}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <button
                type="submit"
                to="/admin-legaldocuments"
                className="button icon-box-button border-0"
                // onClick={}
              >
                Save Legal Document
              </button>
              <Link
                to="/admin-addlegaldocument"
                className="admin-addproperty-cancel"
              >
                Cancel
              </Link>
            </form>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
          </div>
        </div>
      </main>
    </>
  );
}

export default AdminAddLegalDocuments;
