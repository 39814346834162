import React, { useState } from "react";
import AdminSidePanel from "./AdminSidePanel";
import Select from "react-select";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { useCookies } from "react-cookie";
import axios from "axios";

function AdminAddAgent() {
  const [cookies, removeCookie] = useCookies(["Authorization"]);

  const adminAddAgentMenuOptions = {
    civilStatus: [
      { value: "Single", label: "Single" },
      { value: "Married", label: "Married" },
      { value: "Widowed", label: "Widowed" },
      { value: "Separated", label: "Separated" },
      { value: "Divorced", label: "Divorced" },
    ],
    educationAttainment: [
      { value: "elementaryLevel", label: "Elementary Level" },
      { value: "elementaryGraduate", label: "Elementary Graduate" },
      { value: "highSchoolLevel", label: "High School Level" },
      { value: "highSchoolGraduate", label: "High School Graduate" },
      { value: "collegeLevel", label: "College Level" },
      { value: "collegelGraduate", label: "College Graduate" },
      { value: "other", label: "Other" },
    ],
    position: [
      { value: "Licensed Sales", label: "Licensed Sales Person" },
      { value: "Agent", label: "Agent" },
      { value: "Licensed Broker", label: "Licensed Broker" },
      { value: "Branch Manager", label: "Branch Manager" },
      { value: "Division Manager", label: "Division Manager" },
      { value: "other", label: "Other" },
    ],
  };

  const [agentData, setAgentData] = useState({
    fname: "",
    mname: "",
    lname: "",
    email: "",
    facebookName: "",
    contactNo: "",
    address: "",
    birthDate: "",
    civilStatus: "",
    spouseName: "",
    educationalAttainment: "",
    position: "",
    workExperience: "",
    recruitedBy: "",
    approvedDate: "",
    approvedBiosDate: "",
  });

  let history = useHistory();

  function handleAgentSubmit(event) {
    event.preventDefault();

    console.log("agentData in adminaddagent is: ", agentData);

    const formData = new FormData();
    for (const [key, value] of Object.entries(agentData)) {
      formData.append(key, value);
    }

    axios.post("/api/registration/agent_save", formData, { headers: cookies }).then((res) => {
      console.log("res.data in POST AdminAddAgent page is:", res.data);

      history.push("/admin-agents");
    })
      .catch((err) => {
        console.log("admin add agent error: ", err);
      });
  }

  return (
    <>
      <main className="admin-panel">
        <div className="row">
          <div className="col-lg-2">
            <AdminSidePanel />
          </div>
          <div className="col-lg-9">
            <div className="admin-sitemap">
              Admin Panel {">"} Accounts {">"} Agents {">"} Review/Approve Agent
            </div>
            <br></br>
            <br></br>
            <div className="admin-addproperty-title">Review/Approve Agent</div>
            <form method="POST" action="/admin-properties">
              <div className="row">
                <div className="col-lg-12">
                  <div className="input-box">
                    <label className="admin-form-label">First Name</label>
                    <div className="form-group">
                      <input
                        className="form-control"
                        type="text"
                        placeholder="First Name"
                        value={agentData.fname}
                        onChange={(e) =>
                          setAgentData({ ...agentData, fname: e.target.value })
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="input-box">
                    <label className="admin-form-label">Middle Name</label>
                    <div className="form-group">
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Middle Name"
                        value={agentData.mname}
                        onChange={(e) =>
                          setAgentData({ ...agentData, mname: e.target.value })
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="input-box">
                    <label className="admin-form-label">Last Name</label>
                    <div className="form-group">
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Last Name"
                        value={agentData.lname}
                        onChange={(e) =>
                          setAgentData({ ...agentData, lname: e.target.value })
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="input-box">
                    <label className="admin-form-label">Email</label>
                    <div className="form-group">
                      <input
                        className="form-control"
                        type="email"
                        placeholder="Email"
                        value={agentData.email}
                        onChange={(e) =>
                          setAgentData({ ...agentData, email: e.target.value })
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="input-box">
                    <label className="admin-form-label">Facebook Name</label>
                    <div className="form-group">
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Facebook Name"
                        value={agentData.facebookName}
                        onChange={(e) =>
                          setAgentData({
                            ...agentData,
                            facebookName: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="input-box">
                    <label className="admin-form-label">Contact #</label>
                    <div className="form-group">
                      <input
                        className="form-control"
                        type="number"
                        placeholder="Contact #"
                        value={agentData.contactNo}
                        onChange={(e) =>
                          setAgentData({
                            ...agentData,
                            contactNo: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="input-box">
                    <label className="admin-form-label">Address</label>
                    <div className="form-group">
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Address"
                        value={agentData.address}
                        onChange={(e) =>
                          setAgentData({
                            ...agentData,
                            address: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="input-box">
                    <label className="admin-form-label">Age</label>
                    <div className="form-group">
                      <input
                        className="form-control"
                        type="number"
                        placeholder="Age"
                        value={agentData.birthDate}
                        onChange={(e) =>
                          setAgentData({
                            ...agentData,
                            birthDate: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="input-box">
                    <label className="admin-form-label">Civil Status</label>
                    <div className="form-group">
                      <Select
                        type="text"
                        placeholder="Civil Status"
                        options={adminAddAgentMenuOptions.civilStatus}
                        onChange={(e) =>
                          setAgentData({ ...agentData, civilStatus: e.value })
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="input-box">
                    <label className="admin-form-label">Spouse's Name</label>
                    <div className="form-group">
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Spouse's Name"
                        value={agentData.spouseName}
                        onChange={(e) =>
                          setAgentData({
                            ...agentData,
                            spouseName: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="input-box">
                    <label className="admin-form-label">
                      Educational Attainment
                    </label>
                    <div className="form-group">
                      <Select
                        type="text"
                        placeholder="Educational Attainment"
                        options={adminAddAgentMenuOptions.educationAttainment}
                        onChange={(e) =>
                          setAgentData({
                            ...agentData,
                            educationalAttainment: e.value,
                          })
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="input-box">
                    <label className="admin-form-label">
                      Position Applied For
                    </label>
                    <div className="form-group">
                      <Select
                        type="text"
                        placeholder="Position Applied For"
                        options={adminAddAgentMenuOptions.position}
                        onChange={(e) =>
                          setAgentData({
                            ...agentData,
                            position: e.value,
                          })
                        }
                      // onChange={e => e.target.value }
                      // value={}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="input-box">
                    <label className="admin-form-label">Work Experience</label>
                    <div className="form-group">
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Work Experience"
                        value={agentData.workExperience}
                        onChange={(e) =>
                          setAgentData({
                            ...agentData,
                            workExperience: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="input-box">
                    <label className="admin-form-label">
                      Recruited/Invited By
                    </label>
                    <div className="form-group">
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Recruited/Invited By"
                        value={agentData.recruitedBy}
                        onChange={(e) =>
                          setAgentData({
                            ...agentData,
                            recruitedBy: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="input-box">
                    <label className="admin-form-label">Approved Date</label>
                    <div className="form-group">
                      <input
                        className="form-control"
                        type="date"
                        placeholder="Date"
                        value={agentData.approvedDate}
                        onChange={(e) =>
                          setAgentData({
                            ...agentData,
                            approvedDate: e.target.value,
                          })
                        }
                      // onChange={e => e.target.value }
                      // value={}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="input-box">
                    <label className="admin-form-label">
                      Attended BIOS Date
                    </label>
                    <div className="form-group">
                      <input
                        className="form-control"
                        type="date"
                        placeholder="Date"
                        value={agentData.approvedBiosDate}
                        onChange={(e) =>
                          setAgentData({
                            ...agentData,
                            approvedBiosDate: e.target.value,
                          })
                        }
                      // onChange={e => e.target.value }
                      // value={}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <button
                type="submit"
                to="/admin-agents"
                className="button icon-box-button border-0"
                onClick={(event) => handleAgentSubmit(event)}
              >                Approve Agent
              </button>
              <Link to="/admin-addagent" className="admin-addproperty-cancel">
                Cancel
              </Link>
            </form>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
          </div>
        </div>
      </main>
    </>
  );
}

export default AdminAddAgent;
