import React, { useState, useEffect } from "react";
import GeneralHeader from "../../components/common/GeneralHeader";
import BannerOne from "../../components/banner/banner1/BannerOne";
import ClientLogo from "../../components/sliders/ClientLogo";
import Footer from "../../components/common/footer/Footer";
import ScrollTopBtn from "../../components/common/ScrollTopBtn";
import SectionsHeading from "../../components/common/SectionsHeading";
import sectiondata from "../../store/store";
import IconBoxOne from "../../components/other/iconboxes/IconBoxOne.js";
import AskQuestionField from "../../components/contact/AskQuestionField";
import roxas from "../../assets/images/roxasCity.jpg";
import annoucementImg from "../../assets/images/sampleAnnoucement.jpg";

import { useCookies } from "react-cookie";
import axios from "axios";

function Home() {
  const [annoucement, setAnnoucment] = useState({ name: "", desc: "" });
  const [cookies] = useCookies(["Authorization"]);

  useEffect(() => {
    axios
      .post("/api/announcement/load_list", [], { headers: cookies })
      .then((res) => {
        if (res.data.status !== 0) {
          console.log(res.data);
          setAnnoucment({
            name: res.data.records[0].name,
            desc: res.data.records[0].description,
            featuredPhoto: res.data.records[0].featuredPhoto,
          });
        }
      });
  }, []);

  return (
    <main className="home-1">
      {/* Header */}
      <GeneralHeader />

      {/* Hero Banner */}
      <BannerOne />

      {/* About Intro */}
      <section className="cat-area padding-top-100px padding-bottom-90px">
        <div className="container">
          <div className="row section-title-width text-center padding-left-15px padding-right-15px">
            <SectionsHeading
              title="About ANCOR Development Corporation (ADC)"
              desc={`AnCor Development Corporation (ADC)
              is a 39-year-old company that was started by ANtonio and CORa
              Ortiz. The Comp0any aims to develop properties into subdivisions
              or commercial areas. It prides itself in developing properties
              of high quality yet casual luxury.`}
            />
          </div>
          <div className="row section-title-width text-center pt-3 padding-left-15px padding-right-15px">
            <SectionsHeading
              title=""
              desc={`Roxas City is one of the top 10 most liveable cities in the Philippines with a 2021
              population of 179,292 people. Its laidback Spanish pueblo setting is charming and
              relaxing, making it a retirement destination. Its clean beautiful stretch of beach on
              one side of the city and the hills on the other side make it accommodating of various
              outdoor activities to have a lifestyle. The convenience of having 2 shopping malls
              and an airport make city living a true destination that awaits capiznon. Those that
              love simple small town living embraced by family driven values, comfort, and
              security can truly call roxas city a home.`}
            />
            <div className="">
              <div className="img-boxes">
                <div className="row padding-left-15px padding-right-15px">
                  <img src={roxas} alt="Roxas City" />
                </div>
              </div>
            </div>
          </div>
          {/* <div className="">
            <div className="img-boxes">
              <div className="row">
                <img src={roxas} alt="Roxas City" />
              </div>
            </div>
          </div> */}
        </div>
      </section>

      {/* Work with ADC */}
      <section className="hiw-area text-center padding-top-100px padding-bottom-110px">
        <div className="container">
          <div className="row section-title-width text-center">
            <SectionsHeading title={"Work with ADC"} desc={""} />
          </div>

          {/* <HowItWorkOne hiw1content={sectiondata.howitworks.hiw1} /> */}
          <IconBoxOne items={sectiondata.howitworks.hiw1.items} />
        </div>
      </section>

      {/* Annoucement Board */}
      {annoucement?.name && (
        <section className="card-area text-center padding-bottom-100px padding-top-100px">
          <div className="container d-flex align-items-center justify-content-around">
            <div className="row section-title-width text-center">
              <div className="col-6-lg d-flex align-items-center justify-content-around">
                <div className="w-75 text-left">
                  <SectionsHeading
                    title={annoucement?.name}
                    desc={annoucement?.desc}
                  />
                  <br />
                  <img
                    className="annoucement-image"
                    src={
                      annoucement?.featuredPhoto
                        ? "/api/" + annoucement.featuredPhoto
                        : annoucementImg
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      )}

      {/* Sister Companies */}
      <section className="hiw-area text-center padding-top-100px padding-bottom-110px">
        <div className="container">
          <div className="row section-title-width text-center">
            <SectionsHeading title={"Sister Companies"} desc={""} />
          </div>
          <ClientLogo logos={sectiondata.clientlogos} />
        </div>
      </section>

      {/* Contact Us Form */}
      <section className="contact-area padding-top-40px padding-bottom-80px">
        <div className="container">
          <div className="row d-flex justify-content-around">
            <AskQuestionField title="Contact Us" />
          </div>
        </div>
      </section>

      {/* Footer */}
      <Footer />

      <ScrollTopBtn />
    </main>
  );
}

export default Home;
