import React from "react";
import AdminSidePanel from "./AdminSidePanel";
import Select from "react-select";
import { Link } from "react-router-dom";
import { useCookies } from "react-cookie";
import axios from "axios";

function AdminAddMap() {
  const [cookies, removeCookie] = useCookies(["Authorization"]);

  return (
    <>
      <main className="admin-panel">
        <div className="row">
          <div className="col-lg-2">
            <AdminSidePanel />
          </div>
          <div className="col-lg-9">
            <div className="admin-sitemap">
              Admin Panel {">"} Site Manager {">"} Add Location Map
            </div>
            <br></br>
            <br></br>
            <div className="admin-addproperty-title">Add Location Map</div>
            <form method="POST" action="/admin-properties">
              <div className="row">
                <div className="col-lg-12">
                  <div className="input-box">
                    <label className="admin-form-label">Location Name</label>
                    <div className="form-group">
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Location Name"
                        // onChange={e => e.target.value }
                        // value={}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="input-box">
                    <label className="admin-form-label">Description</label>
                    <div className="form-group">
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Description"
                        // onChange={e => e.target.value }
                        // value={}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="input-box">
                    <label className="admin-form-label">Featured Map</label>
                    <div className="form-group">
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Upload File"
                        // onChange={e => e.target.value }
                        // value={}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="input-box">
                    <label className="admin-form-label">Location Images</label>
                    <div className="form-group">
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Upload Files"
                        // onChange={e => e.target.value }
                        // value={}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <button
                type="submit"
                to="/admin-maps"
                className="button icon-box-button border-0"
                // onClick={}
              >
                Save Location Map
              </button>
              <Link to="/admin-addmap" className="admin-addproperty-cancel">
                Cancel
              </Link>
            </form>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
          </div>
        </div>
      </main>
    </>
  );
}

export default AdminAddMap;
