import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
// import MyDropzone from "./MyDropzone";

import Dropzone from "react-dropzone";
import axios from "axios";

function AskQuestionField({ title, partner, userData }) {
  const [contactData, setContactData] = useState({
    fname: "",
    lname: "",
    email: "",
    password: "",
    contactNo: "",
    subject: "",
    message: "",
  });
  const [contactFiles, setContactFiles] = useState({});
  const [businessReason, setBusinessReason] = useState({
    toRent: false,
    toBuy: false,
    toSell: false,
    toJointventure: false,
    toLease: false,
  });
  const [error, setError] = useState({ message: "", status: false });
  let history = useHistory();

  function formSubmission(e) {
    e.preventDefault();
    console.log("contactData >>>>", contactData);
    console.log("contactFiles >>>", contactFiles);
    console.log("business reason >>>>", businessReason);

    // reset error message
    setError({ message: "", status: false });

    // create formData obj / data structure to send to email/server
    // is it going to the database or straight to email?
    const formData = new FormData();
    for (const [key, value] of Object.entries(contactData)) {
      formData.append(key, value);
    }
    formData.append("uploadFile", contactFiles);
    formData.append("reason", businessReason);
    axios
      .post("/api/registration/business_partner_save", formData)
      .then((res) => {
        console.log(res.data);
        // if submission sucessful
        if (res.data.status === 1) {
          setContactData({
            name: "",
            email: "",
            phone: "",
            subject: "",
            message: "",
          });
          history.push("/message-sent");
        } else {
          setError({ message: res.data.message, status: true });
        }
      })
      .catch((err) => {
        console.log(err);
        setError({ message: err.message, status: true });
      });
  }

  useEffect(() => {
    // manage files update
    // console.log('contactFiles >>>', contactFiles)
  }, [contactFiles]);

  function getFilenames() {
    let list = [];
    for (const key in contactFiles) {
      list.push(<li>- {key}</li>);
    }
    return list;
  }

  return (
    <>
      <div className="faq-forum">
        <div className="billing-form-item">
          {title ? (
            <div className="billing-title-wrap d-flex ">
              <div>
                <h3 className="widget-title pb-0">{title}</h3>
                <div className="title-shape margin-top-10px"></div>
              </div>
            </div>
          ) : (
            ""
          )}
          <div className="billing-content">
            <div className="contact-form-action">
              <form method="post">
                <div className="input-box">
                  {/* <label className="label-text">Your name</label> */}
                  <div className="form-group">
                    {/* <span className="form-icon"><FaRegEnvelope /></span> */}
                    <div className="row">
                      <div className="col-lg-6">
                        <input
                          className="form-control"
                          type="text"
                          name="name"
                          placeholder="First Name"
                          onChange={(e) =>
                            setContactData({
                              ...contactData,
                              fname: e.target.value,
                            })
                          }
                          value={contactData.fname}
                        />
                      </div>
                      <div className="col-lg-6">
                        <input
                          className="form-control"
                          type="text"
                          name="name"
                          placeholder="Last Name"
                          onChange={(e) =>
                            setContactData({
                              ...contactData,
                              lname: e.target.value,
                            })
                          }
                          value={contactData.lname}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="input-box">
                  {/* <label className="label-text">Your email</label> */}
                  <div className="form-group">
                    {/* <span className="form-icon"><FaRegEnvelope /></span> */}
                    <div className="row">
                      <div className="col-lg-6">
                        <input
                          className="form-control"
                          type="email"
                          name="email"
                          placeholder="Email"
                          onChange={(e) =>
                            setContactData({
                              ...contactData,
                              email: e.target.value,
                            })
                          }
                          value={contactData.email}
                        />
                      </div>
                      <div className="col-lg-6">
                        <input
                          className="form-control"
                          type="number"
                          name="phone"
                          placeholder="Phone"
                          onChange={(e) =>
                            setContactData({
                              ...contactData,
                              phone: e.target.value,
                            })
                          }
                          value={contactData.phone}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="input-box">
                  {/* <label className="label-text">Your name</label> */}
                  <div className="form-group">
                    {/* <span className="form-icon"><AiOutlineUser /></span> */}
                    <input
                      className="form-control"
                      type="password"
                      name="subject"
                      placeholder="Password"
                      onChange={(e) =>
                        setContactData({
                          ...contactData,
                          password: e.target.value,
                        })
                      }
                      value={contactData.password}
                    />
                  </div>
                </div>
                <div className="input-box">
                  {/* <label className="label-text">Your name</label> */}
                  <div className="form-group">
                    {/* <span className="form-icon"><AiOutlineUser /></span> */}
                    <input
                      className="form-control"
                      type="text"
                      name="subject"
                      placeholder="Subject"
                      onChange={(e) =>
                        setContactData({
                          ...contactData,
                          subject: e.target.value,
                        })
                      }
                      value={contactData.subject}
                    />
                  </div>
                </div>
                <div className="input-box">
                  {/* <label className="label-text">message</label> */}
                  <div className="form-group">
                    <textarea
                      className="message-control form-control"
                      name="message"
                      placeholder="How can we help?"
                      onChange={(e) =>
                        setContactData({
                          ...contactData,
                          message: e.target.value,
                        })
                      }
                      value={contactData.message}
                    ></textarea>
                  </div>
                </div>
                <div>
                  <div className="form-group">
                    <div className="row d-flex justify-content-around">
                      <div>
                        <input
                          onChange={(e) =>
                            setBusinessReason({
                              ...businessReason,
                              toRent: e.target.checked,
                            })
                          }
                          type="checkbox"
                          id="toRent"
                          name="toRent"
                        ></input>{" "}
                        <label for="toRent">Looking to Rent</label>
                      </div>
                      <div>
                        <input
                          onChange={(e) =>
                            setBusinessReason({
                              ...businessReason,
                              toBuy: e.target.checked,
                            })
                          }
                          type="checkbox"
                          id="toBuy"
                          name="toBuy"
                        ></input>{" "}
                        <label for="toBuy">Looking to Buy</label>
                      </div>
                      <div>
                        <input
                          onChange={(e) =>
                            setBusinessReason({
                              ...businessReason,
                              toJointventure: e.target.checked,
                            })
                          }
                          type="checkbox"
                          id="toJointventure"
                          name="toJointventure"
                        ></input>{" "}
                        <label for="toJointventure">
                          Looking to Jointventure
                        </label>
                      </div>
                      <div>
                        <input
                          onChange={(e) =>
                            setBusinessReason({
                              ...businessReason,
                              toSell: e.target.checked,
                            })
                          }
                          type="checkbox"
                          id="toSell"
                          name="toSell"
                        ></input>{" "}
                        <label for="toSell">Offering to Sell</label>
                      </div>
                      <div>
                        <input
                          onChange={(e) =>
                            setBusinessReason({
                              ...businessReason,
                              toLease: e.target.checked,
                            })
                          }
                          type="checkbox"
                          id="toLease"
                          name="toLease"
                        ></input>{" "}
                        <label for="toLease">Offering to Lease</label>
                      </div>
                    </div>
                  </div>
                  <Dropzone
                    mulitple
                    onDrop={(acceptedFiles) => {
                      const name = acceptedFiles[0].name;
                      console.log("file name >>>", name);
                      setContactFiles({
                        ...contactFiles,
                        [name]: acceptedFiles[0],
                      });
                    }}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <section className="container">
                        <div {...getRootProps({ className: "dropzone" })}>
                          <input {...getInputProps()} />
                          <p className="text-left">
                            (Optional) Upload files...
                            <br />
                            &nbsp;&nbsp;&nbsp;&nbsp;Title
                            <br />
                            &nbsp;&nbsp;&nbsp;&nbsp;Vicinity map
                            <br />
                            &nbsp;&nbsp;&nbsp;&nbsp;Tax Dex
                            <br />
                            &nbsp;&nbsp;&nbsp;&nbsp;ID with Signature and Photo
                          </p>
                        </div>
                        <aside>
                          <h4>Files</h4>
                          <ul>{getFilenames()}</ul>
                        </aside>
                      </section>
                    )}
                  </Dropzone>
                </div>

                <div className="btn-box d-flex justify-content-around">
                  {/* <Link to="/message-sent"> */}
                  <button
                    type="button"
                    className="theme-btn border-0"
                    onClick={formSubmission}
                  >
                    submit
                  </button>
                  {/* </Link> */}
                </div>
                {error.status && (
                  <div className="error-message">
                    <h3>Submission Error</h3>
                    <p>Error Message: {error.message}.</p>
                    <p>
                      Please try to contact ADC staff for assistance with your
                      submission.
                    </p>
                  </div>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AskQuestionField;
