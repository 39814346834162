import React from "react";
import GeneralHeader from "../../components/common/GeneralHeader";
import ListingDetailsSidebar from "../../components/sidebars/ListingDetailsSidebar";
import ListingDetailsGallery from "../../components/sliders/ListingDetailsGallery";
import Footer from "../../components/common/footer/Footer";
import ScrollTopBtn from "../../components/common/ScrollTopBtn";

function ListingDetails({ location }) {
  const propertyData = { ...location.state.details };
  console.log(propertyData);
  return (
    <main className="listing-details">
      {/* Header */}
      <GeneralHeader />

      <div className="listing-details-banner"></div>

      <section className="single-listing-area padding-top-35px">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="single-listing-wrap padding-bottom-50px">
                {/* pass listingDetails.image array here */}
                <ListingDetailsGallery propertyData={propertyData}/>
              </div>
            </div>
            <div className="col-lg-4">
              {/* pass listingDetails from API here */}
              <ListingDetailsSidebar propertyData={propertyData} />
            </div>
          </div>
        </div>
      </section>

      <Footer />

      <ScrollTopBtn />
    </main>
  );
}

export default ListingDetails;
