import React, { useEffect, useState } from "react";
import GeneralHeader from "../../components/common/GeneralHeader";
import Breadcrumb from "../../components/common/Breadcrumb";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { Link } from "react-router-dom";
import { BsListCheck } from "react-icons/bs";
import { FaCalendarCheck, FaFileAlt, FaFileContract } from "react-icons/fa";
import { AiOutlineUser, AiOutlineMail } from "react-icons/ai";
import Button from "../../components/common/Button";
import NewsLetter from "../../components/other/cta/NewsLetter";
import Footer from "../../components/common/footer/Footer";
import ScrollTopBtn from "../../components/common/ScrollTopBtn";
import sectiondata from "../../store/store";
import Select from "react-select";

import PlaceListing from "../../components/places/PlaceListing";
import { useCookies } from "react-cookie";
import axios from "axios";
import Dropzone from "react-dropzone";
import AskQuestionField from "../../components/contact/AskQuestionField";

function Dashboard() {
  const [cookies] = useCookies(["Authorization"]);
  const [showError, setShowError] = useState(false);
  const [showPasswordUpdate, setPasswordUpdate] = useState(false);
  const [showProfileUpdate, setShowProfileUpdate] = useState({
    general: false,
    more: false,
  });
  const [showDocumentRequest, setShowDocumentRequest] = useState({
    doc: "",
    status: false,
  });
  const [showDocumentRequestError, setShowDocumentRequestError] =
    useState(false);

  const appointmentLegend = {
    subject: {
      1: "Construction Inquiry",
      2: "Payment Inquiry",
      3: "Loan / Financing Inquiry",
      4: "Title Inquiry",
      5: "Meet with Management",
    },
    type: {
      1: "Virtual",
      2: "ADC Office",
      3: "Property Location",
    },
    status: {
      0: "Disapproved",
      1: "Pending",
      2: "Confirmed",
    },
  };

  const [appointmentForm, setappointmentForm] = useState({
    type: "",
    subject: "",
    notes: "",
    date: "mm/dd/yyyy",
    time: "hh:mm",
    userID: cookies?.User?.id,
  });

  function statusBadge(status) {
    if (status === "1") {
      return "appointment-badge badge-reopened";
    } else if (status === "2") {
      return "appointment-badge bg-2";
    } else {
      return "appointment-badge badge-closed";
    }
  }

  const [appointmentTable, setAppointmentTable] = useState([]);
  const [appointmentMessage, setAppointmentMessage] = useState({
    success: false,
    fail: false,
  });
  const [passwords, setPasswords] = useState({ old: "", new: "", confirm: "" });
  const [userDashboard, setUserDashboard] = useState({
    user: {
      generalData: {},
      moreData: {},
    },
    properties: [],
    docs: [],
  });

  // Connect to API once
  useEffect(() => {
    // collect data from API
    Promise.all([
      axios.post("/api/profile/view", [], { headers: cookies }),
      axios.post("/api/property/load_user_list", [], { headers: cookies }), // should include the owner ID to get owned propertise
      axios.post("/api/appointment/load_list", [], { headers: cookies }),
      axios.post("/api/document/load_list", [], { headers: cookies }),
    ]).then((res) => {
      console.log(res);
      setUserDashboard({
        ...userDashboard,
        properties: res[1].data.records,
        user: {
          generalData: {
            fname: res[0].data.user.fname,
            mname: res[0].data.user.mname,
            lname: res[0].data.user.lname,
            address: res[0].data.user.address,
            birthDate: res[0].data.user.birthDate,
            // birthDate: "2021-08-12",
            age: res[0].data.age,
            gender: res[0].data.user.gender,
            civilStatus: res[0].data.user.civilStatus,
            email: res[0].data.user.email,
            uploadFile: res[0].data.user.file,
          },
          moreData: {
            facebookName: res[0].data.user.facebookName,
            contactNo: res[0].data.user.contactNo,
            citizenship: res[0].data.user.citizenship,
            occupation: res[0].data.user.occupation,
            employer: res[0].data.user.employer,
            position: res[0].data.user.position,
            officeAddress: res[0].data.user.officeAddress,
            spouseName: res[0].data.user.spouseName,
            spouseOccupation: res[0].data.user.spouseOccupation,
            nameOfChildren: res[0].data.user.nameOfChildren,
            fatherName: res[0].data.user.fatherName,
            motherName: res[0].data.user.motherName,
            bankName: res[0].data.user.bankName,
            sourceOfFunds: res[0].data.user.sourceOfFunds,
          },
          status: res[0].data.user.status,
        },
        docs: [...res[3].data.records],
      });
      setAppointmentTable([...res[2].data.records]);
    });
  }, []);

  // clean up function for dashboard form
  useEffect(() => {}, [userDashboard]);

  useEffect(() => {}, [appointmentForm]);

  function updatePassword(e) {
    e.preventDefault();
    // incase updating twice in a row
    setPasswordUpdate(false);

    // console.log(passwords);
    if (checkPassword(passwords.new, passwords.confirm)) {
      const formData = new FormData();
      formData.append("currentPassword", passwords.new);
      formData.append("newPassword", passwords.new);
      axios
        .post("/profile/change_password", formData, { headers: cookies })
        .then(() => {
          setPasswordUpdate(true);
          setPasswords({ new: "", old: "", confirm: "" });
        });
    }
  }

  function checkPassword(pass1, pass2) {
    if (pass1 === pass2) {
      // do nothing or remove errors
      setShowError(false);
      return true;
    } else {
      setShowError(true);
      return false;
    }
  }

  function updateProfile(e, profilePart) {
    e.preventDefault();
    setShowProfileUpdate({ general: false, more: false });

    const formData = new FormData();
    // becaue its POST it needs all the params or it gets replaced
    // file can be refractor to reduce user object to have the keyvalue pairs instead of breaking into two parts
    for (const [key, value] of Object.entries(userDashboard.user.generalData)) {
      formData.append(key, value);
    }
    for (const [key, value] of Object.entries(userDashboard.user.moreData)) {
      formData.append(key, value);
    }
    axios
      .post("/api/profile/update", formData, { headers: cookies })
      .then((res) => {
        // console.log(res);
        if (profilePart === "general") {
          setShowProfileUpdate({ more: false, general: true });
        } else {
          setShowProfileUpdate({ general: false, more: true });
        }
      });
  }

  function bookAppointment(e) {
    e.preventDefault();

    setAppointmentMessage({ fail: false, success: false });

    const formData = new FormData();
    for (const [key, value] of Object.entries(appointmentForm)) {
      formData.append(key, value);
    }
    formData.append(
      "bookingDate",
      `${appointmentForm.date} ${appointmentForm.time}:00`
    );
    // console.log(formData);
    axios
      .post("/api/appointment/save", formData, { headers: cookies })
      .then((res) => {
        // console.log(res.data);
        if (res.data.status === 1) {
          // preprend to the appointmentTable arr
          const newAppointmentTable = [...appointmentTable];
          newAppointmentTable.unshift(res.data.record);
          setAppointmentTable(newAppointmentTable);

          // reset message
          setAppointmentMessage({ fail: false, success: true });
        } else {
          setAppointmentMessage({ fail: true, success: false });
        }
      })
      .catch((err) => {
        console.log(err);
        setAppointmentMessage({ fail: true, success: false });
      });
  }

  function requestDocs(doc) {
    setShowDocumentRequest({ doc: "", status: false });
    setShowDocumentRequestError(false);

    const path =
      doc.documentID === "2"
        ? "/document/deed_of_sale"
        : "/document/contract_to_sell";
    // console.log(path);
    axios
      .post(path, [], { headers: cookies })
      .then((res) => {
        console.log(res.data);
        if (res.data.status === 1) {
          setShowDocumentRequest({ doc: doc.name, status: true });
        } else {
          setShowDocumentRequest(true);
        }
      })
      .catch((err) => setShowDocumentRequestError(true));
  }

  function praseDocuments() {
    const parsedDocs = userDashboard.docs.map((doc) => {
      if (doc.type === "1") {
        return (
          <div className="row padding-left-30px" key={doc.documentID}>
            <div className="col-lg-12">
              <div className="section-heading">
                {/* this works if we have the docs in the public folder */}
                {/* the document will need to be retreived from API */}
                <a href={doc.link} target="_blank">
                  <h2 className="sec__title font-size-24">{doc.name}</h2>
                </a>
              </div>
              <p className="sec__desc">{doc.description}</p>
            </div>
          </div>
        );
      } else if (doc.type === "2" && cookies.User.userType === "") {
        return (
          <div className="row padding-left-30px" key={doc.documentID}>
            <div className="col-lg-12">
              <div className="section-heading">
                {/* this works if we have the docs in the public folder */}
                {/* the document will need to be retreived from API */}
                <h2
                  className="sec__title font-size-24"
                  onClick={() => requestDocs(doc)}
                >
                  {doc.name}
                </h2>
              </div>
              <p className="sec__desc">{doc.description}</p>
            </div>
          </div>
        );
      }
    });
    return parsedDocs;
  }
  return (
    <main className="dashboard-page">
      {/* Header */}
      <GeneralHeader />

      <div className="listing-details-banner"></div>

      {/*<TestVanillaJs />*/}

      <section className="dashboard-area padding-top-40px padding-bottom-90px">
        <div className="container">
          <Tabs>
            <div className="row">
              <div className="col-lg-3">
                <div className="dashboard-nav d-flex justify-content-between align-items-center mb-4">
                  <TabList className="nav nav-tabs border-0" id="nav-tab">
                    <Tab className="w-100 margin-bottom-10px">
                      <div className="nav-item nav-link theme-btn pt-0 pb-0 mr-1 d-flex justify-content-around">
                        <div>
                          <span className="la">
                            <AiOutlineUser />
                          </span>{" "}
                          Profile
                        </div>
                      </div>
                    </Tab>
                    {cookies.User.userType !== "agent" && (
                      <Tab className="w-100 margin-bottom-10px">
                        <div className="nav-item nav-link theme-btn pt-0 pb-0 mr-1 d-flex justify-content-around">
                          <div>
                            <span className="la">
                              <BsListCheck />
                            </span>{" "}
                            Ledger
                          </div>
                        </div>
                      </Tab>
                    )}
                    <Tab className="w-100 margin-bottom-10px">
                      <div className="nav-item nav-link theme-btn pt-0 pb-0 mr-1 d-flex justify-content-around">
                        <div>
                          <span className="la">
                            <FaFileAlt />
                          </span>{" "}
                          Forms
                        </div>
                      </div>
                    </Tab>
                    <Tab className="w-100 margin-bottom-10px">
                      <div className="nav-item nav-link theme-btn pt-0 pb-0 mr-1 d-flex justify-content-around">
                        <div>
                          <span className="la">
                            <FaFileContract />
                          </span>{" "}
                          Legal Documents
                        </div>
                      </div>
                    </Tab>
                    <Tab className="w-100 margin-bottom-10px">
                      <div className="nav-item nav-link theme-btn pt-0 pb-0 mr-1 d-flex justify-content-around">
                        <div>
                          <span className="la">
                            <FaCalendarCheck />
                          </span>{" "}
                          Appointments
                        </div>
                      </div>
                    </Tab>
                    <Tab className="w-100">
                      <div className="nav-item nav-link theme-btn pt-0 pb-0 mr-1 d-flex justify-content-around">
                        <div>
                          <span className="la">
                            <AiOutlineMail />
                          </span>{" "}
                          Contact ADC
                        </div>
                      </div>
                    </Tab>
                  </TabList>
                </div>
              </div>
              <div className="col-lg-9">
                <div className="tab-content" id="nav-tabContent">
                  <TabPanel>
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="user-form-action">
                          <div className="billing-form-item">
                            <div className="billing-title-wrap">
                              <h3 className="widget-title pb-0">
                                General Information
                              </h3>
                              <div className="title-shape margin-top-10px"></div>
                            </div>
                            <div className="billing-content">
                              <div className="contact-form-action">
                                <form>
                                  <div className="input-box">
                                    <label className="label-text">
                                      First Name
                                    </label>
                                    <div className="form-group">
                                      <input
                                        className="form-control"
                                        type="text"
                                        name="fname"
                                        placeholder="First Name"
                                        onChange={(e) =>
                                          setUserDashboard({
                                            ...userDashboard,
                                            user: {
                                              ...userDashboard.user,
                                              generalData: {
                                                ...userDashboard.user
                                                  .generalData,
                                                fname: e.target.value,
                                              },
                                            },
                                          })
                                        }
                                        value={
                                          userDashboard.user.generalData.fname
                                        }
                                      />
                                    </div>
                                  </div>
                                  <div className="input-box">
                                    <div className="row">
                                      <div className="col-lg-6">
                                        <label className="label-text">
                                          Middle Name
                                        </label>
                                        <div className="form-group">
                                          <input
                                            className="form-control"
                                            type="text"
                                            name="mname"
                                            placeholder="Middle Name"
                                            onChange={(e) =>
                                              setUserDashboard({
                                                ...userDashboard,
                                                user: {
                                                  ...userDashboard.user,
                                                  generalData: {
                                                    ...userDashboard.user
                                                      .generalData,
                                                    mname: e.target.value,
                                                  },
                                                },
                                              })
                                            }
                                            value={
                                              userDashboard.user.generalData
                                                .mname
                                            }
                                          />
                                        </div>
                                      </div>
                                      <div className="col-lg-6">
                                        <label className="label-text">
                                          Last Name
                                        </label>
                                        <div className="form-group">
                                          <input
                                            className="form-control"
                                            type="text"
                                            name="lname"
                                            placeholder="Last Name"
                                            onChange={(e) =>
                                              setUserDashboard({
                                                ...userDashboard,
                                                user: {
                                                  ...userDashboard.user,
                                                  generalData: {
                                                    ...userDashboard.user
                                                      .generalData,
                                                    lname: e.target.value,
                                                  },
                                                },
                                              })
                                            }
                                            value={
                                              userDashboard.user.generalData
                                                .lname
                                            }
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="input-box">
                                    <label className="label-text">
                                      Address
                                    </label>
                                    <div className="form-group">
                                      <input
                                        className="form-control"
                                        type="text"
                                        name="address"
                                        placeholder="Address"
                                        onChange={(e) =>
                                          setUserDashboard({
                                            ...userDashboard,
                                            user: {
                                              ...userDashboard.user,
                                              generalData: {
                                                ...userDashboard.user
                                                  .generalData,
                                                address: e.target.value,
                                              },
                                            },
                                          })
                                        }
                                        value={
                                          userDashboard.user.generalData.address
                                        }
                                      />
                                    </div>
                                  </div>
                                  <div className="input-box">
                                    <div className="row">
                                      <div className="col-lg-6">
                                        <label className="label-text">
                                          Date of Birth
                                        </label>
                                        <div className="form-group">
                                          <input
                                            className="form-control"
                                            type="date"
                                            name="birthday"
                                            placeholder="Birthday"
                                            onChange={(e) =>
                                              setUserDashboard({
                                                ...userDashboard,
                                                user: {
                                                  ...userDashboard.user,
                                                  generalData: {
                                                    ...userDashboard.user
                                                      .generalData,
                                                    birthDate: e.target.value,
                                                  },
                                                },
                                              })
                                            }
                                            value={
                                              userDashboard.user.generalData
                                                .birthDate
                                            }
                                          />
                                        </div>
                                      </div>
                                      <div className="col-lg-6">
                                        <label className="label-text">
                                          Age
                                        </label>
                                        <div className="form-group">
                                          <input
                                            className="form-control"
                                            type="number"
                                            name="Age"
                                            placeholder="Age"
                                            onChange={(e) =>
                                              setUserDashboard({
                                                ...userDashboard,
                                                user: {
                                                  ...userDashboard.user,
                                                  generalData: {
                                                    ...userDashboard.user
                                                      .generalData,
                                                    age: e.target.value,
                                                  },
                                                },
                                              })
                                            }
                                            value={
                                              userDashboard.user.generalData.age
                                            }
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="input-box">
                                    <div className="row">
                                      <div className="col-lg-6">
                                        <label className="label-text">
                                          Gender
                                        </label>
                                        <div className="form-group">
                                          <Select
                                            placeholder={
                                              userDashboard.user.generalData
                                                .gender
                                                ? userDashboard.user.generalData
                                                    .gender
                                                : "Gender"
                                            }
                                            options={[
                                              {
                                                value: "female",
                                                label: "Female",
                                              },
                                              { value: "male", label: "Male" },
                                              {
                                                value: "non-binary",
                                                label: "Non-Binary",
                                              },
                                            ]}
                                            onChange={(e) =>
                                              setUserDashboard({
                                                ...userDashboard,
                                                user: {
                                                  ...userDashboard.user,
                                                  generalData: {
                                                    ...userDashboard.user
                                                      .generalData,
                                                    gender: e.value,
                                                  },
                                                },
                                              })
                                            }
                                          />
                                        </div>
                                      </div>
                                      <div className="col-lg-6">
                                        <label className="label-text">
                                          Civil Status
                                        </label>
                                        <div className="form-group">
                                          <Select
                                            placeholder={
                                              userDashboard.user.generalData
                                                .civilStatus
                                                ? userDashboard.user.generalData
                                                    .civilStatus
                                                : "Civil Status"
                                            }
                                            options={[
                                              {
                                                value: "single",
                                                label: "Single",
                                              },
                                              {
                                                value: "married",
                                                label: "Married",
                                              },
                                              {
                                                value: "separated",
                                                label: "Separated",
                                              },
                                              {
                                                value: "widow",
                                                label: "Widow",
                                              },
                                              {
                                                value: "widower",
                                                label: "Widower",
                                              },
                                              {
                                                value: "other",
                                                label: "Other",
                                              },
                                            ]}
                                            onChange={(e) =>
                                              setUserDashboard({
                                                ...userDashboard,
                                                user: {
                                                  ...userDashboard.user,
                                                  generalData: {
                                                    ...userDashboard.user
                                                      .generalData,
                                                    civilStatus: e.value,
                                                  },
                                                },
                                              })
                                            }
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div className="input-box">
                                      <label className="label-text">
                                        Email
                                      </label>
                                      <div className="form-group">
                                        <input
                                          className="form-control"
                                          type="text"
                                          name="email"
                                          placeholder="Email"
                                          onChange={(e) =>
                                            setUserDashboard({
                                              ...userDashboard,
                                              user: {
                                                ...userDashboard.user,
                                                generalData: {
                                                  ...userDashboard.user
                                                    .generalData,
                                                  email: e.target.value,
                                                },
                                              },
                                            })
                                          }
                                          value={
                                            userDashboard.user.generalData.email
                                          }
                                        />
                                      </div>
                                    </div>
                                    <div className="input-box">
                                      <label className="label-text">
                                        Government Issued Photo ID
                                      </label>
                                      <Dropzone
                                        mulitple
                                        onDrop={(acceptedFiles) => {
                                          setUserDashboard({
                                            ...userDashboard,
                                            user: {
                                              ...userDashboard.user,
                                              generalData: {
                                                ...userDashboard.user
                                                  .generalData,
                                                uploadFile: acceptedFiles[0],
                                              },
                                            },
                                          });
                                        }}
                                      >
                                        {({ getRootProps, getInputProps }) => (
                                          <section className="container">
                                            <div
                                              {...getRootProps({
                                                className: "dropzone",
                                              })}
                                            >
                                              <input {...getInputProps()} />
                                              {userDashboard.user.generalData
                                                .uploadFile ? (
                                                <p>Governemnt ID uploaded</p>
                                              ) : (
                                                <p>Upload Government ID here</p>
                                              )}
                                            </div>
                                          </section>
                                        )}
                                      </Dropzone>
                                    </div>
                                  </div>
                                  {showProfileUpdate.general && (
                                    <div className="margin-bottom-10px">
                                      Your profile has been updated!
                                    </div>
                                  )}
                                  <div className="btn-box">
                                    <button
                                      className="theme-btn button-success border-0"
                                      onClick={(e) =>
                                        updateProfile(e, "general")
                                      }
                                    >
                                      Save Changes
                                    </button>
                                  </div>
                                </form>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="user-form-action">
                          <div className="billing-form-item">
                            <div className="billing-title-wrap">
                              <h3 className="widget-title pb-0">
                                Change Password
                              </h3>
                              <div className="title-shape margin-top-10px"></div>
                            </div>
                            <div className="billing-content">
                              <div className="contact-form-action">
                                <form>
                                  <div className="input-box">
                                    <label className="label-text">
                                      Current Password
                                    </label>
                                    <div className="form-group">
                                      <input
                                        className="form-control"
                                        type="password"
                                        name="currentPassword"
                                        placeholder="Current Password"
                                        onChange={(e) =>
                                          setPasswords({
                                            ...passwords,
                                            old: e.target.value,
                                          })
                                        }
                                        value={passwords.old}
                                      />
                                    </div>
                                  </div>
                                  <div className="input-box">
                                    <label className="label-text">
                                      New Password
                                    </label>
                                    <div className="form-group">
                                      <input
                                        className="form-control"
                                        type="password"
                                        name="newPassword"
                                        placeholder="New Password"
                                        onChange={(e) =>
                                          setPasswords({
                                            ...passwords,
                                            new: e.target.value,
                                          })
                                        }
                                        value={passwords.new}
                                      />
                                    </div>
                                  </div>
                                  <div className="input-box">
                                    <label className="label-text">
                                      Confirm New Password
                                    </label>
                                    <div className="form-group">
                                      <input
                                        className="form-control"
                                        type="password"
                                        name="confirmNewPassword"
                                        placeholder="Confirm New Password"
                                        onChange={(e) =>
                                          setPasswords({
                                            ...passwords,
                                            confirm: e.target.value,
                                          })
                                        }
                                        value={passwords.confirm}
                                      />
                                    </div>
                                  </div>
                                  {showPasswordUpdate && (
                                    <div className="margin-bottom-10px">
                                      Your password has successfully been
                                      updated!
                                    </div>
                                  )}

                                  {showError && (
                                    <div className="error-message color-text margin-bottom-10px">
                                      The passwords you have entered does not
                                      match.
                                    </div>
                                  )}

                                  <div className="btn-box">
                                    <button
                                      className="theme-btn button-success border-0"
                                      onClick={(e) => updatePassword(e)}
                                    >
                                      updated password
                                    </button>
                                  </div>
                                </form>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="user-form-action">
                          <div className="billing-form-item">
                            <div className="billing-title-wrap">
                              <h3 className="widget-title pb-0">
                                More Information
                              </h3>
                              <div className="title-shape margin-top-10px"></div>
                            </div>
                            <div className="billing-content">
                              <div className="contact-form-action">
                                <form>
                                  <div className="input-box">
                                    <div className="row">
                                      <div className="col-lg-6">
                                        <label className="label-text">
                                          Facebook Name
                                        </label>
                                        <div className="form-group">
                                          <input
                                            className="form-control"
                                            type="text"
                                            name="fbname"
                                            placeholder="Facebook Name"
                                            onChange={(e) =>
                                              setUserDashboard({
                                                ...userDashboard,
                                                user: {
                                                  ...userDashboard.user,
                                                  moreData: {
                                                    ...userDashboard.user
                                                      .moreData,
                                                    facebookName:
                                                      e.target.value,
                                                  },
                                                },
                                              })
                                            }
                                            value={
                                              userDashboard.user.moreData
                                                .facebookName
                                            }
                                          />
                                        </div>
                                      </div>
                                      <div className="col-lg-6">
                                        <label className="label-text">
                                          Contact #
                                        </label>
                                        <div className="form-group">
                                          <input
                                            className="form-control"
                                            type="text"
                                            name="contactNo"
                                            placeholder="Contact #"
                                            onChange={(e) =>
                                              setUserDashboard({
                                                ...userDashboard,
                                                user: {
                                                  ...userDashboard.user,
                                                  moreData: {
                                                    ...userDashboard.user
                                                      .moreData,
                                                    contactNo: e.target.value,
                                                  },
                                                },
                                              })
                                            }
                                            value={
                                              userDashboard.user.moreData
                                                .contactNo
                                            }
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="input-box">
                                    <label className="label-text">
                                      Citizenship
                                    </label>
                                    <div className="form-group">
                                      <input
                                        className="form-control"
                                        type="text"
                                        name="citizenship"
                                        placeholder="Citizenship"
                                        onChange={(e) =>
                                          setUserDashboard({
                                            ...userDashboard,
                                            user: {
                                              ...userDashboard.user,
                                              moreData: {
                                                ...userDashboard.user.moreData,
                                                citizenship: e.target.value,
                                              },
                                            },
                                          })
                                        }
                                        value={
                                          userDashboard.user.moreData
                                            .citizenship
                                        }
                                      />
                                    </div>
                                  </div>

                                  <div className="input-box">
                                    <label className="label-text">
                                      Occupation
                                    </label>
                                    <div className="form-group">
                                      <input
                                        className="form-control"
                                        type="text"
                                        name="occupation"
                                        placeholder="Occupation"
                                        onChange={(e) =>
                                          setUserDashboard({
                                            ...userDashboard,
                                            user: {
                                              ...userDashboard.user,
                                              moreData: {
                                                ...userDashboard.user.moreData,
                                                occupation: e.target.value,
                                              },
                                            },
                                          })
                                        }
                                        value={
                                          userDashboard.user.moreData.occupation
                                        }
                                      />
                                    </div>
                                  </div>
                                  <div className="input-box">
                                    <label className="label-text">
                                      Employer
                                    </label>
                                    <div className="form-group">
                                      <input
                                        className="form-control"
                                        type="text"
                                        name="employer"
                                        placeholder="Employer"
                                        onChange={(e) =>
                                          setUserDashboard({
                                            ...userDashboard,
                                            user: {
                                              ...userDashboard.user,
                                              moreData: {
                                                ...userDashboard.user.moreData,
                                                employer: e.target.value,
                                              },
                                            },
                                          })
                                        }
                                        value={
                                          userDashboard.user.moreData.employer
                                        }
                                      />
                                    </div>
                                  </div>
                                  <div className="input-box">
                                    <label className="label-text">
                                      Position
                                    </label>
                                    <div className="form-group">
                                      <input
                                        className="form-control"
                                        type="text"
                                        name="position"
                                        placeholder="Position"
                                        onChange={(e) =>
                                          setUserDashboard({
                                            ...userDashboard,
                                            user: {
                                              ...userDashboard.user,
                                              moreData: {
                                                ...userDashboard.user.moreData,
                                                position: e.target.value,
                                              },
                                            },
                                          })
                                        }
                                        value={
                                          userDashboard.user.moreData.position
                                        }
                                      />
                                    </div>
                                  </div>
                                  <div className="input-box">
                                    <label className="label-text">
                                      Office Address
                                    </label>
                                    <div className="form-group">
                                      <input
                                        className="form-control"
                                        type="text"
                                        name="officeAddress"
                                        placeholder="Office Address"
                                        onChange={(e) =>
                                          setUserDashboard({
                                            ...userDashboard,
                                            user: {
                                              ...userDashboard.user,
                                              moreData: {
                                                ...userDashboard.user.moreData,
                                                officeAddress: e.target.value,
                                              },
                                            },
                                          })
                                        }
                                        value={
                                          userDashboard.user.moreData
                                            .officeAddress
                                        }
                                      />
                                    </div>
                                  </div>
                                  <div className="input-box">
                                    <label className="label-text">
                                      Spouse's Name
                                    </label>
                                    <div className="form-group">
                                      <input
                                        className="form-control"
                                        type="text"
                                        name="spouseName"
                                        placeholder="Spouse's Name"
                                        onChange={(e) =>
                                          setUserDashboard({
                                            ...userDashboard,
                                            user: {
                                              ...userDashboard.user,
                                              moreData: {
                                                ...userDashboard.user.moreData,
                                                spouseName: e.target.value,
                                              },
                                            },
                                          })
                                        }
                                        value={
                                          userDashboard.user.moreData.spouseName
                                        }
                                      />
                                    </div>
                                  </div>
                                  <div className="input-box">
                                    <label className="label-text">
                                      Spouse's Occupation
                                    </label>
                                    <div className="form-group">
                                      <input
                                        className="form-control"
                                        type="text"
                                        name="spouseOccupation"
                                        placeholder="Spouse's Occupation"
                                        onChange={(e) =>
                                          setUserDashboard({
                                            ...userDashboard,
                                            user: {
                                              ...userDashboard.user,
                                              moreData: {
                                                ...userDashboard.user.moreData,
                                                spouseOccupation:
                                                  e.target.value,
                                              },
                                            },
                                          })
                                        }
                                        value={
                                          userDashboard.user.moreData
                                            .spouseOccupation
                                        }
                                      />
                                    </div>
                                  </div>
                                  <div className="input-box">
                                    <label className="label-text">
                                      Name of Children
                                    </label>
                                    <div className="form-group">
                                      <input
                                        className="form-control"
                                        type="text"
                                        name="nameOfChildren"
                                        placeholder="Name of Children"
                                        onChange={(e) =>
                                          setUserDashboard({
                                            ...userDashboard,
                                            user: {
                                              ...userDashboard.user,
                                              moreData: {
                                                ...userDashboard.user.moreData,
                                                nameOfChildren: e.target.value,
                                              },
                                            },
                                          })
                                        }
                                        value={
                                          userDashboard.user.moreData
                                            .nameOfChildren
                                        }
                                      />
                                    </div>
                                  </div>
                                  <div className="input-box">
                                    <label className="label-text">
                                      Father's Name
                                    </label>
                                    <div className="form-group">
                                      <input
                                        className="form-control"
                                        type="text"
                                        name="fatherName"
                                        placeholder="Father's Name"
                                        onChange={(e) =>
                                          setUserDashboard({
                                            ...userDashboard,
                                            user: {
                                              ...userDashboard.user,
                                              moreData: {
                                                ...userDashboard.user.moreData,
                                                fatherName: e.target.value,
                                              },
                                            },
                                          })
                                        }
                                        value={
                                          userDashboard.user.moreData.fatherName
                                        }
                                      />
                                    </div>
                                  </div>
                                  <div className="input-box">
                                    <label className="label-text">
                                      Mother's Name
                                    </label>
                                    <div className="form-group">
                                      <input
                                        className="form-control"
                                        type="text"
                                        name="motherName"
                                        placeholder="Mother's Name"
                                        onChange={(e) =>
                                          setUserDashboard({
                                            ...userDashboard,
                                            user: {
                                              ...userDashboard.user,
                                              moreData: {
                                                ...userDashboard.user.moreData,
                                                motherName: e.target.value,
                                              },
                                            },
                                          })
                                        }
                                        value={
                                          userDashboard.user.moreData.motherName
                                        }
                                      />
                                    </div>
                                  </div>
                                  <div className="input-box">
                                    <label className="label-text">
                                      Bank Name
                                    </label>
                                    <div className="form-group">
                                      <input
                                        className="form-control"
                                        type="text"
                                        name="bankName"
                                        placeholder="Bank Name"
                                        onChange={(e) =>
                                          setUserDashboard({
                                            ...userDashboard,
                                            user: {
                                              ...userDashboard.user,
                                              moreData: {
                                                ...userDashboard.user.moreData,
                                                bankName: e.target.value,
                                              },
                                            },
                                          })
                                        }
                                        value={
                                          userDashboard.user.moreData.bankName
                                        }
                                      />
                                    </div>
                                  </div>
                                  <div className="input-box">
                                    <label className="label-text">
                                      Source of Funds
                                    </label>
                                    <div className="form-group">
                                      <Select
                                        placeholder={
                                          userDashboard.user.moreData
                                            .sourceOfFunds
                                            ? userDashboard.user.moreData
                                                .sourceOfFunds
                                            : "Source of Funds"
                                        }
                                        options={[
                                          { value: "salary", label: "Salary" },
                                          {
                                            value: "Pension",
                                            label: "Pension",
                                          },
                                          {
                                            value: "Bank Loan",
                                            label: "Bank Loan",
                                          },
                                          {
                                            value: "Dividend",
                                            label: "Dividend",
                                          },
                                          {
                                            value: "Remittance",
                                            label: "Remittance",
                                          },
                                          { value: "other", label: "Other" },
                                        ]}
                                        onChange={(e) =>
                                          setUserDashboard({
                                            ...userDashboard,
                                            user: {
                                              ...userDashboard.user,
                                              moreData: {
                                                ...userDashboard.user.moreData,
                                                sourceOfFunds: e.value,
                                              },
                                            },
                                          })
                                        }
                                      />
                                    </div>
                                  </div>

                                  {showProfileUpdate.more && (
                                    <div className="margin-bottom-10px">
                                      Your profile has been updated!
                                    </div>
                                  )}

                                  <div className="btn-box">
                                    <button
                                      className="theme-btn button-success border-0"
                                      onClick={(e) => updateProfile(e, "more")}
                                    >
                                      Save Changes
                                    </button>
                                  </div>
                                </form>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </TabPanel>
                  {cookies.User.userType !== "agent" && (
                    <TabPanel>
                      <div className="row">
                        <div className="col-lg-12">
                          <PlaceListing
                            // instead of listings for the store, it needs to be pulled from API with users Auth
                            // new props variable to add to redirect to user-owned-listing details
                            // example: if userID is true, then redirect to listing-details/propertyID/userID
                            listitems={userDashboard.properties}
                            ownerID={true}
                          />
                        </div>
                      </div>
                    </TabPanel>
                  )}
                  <TabPanel>
                    {sectiondata.forms.map((form, index) => {
                      if (
                        cookies.User.userType === "" &&
                        userDashboard.user.status === "2"
                      ) {
                        if (form.userStatus === "2") {
                          return (
                            <div className="row padding-left-30px">
                              <div className="col-lg-12">
                                <div className="section-heading">
                                  {/* this works if we have the docs in the public folder */}
                                  {/* the document will need to be retreived from API */}
                                  <a href={form.link} target="_blank">
                                    <h2 className="sec__title font-size-24">
                                      {form.name}
                                    </h2>
                                  </a>
                                </div>
                              </div>
                            </div>
                          );
                        }
                      }
                      // filter based on usertype
                      else if (form.userType === cookies.User.userType) {
                        return (
                          <div className="row padding-left-30px">
                            <div className="col-lg-12">
                              <div className="section-heading">
                                {/* this works if we have the docs in the public folder */}
                                {/* the document will need to be retreived from API */}
                                <a href={form.link} target="_blank">
                                  <h2 className="sec__title font-size-24">
                                    {form.name}
                                  </h2>
                                </a>
                              </div>
                            </div>
                          </div>
                        );
                      }
                    })}
                    <div className="row padding-left-30px">
                      <div className="col-lg-12">
                        <div className="section-heading">
                          {/* this works if we have the docs in the public folder */}
                          {/* the document will need to be retreived from API */}
                          <a
                            href="https://forms.gle/re1cLTkrViJYaUzx6"
                            target="_blank"
                          >
                            <h2 className="sec__title font-size-24">
                              Form Link
                            </h2>
                          </a>
                        </div>
                        <p className="sec__desc">
                          Form Link Description of form. Lorem ipsum dolor sit
                          amet, consectetur adipiscing elit. Sed amet,
                          ullamcorper sit turpis neque vitae.
                        </p>
                      </div>
                    </div>
                  </TabPanel>
                  <TabPanel>
                    {showDocumentRequest.status && (
                      <div className="padding-top-20px padding-left-30px">
                        The document request for {showDocumentRequest.doc} has
                        been sent.
                      </div>
                    )}
                    {showDocumentRequestError && (
                      <div className="padding-top-20px padding-left-30px">
                        There is an error with your request. Please contact ADC
                        for assistance.
                      </div>
                    )}
                    {praseDocuments(userDashboard.docs)}
                    <div className="row padding-left-30px">
                      <div className="col-lg-12">
                        <div className="section-heading">
                          {/* this works if we have the docs in the public folder */}
                          {/* the document will need to be retreived from API */}
                          <Link to="/logo512.png" target="_blank" download>
                            <h2 className="sec__title font-size-24">
                              Document Name
                            </h2>
                          </Link>
                        </div>
                        <p className="sec__desc">
                          Document Name Description of document. Lorem ipsum
                          dolor sit amet, consectetur adipiscing elit. Sed amet,
                          ullamcorper sit turpis neque vitae.
                        </p>
                      </div>
                    </div>
                    <TabPanel>
                      <div className="row">LEGAL DOCS</div>
                    </TabPanel>
                  </TabPanel>
                  <TabPanel>
                    <div className="row padding-left-30px">
                      <div className="col-lg-12">
                        <div className="section-heading">
                          <h2 className="sec__title font-size-24">
                            Current Appointments
                          </h2>
                        </div>
                        <div className="ledger-details-payment table-responsive">
                          <table className="table mb-0">
                            <thead>
                              <tr>
                                <th scope="col">Booking #</th>
                                <th scope="col">Date</th>
                                <th scope="col">Time</th>
                                <th scope="col">Meeting Type</th>
                                <th scope="col">Subject</th>
                                <th scope="col">Status</th>
                              </tr>
                            </thead>
                            <tbody>
                              {/* get appointment history from API and use map to populate table */}
                              {appointmentTable.map((appointment, index) => {
                                return (
                                  <tr>
                                    <td>{appointment.bookingNo}</td>
                                    <td>
                                      {appointment.bookingDate.slice(0, 9)}
                                    </td>
                                    <td>{appointment.bookingDate.slice(10)}</td>
                                    <td>
                                      {appointmentLegend.type[appointment.type]}
                                    </td>
                                    <td>
                                      {
                                        appointmentLegend.subject[
                                          appointment.subject
                                        ]
                                      }
                                    </td>
                                    <td>
                                      <span
                                        className={statusBadge(
                                          appointment.status
                                        )}
                                      >
                                        {
                                          appointmentLegend.status[
                                            appointment.status
                                          ]
                                        }
                                      </span>
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <div className="row padding-left-30px">
                      <div className="col-lg-12">
                        <div className="section-heading">
                          <h2 className="sec__title font-size-24">
                            Book New Appointment
                          </h2>
                        </div>
                        <div>
                          <div className="contact-form-action agent-application-form">
                            <form method="POST">
                              <div className="row">
                                <div className="col-lg-12">
                                  <div className="input-box">
                                    <label className="label-text">
                                      Meeting Type
                                    </label>
                                    <div className="form-group">
                                      <Select
                                        placeholder="Virtual/ADC Office/Property Location"
                                        options={[
                                          {
                                            value: "1",
                                            label: "Virtual",
                                          },
                                          {
                                            value: "2",
                                            label: "ADC Office",
                                          },
                                          {
                                            value: "3",
                                            label: "Property Location",
                                          },
                                        ]}
                                        onChange={(e) =>
                                          setappointmentForm({
                                            ...appointmentForm,
                                            type: e.value,
                                          })
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-lg-12">
                                  <div className="input-box">
                                    <label className="label-text">
                                      Subject
                                    </label>
                                    <div className="form-group">
                                      <Select
                                        placeholder="Select a Subject"
                                        options={[
                                          {
                                            value: "1",
                                            label: "Construction Inquiry",
                                          },
                                          {
                                            value: "2",
                                            label: "Payment Inquiry",
                                          },
                                          {
                                            value: "3",
                                            label: "Loan / Financing Inquiry",
                                          },
                                          {
                                            value: "4",
                                            label: "Title Inquiry",
                                          },
                                          {
                                            value: "5",
                                            label: "Meet with Management",
                                          },
                                        ]}
                                        onChange={(e) =>
                                          setappointmentForm({
                                            ...appointmentForm,
                                            subject: e.value,
                                          })
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="input-box">
                                    <label className="label-text">Date</label>
                                    <div className="form-group">
                                      <input
                                        className="form-control"
                                        type="date"
                                        name="date"
                                        placeholder="Date"
                                        onChange={(e) =>
                                          setappointmentForm({
                                            ...appointmentForm,
                                            date: e.target.value,
                                          })
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="input-box">
                                    <label className="label-text">Time</label>
                                    <div className="form-group">
                                      <input
                                        className="form-control"
                                        type="time"
                                        name="time"
                                        placeholder="Time"
                                        onChange={(e) =>
                                          setappointmentForm({
                                            ...appointmentForm,
                                            time: e.target.value,
                                          })
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-lg-12">
                                  <div className="input-box">
                                    <label className="label-text">Notes</label>
                                    <div className="form-group">
                                      <input
                                        className="form-control"
                                        type="text"
                                        name="notes"
                                        placeholder="Notes"
                                        onChange={(e) =>
                                          setappointmentForm({
                                            ...appointmentForm,
                                            notes: e.target.value,
                                          })
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/* <Link to="/agent-application-submitted"> */}
                              <button
                                className="icon-box-button"
                                onClick={(e) => bookAppointment(e)}
                              >
                                Book Appointment
                              </button>
                              {/* </Link> */}
                              {appointmentMessage.success && (
                                <div className="padding-top-20px">
                                  Your appointment has been booked.
                                </div>
                              )}
                              {appointmentMessage.fail && (
                                <div className="error-message">
                                  There is a problem booking your appointment.
                                  Please contact ADC directly.
                                </div>
                              )}
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </TabPanel>
                  <TabPanel>
                    <div className="row">
                      <div className="col-lg-12">
                        <AskQuestionField
                          title="Contact Us"
                          userData={userDashboard.user.generalData}
                        />
                      </div>
                    </div>
                  </TabPanel>
                </div>
              </div>
            </div>
          </Tabs>
        </div>
      </section>

      {/* Newsletter */}
      {/* <NewsLetter newsLetterContent={sectiondata.calltoactions.newsletters} /> */}

      {/* Footer */}
      <Footer />

      <ScrollTopBtn />
    </main>
  );
}

export default Dashboard;
