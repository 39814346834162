import React, { useState, useEffect } from "react";
import AdminSidePanel from "./AdminSidePanel";
import { Link } from "react-router-dom";
import { AiOutlineExport } from "react-icons/ai";
import { FaPlusCircle } from "react-icons/fa";
import { useCookies } from "react-cookie";
import axios from "axios";

function AdminAppointments() {
  const [cookies, removeCookie] = useCookies(["Authorization"]);
  const [appointmentList, setAppointmentList] = useState([]);


  useEffect(() => {
    axios
      .get("/api/appointment/load_list", { headers: cookies })
      .then((res) => {
        console.log("res.data for appointments in adminappointments is", res.data);
        setAppointmentList(res.data.records);
      });
  }, []);

  return (
    <>
      <main className="admin-panel">
        <div className="row">
          <div className="col-lg-2">
            <AdminSidePanel />
          </div>
          <div className="col-lg-9">
            <div className="admin-sitemap">
              Admin Panel {">"} Accounts {">"} Appointments
            </div>
            <br></br>
            <br></br>
            {/* Appointments */}
            <div className="col-lg-12">
              <div className="section-heading">
                <h2 className="sec__title font-size-24">Appointments</h2>
                {/* <Link to="/admin-addagent" className="admin-add">
                  <FaPlusCircle />
                </Link>
                <Link to="/admin-exportclients" className="admin-export">
                  Export <AiOutlineExport />
                </Link> */}
              </div>
              <div className="ledger-details-payment table-responsive">
                <table className="table mb-0">
                <thead>
                  <tr>
                    <th scope="col">Booking #</th>
                    <th scope="col">Date and Time</th>
                    {/* <th scope="col">Time</th> */}
                    <th scope="col">Customer</th>
                    <th scope="col">Meeting Type</th>
                    <th scope="col">Subject</th>
                    <th scope="col">Status</th>
                  </tr>
                </thead>
                <tbody>
                  {appointmentList
                    .map((item, index) => (
                      <tr>
                        <td>{item.bookingNo}</td>
                        <td>{item.bookingDate}</td>
                        {/* <td>hh:mm</td> */}
                        <td>{item.userID}</td>
                        <td>{item.type}</td>
                        <td>{item.subject}</td>
                        <td>{item.status}</td>
                        {/* <td className="admin-appointments-confirmed">
                          Confirmed
                        </td> */}
                      </tr>
                    ))}
                </tbody>
                </table>
              </div>
              <Link to="/admin-appointments" className="admin-seeall">
                Page 1 of 1 Next {">>"}
              </Link>
            </div>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
          </div>
        </div>
      </main>
    </>
  );
}

export default AdminAppointments;
