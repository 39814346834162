import React, { useState } from "react";
import logosrc from "../../assets/images/logo3.png";
import { NavLink, Link } from "react-router-dom";
import { AiOutlinePieChart } from "react-icons/ai";
import { VscGlobe, VscAccount } from "react-icons/vsc";
import { useCookies } from "react-cookie";
import axios from "axios";

function AdminSidePanel() {
  const [AuthorAccessOpen, setAuthorAccessOpen] = useState(false);
  const [cookies, removeCookie] = useCookies(["Authorization"]);

  function signOut(event) {
    event.preventDefault();
    console.log(cookies);
    axios.post("/api/profile/logout", { headers: cookies }).then(() => {
      removeCookie("Authorization"); // doesnt delete the cookie, just turned to undefined
      removeCookie("User");
      setAuthorAccessOpen(!AuthorAccessOpen);
    });
  }

  return (
    <div className="admin-side-panel">
      <img src={logosrc} className="admin-logo" />
      <ul>
        <NavLink className="admin-side-item" to="/admin-overview">
          <span className="admin-side-icons">
            <AiOutlinePieChart />
          </span>{" "}
          Overview
        </NavLink>
      </ul>
      <ul>
        <NavLink className="admin-side-item" to="/admin-sitemanager">
          <span className="admin-side-icons">
            <VscGlobe />
          </span>{" "}
          Site Manager
        </NavLink>
      </ul>
      <ul>
        <NavLink className="admin-side-item" to="/admin-accounts">
          <span className="admin-side-icons">
            <VscAccount />
          </span>{" "}
          Accounts
        </NavLink>
      </ul>
      <div className="admin-side-link-container">
        <Link to="/" className="admin-side-link">
          View Website
        </Link>
        {/* <Link onClick={(e) => signOut(e)} to="/" className="admin-side-link">Log Out of Admin Panel</Link>         */}
      </div>
    </div>
  );
}
export default AdminSidePanel;
