import React, { useContext, useState, useCallback } from "react";
import GeneralHeader from "../components/common/GeneralHeader";
import AccordionList from "../components/other/AccordionList";
import SectionsHeading from "../components/common/SectionsHeading";
import Footer from "../components/common/footer/Footer";
import ScrollTopBtn from "../components/common/ScrollTopBtn";
import sectiondata from "../store/store";

import Search from "../components/common/Search";

import { Link } from "react-router-dom";

import {
  MatchText,
  SearchProvider,
  SearchContext,
  SearchEventContext,
} from "react-ctrl-f";

function Faq() {
  // const { searchValue, activeCount, totalCount } = useContext(SearchContext);
  // const { onSearchChange, onPrev, onNext } = useContext(SearchEventContext);

  const onCustomScroll = useCallback((id, fixedHeaderHeight) => {
    const dom = document.getElementById(id);
    if (dom) {
      const topOfElement =
        dom.getBoundingClientRect().bottom +
        window.pageYOffset -
        fixedHeaderHeight;
      window.scroll({
        top: topOfElement,
        behavior: "smooth",
      });
    }
  }, []);

  return (
    <main className="faq-page">
      {/* Header */}
      <GeneralHeader />

      <div className="listing-details-banner"></div>

      <section className="faq-area padding-top-50px padding-bottom-100px">
        <div className="container">
          <div className="row section-title-width section-title-ml-mr-0">
            <div className="col-lg-12 d-flex justify-content-center">
              <SectionsHeading
                title={sectiondata.accordions.sectitle}
                desc={sectiondata.accordions.seccontent}
              />
            </div>
          </div>
          <div className="row margin-top-35px d-flex justify-content-center">
            <div className="col-lg-8">
              <SearchProvider
                value={{
                  fixedHeaderHeight: 150,
                  onScroll: onCustomScroll,
                }}
              >
                <div
                  className="col-lg-2"
                  style={{
                    position: "fixed",
                    top: "110px",
                    left: "10px",
                    width: "auto",
                    border: "1px solid green",
                    zIndex: "999",
                    backgroundColor: "white",
                  }}
                >
                  <h4
                    style={{
                      paddingTop: "5px",
                      paddingLeft: "5px",
                    }}
                  >
                    Search by keyword
                  </h4>
                  <Search />
                  <div>
                    If you cannot find what you are looking for, please contact
                    us <Link to="/contact">here</Link>.
                  </div>
                </div>
                <AccordionList accordionItems={sectiondata.accordions.items} />
              </SearchProvider>
            </div>
          </div>
        </div>
      </section>

      {/* Footer */}
      <Footer />

      <ScrollTopBtn />
    </main>
  );
}

export default Faq;
