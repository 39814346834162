import React, { useState } from "react";
import axios from "axios";
import { Link, useHistory } from "react-router-dom";
import { useCookies } from "react-cookie";

function LoginBox({ title, subtitle }) {
  const [userLogin, setUserLogin] = useState({ email: "", password: "" });
  const [cookies, setCookie] = useCookies(["Authorization"]);
  const [error, setError] = useState({ message: "", status: false });
  let history = useHistory();

  function signIn(event) {
    event.preventDefault();
    // console.log("signing in!");
    // console.log(userLogin);
    setError({ message: "", status: false });

    const formData = new FormData();
    formData.append("email", userLogin.email);
    formData.append("password", userLogin.password);

    axios.post("/api/login/post", formData).then((res) => {
      console.log(res.data);
      // set up 2 cookies
      if (res.data.status) {
        setCookie("Authorization", res.data.token, { path: "/" });
        setCookie("User", res.data.data);
        // reset form
        setUserLogin({ email: "", password: "" });
        // redirect to homepage
        history.push("/");
      } else {
        setError({ message: res.data.message, status: true });
      }
    });
  }

  return (
    <>
      <div className="billing-form-item mb-0">
        <div className="billing-title-wrap border-bottom-0 pr-0 pl-0 pb-0 text-center">
          <h3 className="widget-title font-size-28 pb-0">{title}</h3>
          <p className="font-size-16 font-weight-medium">{subtitle}</p>
        </div>
        <div className="billing-content">
          <div className="contact-form-action">
            <form method="post" action="login/post">
              <div className="row">
                <div className="col-lg-12">
                  <div className="input-box">
                    <label className="label-text">Email</label>
                    <div className="form-group">
                      <input
                        className="form-control"
                        type="email"
                        name="email"
                        placeholder="Email"
                        onChange={(e) =>
                          setUserLogin({ ...userLogin, email: e.target.value })
                        }
                        value={userLogin.email}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="input-box">
                    <label className="label-text">Password</label>
                    <div className="form-group">
                      <input
                        className="form-control"
                        type="password"
                        name="password"
                        placeholder="Password"
                        onChange={(e) =>
                          setUserLogin({
                            ...userLogin,
                            password: e.target.value,
                          })
                        }
                        value={userLogin.password}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="form-group">
                    <div className="custom-checkbox mr-0 d-flex align-items-center justify-content-between">
                      <div>
                        <input type="checkbox" id="chb1" />
                        <label htmlFor="chb1">Remember Me</label>
                      </div>
                      <div>
                        <Link
                          to="/recover"
                          className="color-text-4 font-weight-medium"
                        >
                          Forgot password?
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12">
                  {error.status && (
                    <div className="error-message">
                      <p>Error Message: {error.message}</p>
                    </div>
                  )}
                  <div className="btn-box margin-top-20px margin-bottom-20px">
                    <button
                      className="button icon-box-button border-0"
                      type="submit"
                      onClick={(e) => signIn(e)}
                    >
                      Login
                    </button>
                  </div>
                </div>
                <div className="col-lg-12">
                  <p className="font-weight-medium">
                    No Account?{" "}
                    <Link to="/sign-up" className="color-text-4">
                      {" "}
                      Register
                    </Link>
                  </p>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default LoginBox;
