import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";

function RecoverPassBox() {
  const [email, setEmail] = useState("");
  const [showMessage, setShowMessage] = useState(false);
  const [showError, setShowError] = useState(false);

  function recoverPassword(e) {
    e.preventDefault();
    setShowError(false);
    setShowMessage(false);
    console.log("recovering password~");
    const formData = new FormData();
    formData.append("email", email);

    axios
      .post("/apiuser/recover_password", formData)
      .then((res) => {
        console.log(res.data);
        if (res.data.status === 1) {
          setShowMessage(true);
        } else {
          setShowError(true);
        }
      })
      .catch((err) => {
        console.log(err);
        setShowError(true);
      });
  }

  useEffect(() => {
    // control email form
  }, [email]);

  return (
    <>
      <section className="form-shared padding-top-40px padding-bottom-100px">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 mx-auto">
              <div className="billing-form-item mb-0">
                <div className="billing-title-wrap">
                  <h3 className="widget-title font-size-28">
                    Recover Password!
                  </h3>
                  <p className="font-size-16 font-weight-medium">
                    Enter the email of your account to reset password. Then you
                    will receive a link to email to reset the password. If you
                    have any issue about reset password{" "}
                    <Link to="/contact" className="color-text-2">
                      contact us
                    </Link>
                  </p>
                </div>
                <div className="billing-content">
                  <div className="contact-form-action">
                    <form method="post">
                      <div className="input-box">
                        <label className="label-text">Your Email</label>
                        <div className="form-group">
                          {/* <span className="la form-icon"><FaRegEnvelope /></span> */}
                          <input
                            className="form-control"
                            type="email"
                            name="text"
                            placeholder="Enter email address"
                            onChange={(e) => setEmail(e.target.value)}
                            value={email}
                          />
                        </div>
                      </div>
                      <div className="btn-box margin-top-20px margin-bottom-20px">
                        <button
                          className="theme-btn border-0"
                          type="submit"
                          onClick={(e) => recoverPassword(e)}
                        >
                          reset password
                        </button>
                      </div>
                      <p className="font-weight-medium mb-2">
                        <Link to="/login" className="color-text-2">
                          Login{" "}
                        </Link>
                        or
                        <Link to="/sign-up" className="color-text-2">
                          {" "}
                          Register
                        </Link>
                      </p>
                      {showMessage && (
                        <div className="margin-bottom-10px">
                          The email has been sent! Please check your email to
                          reset your password.
                        </div>
                      )}
                      {showError && (
                        <div className="error-message color-text margin-bottom-10px">
                          The email you have entered does not exist in our
                          system. Please contact us directly for assistance.
                        </div>
                      )}
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default RecoverPassBox;
