import React from 'react';
import SectionsHeading from "../common/SectionsHeading";
import Button from "../common/Button";


function About3({aboutcontent}) {
    return (
        <>
            <div className="row d-flex justify-content-around">
                <div className="col-lg-8">
                    <div className="section-heading">
                        <SectionsHeading
                            title='About ANCOR Development Corporation (ADC)'
                            desc='AnCor Development Corporation (ADC)
                            is a 39-year-old company that was started by ANtonio and CORa
                            Ortiz. The Comp0any aims to develop properties into subdivisions
                            or commercial areas. It prides itself in developing properties
                            of high quality yet casual luxury.'
                            descClass=" font-size-17 pr-3 mb-3"
                        >
                        </SectionsHeading>
                        <p className="font-size-17 pr-3 mb-3 text-left">Vision: We the employees and the members of the Ancor Group of
                            companies inspired by God's generosity and the love and dream of
                            our founders. give our wholehearted commitment to pursue
                            progressive, balanced, and productive lives.
                        </p>
                        <p className="font-size-17 pr-3 mb-3 text-left">Mission: we commit to nurture integrity and stewardship, to
                            preserving harmonious and respectful relationships, advancing in excellence and productivity, and celebrating individual, team, and enterprise achievement.
                        </p>
                        <p className="font-size-17 pr-3 mb-3 text-left">It has a Securities and Exchange Commission Registration no.
101486.
                        </p>
                    </div>
                </div>
            </div>
        </>
    );
}

export default About3;
