import React from "react";
import GeneralHeader from "../components/common/GeneralHeader";
import AskQuestionField from "../components/contact/AskQuestionField";
import ContactSidebar from "../components/sidebars/ContactSidebar";
import Footer from "../components/common/footer/Footer";
import ScrollTopBtn from "../components/common/ScrollTopBtn";
import sectiondata from "../store/store";

function Contact() {
  return (
    <main className="contact-page">
      {/* Header */}
      <GeneralHeader />

      <div className="listing-details-banner"></div>

      <section className="contact-area padding-top-40px padding-bottom-80px">
        <div className="container">
          <div className="row d-flex justify-content-around">
            <div className="col-lg-7">
              {/* previous col was 7 if keeping the contact side bar */}
              <AskQuestionField title="Contact Us" />
            </div>

            <div className="col-lg-5">
              <ContactSidebar contactinfo={sectiondata.contactdata} />
            </div>
          </div>
        </div>
      </section>

      {/* Footer */}
      <Footer />

      <ScrollTopBtn />
    </main>
  );
}

export default Contact;
