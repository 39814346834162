import { filter } from "lodash-es";
import React from "react";
import { FiSearch } from "react-icons/fi";
import Select from "react-select";

const state = {
  selectedOption: null,
  selectedCatOp: null,
  locations: [
    {
      value: "",
      label: "Select a Location",
    },
    {
      value: "",
      label: "All Locations",
    },
    {
      value: "1",
      label: "Saint. Ignatius Valley",
    },
    {
      value: "2",
      label: "San Antonio Resort Village",
    },
    {
      value: "3",
      label: "Saint. Ignatius Heights",
    },
    {
      value: "4",
      label: "Puenta Acuña",
    },
  ],
  type: [
    {
      value: "",
      label: "Select a Type",
    },
    {
      value: "",
      label: "All Types",
    },
    {
      value: "1",
      label: "Residential Lot for sale",
    },
    {
      value: "2",
      label: "House and Lot",
    },
    {
      value: "3",
      label: "Residential Lot",
    },
    {
      value: "4",
      label: "With Commercial Potential",
    },
    {
      value: "5",
      label: "Lot for lease",
    },
    {
      value: "6",
      label: "House for Construction",
    },
    {
      value: "7",
      label: "RFO House",
    },
    {
      value: "8",
      label: "House for Rent",
    },
  ],
  sortBy: [
    {
      value: "",
      label: "Sort by",
    },
    {
      value: "status",
      label: "Status",
    },
    {
      value: "name",
      label: "Name",
    },
    {
      value: "locationID",
      label: "Locations",
    },
    {
      value: "blockID",
      label: "Blocks",
    },
    {
      value: "lotNo",
      label: "Lots",
    },
  ],
  typeLegend: {
    "": "Select a Type",

    "": "All Types",

    1: "Residential Lot for sale",

    2: "House and Lot",

    3: "Residential Lot",

    4: "With Commercial Potential",

    5: "Lot for lease",

    6: "House for Construction",

    7: "RFO House",

    8: "House for Rent",
  },
  sortLegend: {
    "": "Sort by",

    status: "Status",

    name: "Name",

    locationID: "Locations",

    blockID: "Blocks",

    lotNo: "Lots",
  },
  sortOrder: [
    {
      value: "desc",
      label: "Descending",
    },
    {
      value: "asc",
      label: "Ascending",
    },
  ],
};
function WidgetSearch({ filterParams, setFilterParams }) {
  return (
    <>
      <div className="sidebar-widget">
        {/* <div className="contact-form-action">
                    <form>
                        <div className="form-group">
                            <span className="d-inline-block form-icon">
                                <FiSearch />
                            </span>
                            <input className="form-control" type="text" placeholder="What are you looking for?" />
                        </div>
                    </form>
                </div> */}
        <div className="sidebar-option">
          <Select
            placeholder={
              filterParams.locationName
                ? `${filterParams.locationName}`
                : "Select a Location"
            }
            options={state.locations}
            onChange={(e) =>
              setFilterParams({
                ...filterParams,
                locationID: e.value,
                locationName: e.label,
              })
            }
          />
        </div>
        <div className="sidebar-option">
          <Select
            placeholder={
              filterParams.projectType
                ? state.typeLegend[filterParams.projectType]
                : "Select a Type"
            }
            options={state.type}
            onChange={(e) =>
              setFilterParams({ ...filterParams, projectType: e.value })
            }
          />
        </div>
        <div className="sidebar-option">
          <Select
            placeholder={
              filterParams.sortBy
                ? state.sortLegend[filterParams.sortBy]
                : "Sort By"
            }
            options={state.sortBy}
            onChange={(e) =>
              setFilterParams({ ...filterParams, sortBy: e.value })
            }
          />
        </div>
        <div className="sidebar-option">
          <Select
            placeholder={
              filterParams.sortOrder
                ? filterParams.sortOrder.toUpperCase()
                : "Sort Order"
            }
            options={state.sortOrder}
            onChange={(e) =>
              setFilterParams({ ...filterParams, sortOrder: e.value })
            }
          />
        </div>
      </div>
    </>
  );
}

export default WidgetSearch;
