import React from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import { Link } from "react-router-dom";
import { MatchText } from "react-ctrl-f";

function AccordionList({ accordionItems }) {
  const projectSA = {
    locationID: "2",
    projectType: "",
    locationName: "San Antonio Resort Village",
    price: "P8,000",
  };
  const projectIV = {
    locationID: "1",
    projectType: "",
    locationName: "Saint. Ignatius Valley",
    price: "P5,000",
  };
  const projectSH = {
    locationID: "3",
    projectType: "",
    locationName: "Saint. Ignatius Heights",
    price: "P20,000",
  };
  const projectPA = {
    locationID: "4",
    projectType: "",
    locationName: "Puenta Acuña",
    price: "P8,000",
  };
  return (
    <>
      <Accordion
        className="accordion accordion-item pr-4"
        id="accordionExample"
      >
        {accordionItems.map((item, i) => {
          return (
            <div className={"card " + item.cardClass} key={i}>
              <AccordionItem>
                <AccordionItemHeading className="card-header">
                  <AccordionItemButton className="btn btn-link d-flex align-items-center justify-content-between">
                    <p>
                      <MatchText>{item.title}</MatchText>
                    </p>
                    <i className="minus">{item.minus}</i>
                    <i className="plus">{item.plus}</i>
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <div className="card-body">
                    <p className="pb-2">{item.desc}</p>
                    {item.desc1 && <p className="pb-2">{item.desc1}</p>}
                    {item.desc2 && <p className="pb-2">{item.desc2}</p>}
                    {item.desc3 && <p className="pb-2">{item.desc3}</p>}
                    {item.desc4 && <p className="pb-2">{item.desc4}</p>}
                    {item.maceda && (
                      <a
                        target="_blank"
                        href="https://www.google.com/search?q=maceda+law&oq=Maceda+law&aqs=chrome.0.0i512l10.817j0j7&sourceid=chrome&ie=UTF-8"
                      >
                        Click here to learn more about the Maceda Law
                      </a>
                    )}
                    {item.link && (
                      <a target="_blank" href={item.link}>
                        Link
                      </a>
                    )}
                    {item.propertyLocated && (
                      <>
                        <Link
                          to={{
                            pathname: "/listing-grid",
                            state: { filter: projectSA },
                          }}
                        >
                          <p>San Antonio Resort-Village, Baybay, Roxas City</p>
                        </Link>
                        <Link
                          to={{
                            pathname: "/listing-grid",
                            state: { filter: projectIV },
                          }}
                        >
                          <p>St. Ignatius Valley, Lawaan, Roxas City</p>
                        </Link>
                        <Link
                          to={{
                            pathname: "/listing-grid",
                            state: { filter: projectSH },
                          }}
                        >
                          <p>St. Ignatius Heights, Lawaan, Roxas City</p>
                        </Link>
                        <Link
                          to={{
                            pathname: "/listing-grid",
                            state: { filter: projectPA },
                          }}
                        >
                          <p>Puente Acuna, Dayao, Roxas CIty</p>
                        </Link>
                      </>
                    )}
                    {item.propertyReserve && (
                      <>
                        <Link
                          to={{
                            pathname: "/listing-grid",
                            state: { filter: projectSA },
                          }}
                        >
                          <p>
                            San Antonio Resort-Village, Baybay, Roxas City -{" "}
                            {projectSA.price}
                          </p>
                        </Link>
                        <Link
                          to={{
                            pathname: "/listing-grid",
                            state: { filter: projectIV },
                          }}
                        >
                          <p>
                            St. Ignatius Valley, Lawaan, Roxas City -{" "}
                            {projectIV.price}
                          </p>
                        </Link>
                        <Link
                          to={{
                            pathname: "/listing-grid",
                            state: { filter: projectSH },
                          }}
                        >
                          <p>
                            St. Ignatius Heights, Lawaan, Roxas City -{" "}
                            {projectSH.price}
                          </p>
                        </Link>
                        <Link
                          to={{
                            pathname: "/listing-grid",
                            state: { filter: projectPA },
                          }}
                        >
                          <p>
                            Puente Acuna, Dayao, Roxas CIty - {projectPA.price}
                          </p>
                        </Link>
                      </>
                    )}
                    {item.vlog && (
                      <>
                        <a
                          target="_blank"
                          href="https://www.youtube.com/watch?v=TEfWlsNweRQ"
                        >
                          San Antonio Resort-Village vlog
                        </a>
                        <br />
                        <a
                          target="_blank"
                          href="https://www.youtube.com/watch?v=s5DwS1XZt_g"
                        >
                          Puente Acuna vlog
                        </a>
                        <br />
                        <a
                          target="_blank"
                          href="https://www.youtube.com/watch?v=6JoRL9zYUXw"
                        >
                          St. Ignatius Valley / Heights vlog
                        </a>
                      </>
                    )}
                  </div>
                </AccordionItemPanel>
              </AccordionItem>
            </div>
          );
        })}
      </Accordion>
    </>
  );
}

export default AccordionList;
