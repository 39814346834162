import React from "react";
import GeneralHeader from "../components/common/GeneralHeader";
import LoginBox from "../components/other/account/LoginBox";
import Footer from "../components/common/footer/Footer";
import ScrollTopBtn from "../components/common/ScrollTopBtn";

function Login() {
  return (
    <main className="login-page">
      {/* Header */}
      <GeneralHeader />

      <div className="listing-details-banner"></div>

      <section className="form-shared padding-top-40px padding-bottom-100px">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 mx-auto">
              <LoginBox title="Login to your account" />
            </div>
          </div>
        </div>
      </section>

      {/* Footer */}
      <Footer />

      <ScrollTopBtn />
    </main>
  );
}

export default Login;
