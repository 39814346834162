import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import AdminSidePanel from "./AdminSidePanel";
import { Link } from "react-router-dom";
import { FaPlusCircle } from "react-icons/fa";
import { useCookies } from "react-cookie";
import axios from "axios";

function AdminProperties() {

  const [cookies, removeCookie] = useCookies(["Authorization"]);

  const [propertyList, setPropertyList] = useState([]);

  useEffect(() => {
    axios.get("/api/property/load_list", { headers: cookies }).then((res) => {
      console.log("res.data in adminproperties is", res.data);
      setPropertyList(res.data.records);
    });
  }, []);



  // Delete Property

  const deleteProperty = (id) => {
    setPropertyList(propertyList.filter((item) => item.id !== id))
  }

  // const formData = new FormData();
  // for (const [key, value] of Object.entries(propertyData)) {
  //   formData.append(key, value);
  // }

  // axios
  //   .delete(`/api/property/delete/${id}`, formData, { headers: cookies })
  //   .then((res) => {
  //     console.log("res.data in DELETE adminproperties page is:", res.data);

  //     history.push("/admin-properties");
  //   })
  //   .catch((err) => {
  //     console.log("admin add property error: ", err);
  //   });

  // useEffect(() => {
  //   axios.delete(`/api/property/delete/${id}`, formData, { headers: cookies }).then((res) => {
  //     console.log("res.data for DELETE in adminproperties is", res.data);
  //     history.push("/admin-properties");
  //     setPropertyList(res.data.records);
  //   });
  // }, []);

  // const deleteData = (id) => {
  //   console.log(id);
  //   const deleteData = axios
  //     .delete(`/api/property/load_list/${id}`)
  //     .then((response) => {
  //       console.log("Delete Data: ", response);
  //     })
  //     .catch(function (error) {
  //       console.log(error);
  //     });
  //   return deleteData;
  // };

  return (
    <>
      <main className="admin-panel">
        <div className="row">
          <div className="col-lg-2">
            <AdminSidePanel />
          </div>
          <div className="col-lg-9">
            <div className="admin-sitemap">
              Admin Panel {">"} Site Manager {">"} Property Listings
            </div>
            <br></br>
            <br></br>
            {/* Property Listings */}
            <div className="col-lg-12">
              <div className="section-heading">
                <h2 className="sec__title font-size-24">Property Listings</h2>
                <Link to="/admin-addproperty" className="admin-add">
                  <FaPlusCircle />
                </Link>
              </div>
              <div className="ledger-details-payment table-responsive">
                <table className="table mb-0">
                  <thead>
                    <tr>
                      <th scope="col">Property Name</th>
                      <th scope="col">Lot</th>
                      <th scope="col">Block</th>
                      <th scope="col">Lot Type</th>
                      <th scope="col">Location</th>
                      {/* <th scope="col">Listing Price</th> */}
                      <th scope="col">Agent</th>
                      <th scope="col">Client</th>
                      <th scope="col">Status</th>
                      <th scope="col">Manage</th>
                    </tr>
                  </thead>
                  <tbody>
                    {propertyList.map((item, index) => (
                      <tr key={index}>
                        <td>{item.name}</td>
                        <td>{item.lotStatus}</td>
                        <td>{item.blockID}</td>
                        <td>{item.locationName}</td>
                        <td>{item.locationID}</td>
                        {/* <td>Listing Price</td> */}
                        <td>{item.agentID}</td>
                        <td>{item.userID}</td>
                        <td>{item.status}</td>
                        <td>
                          <Link
                            to={{
                              pathname: "/admin-property",
                              state: { details: item },
                            }}
                          >View</Link> |{" "}
                          <Link to="/admin-properties">Edit</Link> |{" "}
                          <Link onClick={() => deleteProperty(item.propertyID)} to="/admin-properties">Delete</Link>
                          {/* <Link onClick={() => deleteData(item.propertyID)} to="/admin-properties">Delete</Link> */}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <Link to="/admin-properties" className="admin-seeall">
                Page 1 of 1 Next {">>"}
              </Link>
            </div>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
          </div>
        </div>
      </main>
    </>
  );
}

export default AdminProperties;
