import React, { useState, useEffect } from "react";
import AdminSidePanel from "./AdminSidePanel";
import { Link } from "react-router-dom";
import { FaPlusCircle } from "react-icons/fa";
import { useCookies } from "react-cookie";
import axios from "axios";

function AdminLegalDocuments() {
  const [cookies, removeCookie] = useCookies(["Authorization"]);
  const [documentList, setDocumentList] = useState([]);

  useEffect(() => {
    axios.get("/api/document/load_list", { headers: cookies }).then((res) => {
      console.log("res.data in adminsitemanager for document is", res.data);
      setDocumentList(res.data.records);
    });
  }, []);

  return (
    <>
      <main className="admin-panel">
        <div className="row">
          <div className="col-lg-2">
            <AdminSidePanel />
          </div>
          <div className="col-lg-9">
            <div className="admin-sitemap">
              Admin Panel {">"} Site Manager {">"} Legal Documents
            </div>
            <br></br>
            <br></br>
            <div className="col-lg-12">
              <div className="section-heading">
                <h2 className="sec__title font-size-24">Legal Documents</h2>
                <Link to="/admin-addlegaldocument" className="admin-add">
                  <FaPlusCircle />
                </Link>
              </div>
              <div className="ledger-details-payment table-responsive">
                <table className="table mb-0">
                  <thead>
                    <tr>
                      <th scope="col">Document Name</th>
                      <th scope="col">Description</th>
                      <th scope="col">Link</th>
                      <th scope="col">User Access</th>
                      <th scope="col">Manage</th>
                    </tr>
                  </thead>
                  <tbody>
                    {documentList.map((item, index) => (
                      <tr>
                        <td>{item.name}</td>
                        <td>{item.description}</td>
                        <td>{item.link}</td>
                        <td>Client</td>
                        <td>
                          <Link to="/admin-legaldocuments">Edit</Link> |{" "}
                          <Link to="/admin-legaldocuments">Delete</Link>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <Link to="/admin-legaldocuments" className="admin-seeall">
                See all...
              </Link>
            </div>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
          </div>
        </div>
      </main>
    </>
  );
}

export default AdminLegalDocuments;
