import React, { useContext } from "react";
import { SearchContext, SearchEventContext } from "react-ctrl-f";
function Search() {
  const { searchValue, activeCount, totalCount } = useContext(SearchContext);
  const { onSearchChange, onPrev, onNext } = useContext(SearchEventContext);
  return (
    <div style={{ padding: 16 }}>
      <input
        style={{ width: "100%", marginRight: "12px", height: "24px" }}
        value={searchValue}
        onChange={onSearchChange}
      />
      <div style={{ paddingTop: "10px" }}>
        <button
          className="icon-box-button"
          style={{ height: "28px" }}
          title="Up"
          onClick={onPrev}
        >
          Prev
        </button>
        <span style={{ padding: "0px 12px" }}>
          {activeCount}/{totalCount}
        </span>
        <button
          className="icon-box-button"
          style={{ height: "28px" }}
          title="Down"
          onClick={onNext}
        >
          Next
        </button>
      </div>
    </div>
  );
}

export default Search;
