import React, { useState, useEffect } from "react";
import AdminSidePanel from "./AdminSidePanel";
import { Link } from "react-router-dom";
import { FaPlusCircle } from "react-icons/fa";
import { useCookies } from "react-cookie";
import axios from "axios";

function AdminAccounts() {
  const [cookies, removeCookie] = useCookies(["Authorization"]);
  const [userList, setUserList] = useState([]);

  useEffect(() => {
    axios.get("/api/user/load_list", { headers: cookies }).then((res) => {
      console.log("res.data for USERS in adminoverview is", res.data);
      setUserList(res.data.records);
    });
  }, []);

  return (
    <>
      <main className="admin-panel">
        <div className="row">
          <div className="col-lg-2">
            <AdminSidePanel />
          </div>
          <div className="col-lg-9">
            <div className="admin-sitemap">Admin Panel {">"} Accounts</div>
            <br></br>
            <br></br>
            {/* Clients */}
            <div className="col-lg-12">
              <div className="section-heading">
                <h2 className="sec__title font-size-24">Clients</h2>
              </div>
              <div className="ledger-details-payment table-responsive">
                <table className="table mb-0">
                  <thead>
                    <tr>
                      <th scope="col">Name</th>
                      <th scope="col">Email</th>
                      <th scope="col">Contact #</th>
                      <th scope="col">Status</th>
                      <th scope="col">Location</th>
                      {/* <th scope="col">Ledger</th> */}
                      <th scope="col">Manage</th>
                    </tr>
                  </thead>
                  <tbody>
                    {userList
                      .reverse()
                      .filter((item) => item.userType === "")
                      .slice(0, 2)
                      .map((item) => (
                        <tr>
                          <td>
                            {item.fname} {item.mnam} {item.lname}
                          </td>
                          <td>{item.email}</td>
                          <td>{item.contactNo}</td>
                          <td>{item.status}</td>
                          <td>{item.address}</td>
                          {/* <td>
                            <Link to="/dashboard">View Ledger</Link>
                          </td> */}
                          <td>
                            <Link to="/admin-clients">View</Link> |{" "}
                            <Link to="/admin-clients">Edit</Link> |{" "}
                            <Link to="/admin-clients">Delete</Link>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
              <Link to="/admin-clients" className="admin-seeall">
                See all...
              </Link>
            </div>
            <br></br>
            <br></br>
            {/* Partners */}
            <div className="col-lg-12">
              <div className="section-heading">
                <h2 className="sec__title font-size-24">Business Partners</h2>
              </div>
              <div className="ledger-details-payment table-responsive">
                <table className="table mb-0">
                  <thead>
                    <tr>
                      <th scope="col">Name</th>
                      <th scope="col">Email</th>
                      <th scope="col">Contact #</th>
                      <th scope="col">Manage</th>
                    </tr>
                  </thead>
                  <tbody>
                    {userList
                      .reverse()
                      .filter((item) => item.userType === "business partner")
                      .slice(0, 2)
                      .map((item) => (
                        <tr>
                          <td>
                            {item.fname} {item.mnam} {item.lname}
                          </td>
                          <td>{item.email}</td>
                          <td>{item.contactNo}</td>
                          <td>
                            <Link to="/admin-businesspartners">View</Link> |{" "}
                            <Link to="/admin-businesspartners">Edit</Link> |{" "}
                            <Link to="/admin-businesspartners">Delete</Link>
                          </td>
                        </tr>
                      ))}

                    {/* <tr>
                      <td>Name</td>
                      <td>Email</td>
                      <td>Contact #</td>
                      <td>
                        <Link to="/admin-businesspartners">View</Link> | <Link to="/admin-businesspartners">Edit</Link> | <Link to="/admin-businesspartners">Delete</Link>
                      </td>
                    </tr> */}
                  </tbody>
                </table>
              </div>
              <Link to="/admin-businesspartners" className="admin-seeall">
                See all...
              </Link>
            </div>
            <br></br>
            <br></br>
            {/* Agents */}
            <div className="col-lg-12">
              <div className="section-heading">
                <h2 className="sec__title font-size-24">Agents</h2>
                <Link to="/admin-addagent" className="admin-add">
                  <FaPlusCircle />
                </Link>
              </div>
              <div className="ledger-details-payment table-responsive">
                <table className="table mb-0">
                  <thead>
                    <tr>
                      <th scope="col">Name</th>
                      <th scope="col">Email</th>
                      <th scope="col">Contact #</th>
                      <th scope="col">Recruited By</th>
                      <th scope="col">Position</th>
                      <th scope="col">Status</th>
                      <th scope="col">License</th>
                      <th scope="col">Details</th>
                    </tr>
                  </thead>
                  <tbody>
                    {userList
                      .reverse()
                      .filter((item) => item.userType === "agent")
                      .slice(0, 2)
                      .map((item) => (
                        <tr>
                          <td>
                            {item.fname} {item.mnam} {item.lname}
                          </td>
                          <td>{item.email}</td>
                          <td>{item.contactNo}</td>
                          <td>{item.recruitedBy}</td>
                          <td>{item.position}</td>
                          <td>{item.status}</td>
                          <td>{item.license}</td>
                          <td>
                            <Link to="/admin-agents">View</Link>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
              <Link to="/admin-agents" className="admin-seeall">
                See all...
              </Link>
            </div>
            <br></br>
            <br></br>
            {/* Staff */}
            <div className="col-lg-12">
              <div className="section-heading">
                <h2 className="sec__title font-size-24">Staff</h2>
                <Link to="/admin-addstaff" className="admin-add">
                  <FaPlusCircle />
                </Link>
              </div>
              <div className="ledger-details-payment table-responsive">
                <table className="table mb-0">
                  <thead>
                    <tr>
                      <th scope="col">Employee #</th>
                      <th scope="col">Access Level</th>
                      <th scope="col">Name</th>
                      <th scope="col">Email</th>
                      <th scope="col">Contact #</th>
                      <th scope="col">Manage</th>
                    </tr>
                  </thead>
                  <tbody>
                    {userList
                      .reverse()
                      .slice(0, 2)
                      .map((item) => (
                        <tr>
                          <td>{item.userID}</td>
                          <td>{item.userType}</td>
                          <td>
                            {item.fname} {item.mnam} {item.lname}
                          </td>
                          <td>{item.email}</td>
                          <td>{item.contactNo}</td>
                          <td>
                            <Link to="/admin-staff">View</Link> |{" "}
                            <Link to="/admin-staff">Edit</Link> |{" "}
                            <Link to="/admin-staff">Delete</Link>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
              <Link to="/admin-staff" className="admin-seeall">
                See all...
              </Link>
            </div>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
          </div>
        </div>
      </main>
    </>
  );
}

export default AdminAccounts;
