import React, { useState, useEffect } from "react";
import AdminSidePanel from "./AdminSidePanel";
import { Link } from "react-router-dom";
import { FaPlusCircle } from "react-icons/fa";
import { useCookies } from "react-cookie";
import axios from "axios";
import propertyPhoto from "../../assets/images/house2.png";


function AdminEachProperty({ location }) {

  const [cookies, removeCookie] = useCookies(["Authorization"]);

  console.log("location.state in adminProperty page is", location.state)
  const propertyData = { ...location.state.details };
  console.log(propertyData);

  const defaulPhoto = {
    titleUrl: "/listing-details",
    image: propertyPhoto,
  };

  return (
    <div>

      <main className="admin-panel">
        <div className="row">
          <div className="col-lg-2">
            <AdminSidePanel />
          </div>
          <div className="col-lg-9">
            <div className="admin-sitemap">
              Admin Panel {">"} Site Manager {">"} Property Listings {">"} Property
            </div>
            <br></br>
            <br></br>
          </div>
        </div>

        {/* <div className="card-item card-listing d-flex" key={index}>
          <div className="card-image-wrap">
            <div className="card-image">
              <img
                src={
                  item.featuredPhoto
                    ? "/api/" + item.featuredPhoto
                    : defaulPhoto.image
                }
                className="card__img"
                alt="Property Photo"
              />
            </div>
          </div>
        </div> */}

        {/* <div className="card-item card-listing d-flex">
          <div className="card-image-wrap">
            <div className="card-image">
              <img src={propertyPhoto} className="card__img" alt="Property Photo" />
            </div>
          </div>
        </div> */}
        <div className="property-info">
          {/* <img src={propertyPhoto} className="property-photo" alt="Property Photo" /> */}
          <img src={
            propertyData.featuredPhoto
              ? "/api/" + propertyData.featuredPhoto
              : defaulPhoto.image
          }
            className="property-photo" alt="Property Photo" />
          <div className="property-info-text">
            <br></br><br></br>
            <h3>Property Name: {propertyData.name} </h3>
            <br></br><br></br>
            <h4>Location: {propertyData.locationName}</h4>
            <br></br><br></br>
            <h4>Address: {propertyData.blockName}</h4>
            <br></br><br></br>
            {/* <h4>Listing Price: {propertyData.price}</h4>
            <br></br><br></br> */}
            <h4>Agent in Charge: {propertyData.agentID}</h4>
          </div>
        </div>
        <br></br><br></br><br></br><br></br>
      </main>



    </div>
  )
}

export default AdminEachProperty;