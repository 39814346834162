import React, { useState } from "react";
import Select from "react-select";
import { Link } from "react-router-dom";

const state = {
  selectedCatOp: null,
  type: [
    {
      value: "",
      label: "Select a Type",
    },
    {
      value: "",
      label: "All Types",
    },
    {
      value: "1",
      label: "Residential Lot for sale",
    },
    {
      value: "2",
      label: "House and Lot",
    },
    {
      value: "3",
      label: "Residential Lot",
    },
    {
      value: "4",
      label: "With Commercial Potential",
    },
    {
      value: "5",
      label: "Lot for lease",
    },
    {
      value: "6",
      label: "House for Construction",
    },
    {
      value: "7",
      label: "RFO House",
    },
    {
      value: "8",
      label: "House for Rent",
    },
  ],
  project: [
    {
      value: "",
      label: "Select a Project",
    },
    {
      value: "",
      label: "All Projects",
    },
    {
      value: "1",
      label: "Saint. Ignatius Valley",
    },
    {
      value: "2",
      label: "San Antonio Resort Village",
    },
    {
      value: "3",
      label: "Saint. Ignatius Heights",
    },
    {
      value: "4",
      label: "Puenta Acuña",
    },
  ],
};
export default function BannerOneSearchInput() {
  // let history = useHistory();

  const [landingParams, setLandingParams] = useState({
    locationID: "",
    projectType: "",
    locationName: "",
  });

  // function search() {
  //   console.log(filter)
  //   // redirect with filterData as prop
  //   history.push({
  //     pathname: '/listing-grid',
  //     state: {filter: filter}
  //   })

  // listings page with prop will take the form data and load with useEffect
  // }

  return (
    <>
      <div className="main-search-input">
        {/* <div className="main-search-input-item">
                    <div className="contact-form-action">
                        <form action="#">
                            <div className="form-group mb-0">
                            <span className="form-icon">
                                <FiSearch/>
                            </span>
                                <input className="form-control" type="text"
                                       placeholder="What are you looking for?"/>
                            </div>
                        </form>
                    </div>
                </div> */}
        <div className="main-search-input-title">
          <h3>What are you looking for?</h3>
        </div>

        <div className="main-search-input-item location">
          {/* <SelectCountry /> */}
          <Select
            placeholder="Project"
            options={state.project}
            onChange={(e) =>
              setLandingParams({
                ...landingParams,
                locationID: e.value,
                locationName: e.label,
              })
            }
          />
        </div>

        <div className="main-search-input-item block">
          <Select
            placeholder="Type"
            options={state.type}
            onChange={(e) =>
              setLandingParams({ ...landingParams, projectType: e.value })
            }
          />
        </div>

        <div className="main-search-input-btn">
          <Link
            to={{
              pathname: "/listing-grid",
              state: { filter: landingParams },
            }}
          >
            <button className="button theme-btn" type="submit">
              Search
            </button>
          </Link>
        </div>
      </div>
    </>
  );
}
