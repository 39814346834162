import React, { useState, useEffect } from "react";
import { useCookies } from "react-cookie";
import axios from "axios";

import { BsPower } from "react-icons/bs";
import { AiOutlineUser } from "react-icons/ai";
import { Link, useLocation, useHistory } from "react-router-dom";
import { MdLocalGasStation } from "react-icons/md";

export default function HeaderAuthorAccess() {
  const [AuthorAccessOpen, setAuthorAccessOpen] = useState(false);
  const [cookies, removeCookie] = useCookies(["Authorization"]);
  // const [isAdmin, setIsAdmin] = useState(false);

  const location = useLocation();
  const history = useHistory();

  function signOut(event) {
    event.preventDefault();
    // console.log(cookies);
    axios.post("/api/profile/logout", { headers: cookies }).then(() => {
      removeCookie("Authorization"); // doesnt delete the cookie, just turned to undefined
      removeCookie("User");
      setAuthorAccessOpen(!AuthorAccessOpen);
      if (location.pathname === "/dashboard") {
        history.push("/");
      }
    });
  }

  return (
    <>
      <div className="logo-right-content d-flex align-items-center">
        {cookies.Authorization?.length > 10 ? (
          <div
            className="side-user-menu-open"
            onClick={() => setAuthorAccessOpen(!AuthorAccessOpen)}
          >
            <AiOutlineUser />
          </div>
        ) : (
          <ul className="author-access-list">
            <li>
              <Link to="/login">
                <button className="login-button">Login</button>
              </Link>
            </li>
          </ul>
        )}
      </div>

      {/* Side User panel */}
      <div
        className={
          AuthorAccessOpen ? "side-user-panel active" : "side-user-panel"
        }
      >
        <div className="humburger-menu">
          <div
            className="humburger-menu-lines side-menu-close"
            onClick={() => setAuthorAccessOpen(!AuthorAccessOpen)}
          ></div>
        </div>
        <div className="side-menu-wrap side-user-menu-wrap">
          <div className="side-user-img">
            <h4 className="su__name">Welcome {cookies?.User?.fname}!</h4>
          </div>

          <ul className="side-menu-ul">
            <li>
              <Link to="/dashboard">
                <AiOutlineUser className="user-icon" /> My Account
              </Link>
            </li>
            <li>
              <div className="dropdown-divider"></div>
            </li>
            {/* will be the admin dashboard link */}
            {(() => {
              if (cookies.User?.userType === "admin") {
                console.log("user is admin!");
                return (
                  <>
                    <li>
                      <Link to="/admin-overview">
                        <AiOutlineUser className="user-icon" /> Admin Account
                      </Link>
                    </li>
                    <li>
                      <div className="dropdown-divider"></div>
                    </li>
                  </>
                );
              }
            })()}
            {/* <li>
              <Link to="#">
                <BsQuestion className="user-icon" /> help
              </Link>
            </li>
            */}
            <li onClick={(e) => signOut(e)}>
              <BsPower className="user-icon" /> Sign Out
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}
