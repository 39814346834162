import React from "react";
import GoogleMapReact from "google-map-react";
import LocationPin from "./LocationPin";

function RoxasMap() {
  const location = {
    address: "ROXAS CITY",
    lat: 11.57464,
    lng: 122.74233,
  };

  const projects = {
    projectPA: {
      address: "Puente Acuña",
      lat: 11.58275,
      lng: 122.7461,
      filter: {
        locationID: "4",
        projectType: "",
        locationName: "Puenta Acuña",
      },
    },

    projectSA: {
      address: "San Antonio Resort Village ",
      lat: 11.6035416,
      lng: 122.7346656,
      filter: {
        locationID: "2",
        projectType: "",
        locationName: "San Antonio Resort Village",
      },
    },
    projectIH: {
      address: "St. Ignatius Heights",
      lat: 11.55246,
      lng: 122.75564,
      filter: {
        locationID: "3",
        projectType: "",
        locationName: "Saint. Ignatius Heights",
      },
    },
    projectIV: {
      address: "St. Ignatius Valley",
      lat: 11.55303,
      lng: 122.75741,
      filter: {
        locationID: "1",
        projectType: "",
        locationName: "Saint. Ignatius Valley",
      },
    },
  };

  // API KEY - AIzaSyBcmfd38LTmL98pNQtk18K2kkWSz-FrGKc
  // the website url needs to be added to the api key access list

  return (
    <div className="map">
      <h2 className="map-h2">PROJECTS IN ROXAS CITY</h2>

      <div className="google-map">
        <GoogleMapReact
          bootstrapURLKeys={{ key: "" }}
          defaultCenter={location}
          defaultZoom={14}
        >
          <LocationPin
            lat={projects.projectPA.lat}
            lng={projects.projectPA.lng}
            text={projects.projectPA.address}
            filter={projects.projectPA.filter}
          />
          <LocationPin
            lat={projects.projectSA.lat}
            lng={projects.projectSA.lng}
            text={projects.projectSA.address}
            filter={projects.projectSA.filter}
          />
          <LocationPin
            lat={projects.projectIH.lat}
            lng={projects.projectIH.lng}
            text={projects.projectIH.address}
            filter={projects.projectIH.filter}
          />
          <LocationPin
            lat={projects.projectIV.lat}
            lng={projects.projectIV.lng}
            text={projects.projectIV.address}
            filter={projects.projectIV.filter}
          />
        </GoogleMapReact>
      </div>
    </div>
  );
}

export default RoxasMap;
