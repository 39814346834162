import React from 'react';
import WidgetSearch from "./widgets/WidgetSearch";
import WidgetFilterCategory from "./widgets/WidgetFilterCategory";
import WidgetFilterPrice from "./widgets/WidgetFilterPrice";
import WidgetFilterTags from "./widgets/WidgetFilterTags";
import WidgetFilterFeatures from "./widgets/WidgetFilterFeatures";
import WidgetSortBy from "./widgets/WidgetSortBy";
import WidgetFilterRatings from "./widgets/WidgetFilterRatings";
import WidgetPostedBy from "./widgets/WidgetPostedBy";
import Button from "../common/Button";
import sectiondata from "../../store/store";
import { BsChevronRight } from 'react-icons/bs'

function ListingListSidebar({filterParams, setFilterParams, filterList}) {
    return (
        <>
            <div className="sidebar">
                <WidgetSearch filterParams={filterParams} setFilterParams={setFilterParams}/>

                <div className="sidebar-widget">
                    <div className="btn-box">
                        <button className="theme-btn d-block w-100 text-center border-0" onClick={(e) => filterList()}>
                            <span className="d-inline-block"><BsChevronRight /></span>
                            Apply Filter
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ListingListSidebar;
