import React, { useState } from "react";
import AdminSidePanel from "./AdminSidePanel";
import Select from "react-select";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { useCookies } from "react-cookie";
import axios from "axios";
import Dropzone from "react-dropzone";

function AdminAddProperty() {
  const [cookies, removeCookie] = useCookies(["Authorization"]);
  const adminAddPropertyMenuOptions = {
    // projectTypes: [
    //   { value: "residentialLot", label: "Residential Lot" },
    //   { value: "houseAndLot", label: "House and Lot" },
    //   { value: "lotW", label: "Lot W" },
    //   { value: "commercial", label: "Commercial" },
    //   { value: "lotForLease", label: "Lot For Lease" },
    //   { value: "houseForContruction", label: "House For Contruction" },
    //   { value: "rfoHouse", label: "RFO House" },
    //   { value: "houseForRent", label: "House For Rent" },
    // ],
    projectType: [
      { value: "1", label: "Residential Lot for Sale" },
      { value: "2", label: "House and Lot" },
      { value: "3", label: "Residential Lot" },
      { value: "4", label: "Commercial" },
      { value: "5", label: "Lot For Lease" },
      { value: "6", label: "House For Contruction" },
      { value: "7", label: "RFO House" },
      { value: "8", label: "House For Rent" },
    ],
    pricingType: [
      { value: "1", label: "Regular" },
      { value: "2", label: "Prime" },
      { value: "3", label: "Special" },
      { value: "4", label: "Commercial" },
      { value: "5", label: "Corner" },
    ],
    status: [
      { value: "1", label: "For Lease / Sale" },
      { value: "2", label: "For Sale" },
      { value: "3", label: "For Lease" },
      { value: "4", label: "Resale" },
    ],
  };

  const [propertyData, setPropertyData] = useState({
    name: "",
    description: "",
    barangayID: "",
    lotStatus: "",
    blockID: "",
    cityID: "",
    locationID: "",
    projectType: "",
    pricingType: "",
    price: "",
    status: "",
    agentID: "",
    userID: "",
    featuredPhoto: null,
  });
  const history = useHistory();

  // to convert image to base64
  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };
  const handleFileUpload = async (file) => {
    const base64 = await convertToBase64(file);
    const indexOfSplit = base64.indexOf(",");
    const newBase64 = base64.slice(indexOfSplit + 1);
    console.log(newBase64);
    setPropertyData({ ...propertyData, featuredPhoto: newBase64 });
  };

  function handleSubmit(event) {
    event.preventDefault();

    console.log("propertyData is: ", propertyData);
    // let propertyDetails = {
    //   name,
    //   barangayID,
    //   lotStatus,
    //   blockID,
    //   cityID,
    //   price,
    //   agentID,
    //   userID,
    // };
    // return axios
    //   .post("/admin-properties", propertyDetails)
    //   .then((response) => {
    //     console.log("this is propertyDetails: ", propertyDetails);
    //     console.log("this is response: ", response);
    //     history.push("/admin-properties");
    //   })
    //   .catch((err) => {
    //     console.log("Got an error: ", err);

    // convert featuredPhoto into base64

    const formData = new FormData();
    for (const [key, value] of Object.entries(propertyData)) {
      formData.append(key, value);
    }

    axios
      .post("api/property/save", formData, { headers: cookies })
      .then((res) => {
        console.log("res.data in POST adminaddproperty page is:", res.data);

        history.push("/admin-properties");
      })
      .catch((err) => {
        console.log("admin add property error: ", err);
      });
  }
  return (
    <>
      <main className="admin-panel">
        <div className="row">
          <div className="col-lg-2">
            <AdminSidePanel />
          </div>
          <div className="col-lg-9">
            <div className="admin-sitemap">
              Admin Panel {">"} Site Manager {">"} Add/Edit Property
            </div>
            <br></br>
            <br></br>
            <div className="admin-addproperty-title">
              Add/Edit Property Listing
            </div>
            <form method="POST" action="/admin-properties">
              <div className="row">
                <div className="col-lg-12">
                  <div className="input-box">
                    <label className="admin-form-label">Property Name</label>
                    <div className="form-group">
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Property Name"
                        value={propertyData.name}
                        onChange={(e) =>
                          setPropertyData({
                            ...propertyData,
                            name: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="input-box">
                    <label className="admin-form-label">Brgy.</label>
                    <div className="form-group">
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Brgy."
                        value={propertyData.barangayID}
                        onChange={(e) =>
                          setPropertyData({
                            ...propertyData,
                            barangayID: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="input-box">
                    <label className="admin-form-label">Project Location</label>
                    <div className="form-group">
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Project Location"
                        value={propertyData.locationID}
                        onChange={(e) =>
                          setPropertyData({
                            ...propertyData,
                            locationID: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="input-box">
                    <label className="admin-form-label">Lot</label>
                    <div className="form-group">
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Lot"
                        value={propertyData.lotStatus}
                        onChange={(e) =>
                          setPropertyData({
                            ...propertyData,
                            lotStatus: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="input-box">
                    <label className="admin-form-label">Block</label>
                    <div className="form-group">
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Block"
                        value={propertyData.blockID}
                        onChange={(e) =>
                          setPropertyData({
                            ...propertyData,
                            blockID: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="input-box">
                    <label className="admin-form-label">city</label>
                    <div className="form-group">
                      <input
                        className="form-control"
                        type="text"
                        placeholder="city"
                        value={propertyData.cityID}
                        onChange={(e) =>
                          setPropertyData({
                            ...propertyData,
                            cityID: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="input-box">
                    <label className="admin-form-label">Project Types</label>
                    <div className="form-group">
                      <Select
                        type="text"
                        placeholder="Residential lot for sale/house and lot/Residential lot/commercial/lot for lease/house for contruction/rfo house/house for rent"
                        options={adminAddPropertyMenuOptions.projectType}
                        onChange={(e) =>
                          setPropertyData({
                            ...propertyData,
                            projectType: e.value,
                          })
                        }
                        // value={}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="input-box">
                    <label className="admin-form-label">Pricing Types</label>
                    <div className="form-group">
                      <Select
                        type="text"
                        placeholder="Regular/Prime/Special/Commercial/Corner"
                        options={adminAddPropertyMenuOptions.pricingType}
                        onChange={(e) =>
                          setPropertyData({
                            ...propertyData,
                            pricingType: e.value,
                          })
                        }
                        // value={}
                      />
                    </div>
                  </div>
                </div>
                {/* <div className="col-lg-12">
                  <div className="input-box">
                    <label className="admin-form-label">Starting Price</label>
                    <div className="form-group">
                      <input
                        className="form-control"
                        type="number"
                        placeholder="Starting Price"
                        value={propertyData.price}
                        onChange={(e) =>
                          setPropertyData({
                            ...propertyData,
                            price: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>
                </div> */}
                <div className="col-lg-4">
                  <div className="input-box">
                    <label className="admin-form-label">Status</label>
                    <div className="form-group">
                      <Select
                        type="text"
                        placeholder="For Lease/For Sale/Resale"
                        options={adminAddPropertyMenuOptions.status}
                        onChange={(e) =>
                          setPropertyData({ ...propertyData, status: e.value })
                        }
                        // value={}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="input-box">
                    <label className="admin-form-label">Agent</label>
                    <div className="form-group">
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Agent"
                        value={propertyData.agentID}
                        onChange={(e) =>
                          setPropertyData({
                            ...propertyData,
                            agentID: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="input-box">
                    <label className="admin-form-label">Client</label>
                    <div className="form-group">
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Client"
                        value={propertyData.userID}
                        onChange={(e) =>
                          setPropertyData({
                            ...propertyData,
                            userID: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="input-box">
                    <label className="admin-form-label">Featured Photo</label>
                    <div className="form-group">
                      {/* <input
                        className="form-control"
                        type="text"
                        placeholder="Drag and drop File Here to Upload"
                        // onChange={e => e.target.value }
                        // value={}
                      /> */}
                      <Dropzone
                        onDrop={(acceptedFiles) => {
                          console.log(acceptedFiles);
                          // setPropertyData({
                          //   ...propertyData,
                          //   featuredPhoto: acceptedFiles[0],
                          // });
                          setPropertyData({
                            ...propertyData,
                            featuredPhotoName: acceptedFiles[0].name,
                          });
                          handleFileUpload(acceptedFiles[0]);
                        }}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <section>
                            <div {...getRootProps()}>
                              <input {...getInputProps()} />
                              {propertyData.featuredPhoto ? (
                                <p>Photo uploaded</p>
                              ) : (
                                <p>
                                  Drag 'n' drop some files here, or click to
                                  select files
                                </p>
                              )}
                            </div>
                          </section>
                        )}
                      </Dropzone>
                    </div>
                  </div>
                </div>
                {/* </div>
                      <Dropzone mulitple onDrop={acceptedFiles => {
                        const name = acceptedFiles[0].name
                        console.log('file name >>>', name)
                        setContactFiles({...contactFiles, [name]: acceptedFiles[0]})
                      }}>
                      {({getRootProps, getInputProps}) => (
                          <section className="container">
                          <div {...getRootProps({className: 'dropzone'})}>
                            <input {...getInputProps()} />
                            <p className="text-left">(Optional) Upload files...<br/>
                            &nbsp;&nbsp;&nbsp;&nbsp;Title<br/>
                            &nbsp;&nbsp;&nbsp;&nbsp;Vicinity map<br/>
                            &nbsp;&nbsp;&nbsp;&nbsp;Tax Dex<br/>
                            &nbsp;&nbsp;&nbsp;&nbsp;ID with Signature and Photo</p>
                          </div>
                          <aside>
                            <h4>Files</h4>
                            <ul>{getFilenames()}</ul>
                          </aside>
                        </section>
                      )}
                      </Dropzone>
                    </div> */}
                <div className="col-lg-12">
                  <div className="input-box">
                    <label className="admin-form-label">
                      Additional Photo(s)
                    </label>
                    <div className="form-group">
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Drag and drop Files Here to Upload"
                        // onChange={e => e.target.value }
                        // value={}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <button
                type="submit"
                to="/admin-properties"
                className="button icon-box-button border-0"
                onClick={(event) => handleSubmit(event)}
              >
                Save Property Details
              </button>
              <Link
                to="/admin-addproperty"
                className="admin-addproperty-cancel"
              >
                Cancel
              </Link>
            </form>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
          </div>
        </div>
      </main>
    </>
  );
}
export default AdminAddProperty;
