import React, { useState, useEffect } from "react";
import GeneralHeader from "../../components/common/GeneralHeader";
import Breadcrumb from "../../components/common/Breadcrumb";
import PlaceGrid from "../../components/places/PlaceGrid";
import Button from "../../components/common/Button";
import { FiRefreshCw } from "react-icons/fi";
import Footer from "../../components/common/footer/Footer";
import ScrollTopBtn from "../../components/common/ScrollTopBtn";
import GenericHeader from "../../components/common/GenericHeader";
import bannerImg from "../../assets/images/listing-hero-bg.png";
import axios from "axios";
import { useCookies } from "react-cookie";
import { Link } from "react-router-dom";

function ListingGrid({ location }) {
  const [cookies] = useCookies(["Authorization"]);
  const [propertyGrid, setPropertyGrid] = useState([]);
  const [pagination, setPagination] = useState({
    limit: 3,
    offset: 0,
    page: 1,
  });
  const [emptyMessage, setEmptyMessage] = useState(false);

  const [filterParams, setFilterParams] = useState({
    locationName: location.state?.filter.locationName
      ? location.state?.filter.locationName
      : "",
    projectType: location.state?.filter.projectType
      ? location.state?.filter.projectType
      : "",
    locationID: location.state?.filter.locationID
      ? location.state?.filter.locationID
      : "",
    sortOrder: location.state?.filter.sortOrder
      ? location.state?.filter.sortOrder
      : "",
    sortBy: location.state?.filter.sortBy ? location.state?.filter.sortBy : "",
    searchValue: location.state?.filter.searchValue
      ? location.state?.filter.searchValue
      : "",
  });

  // console.log("this is filter for the page >>>>", filterParams)
  // console.log('this is the pagination       >>>', pagination)

  function nextPagination() {
    // set pagination config for next page to request
    const newOffset = pagination.limit * pagination.page;
    const newPage = pagination.page + 1;
    setPagination({ ...pagination, page: newPage, offset: newOffset });
  }

  function loadMore() {
    // console.log("triggering more");
    nextPagination();
    setEmptyMessage(false);

    const formData = new FormData();
    for (const [key, value] of Object.entries(filterParams)) {
      formData.append(key, value);
    }
    formData.append("limit", pagination.limit);
    formData.append("offset", pagination.offset);

    // console.log("offset >>>", pagination.offset);

    axios.post("/api/property/load_list", formData).then((res) => {
      // console.log(res.data);
      const newGrid = propertyGrid.concat(res.data.records);
      console.log("NEW LIST >>>", newGrid);
      setPropertyGrid(newGrid);
      if (res.data.records.length === 0) {
        setEmptyMessage(true);
      } else {
        setEmptyMessage(false);
      }
    });
  }

  function filter() {
    console.log("sending new list with filteR!!");
    setEmptyMessage(false);

    // take in params
    const formData = new FormData();
    for (const [key, value] of Object.entries(filterParams)) {
      formData.append(key, value);
    }
    // reset pagination in request
    formData.append("limit", 3);
    formData.append("offset", 0);

    // axios call
    axios.post("/api/property/load_list", formData).then((res) => {
      console.log(res.data);
      // reset pagination and set up for next page
      setPagination({ limit: 3, offset: 3, page: 2 });
      // replace the whole properties state
      setPropertyGrid(res.data.records);

      if (res.data.records.length === 0) {
        setEmptyMessage(true);
      } else {
        setEmptyMessage(false);
      }
    });
  }

  useEffect(() => {
    //update pagination state right away
    // console.log('filter paginatoin reset >>>>', pagination)
  }, [pagination]);

  useEffect(() => {
    const formData = new FormData();
    for (const [key, value] of Object.entries(filterParams)) {
      formData.append(key, value);
    }
    // reset offset for request
    formData.append("limit", 3);
    formData.append("offset", 0);

    axios.post("/api/property/load_list", formData).then((res) => {
      console.log(res.data);
      // reset pagination and set up for next page
      setPagination({ limit: 3, offset: 3, page: 2 });
      setPropertyGrid(res.data.records);

      if (res.data.records.length === 0) {
        setEmptyMessage(true);
      } else {
        setEmptyMessage(false);
      }
    });
  }, []);

  return (
    <main className="listing-grid">
      {/* Header */}
      <GeneralHeader />

      {/* Breadcrumb */}
      <Breadcrumb
        CurrentPgTitle="Properties"
        MenuPgTitle="Listings"
        img={bannerImg}
      />

      {/* Place Grid */}
      <section className="card-area padding-top-40px padding-bottom-100px">
        <div className="container d-flex align-items-center flex-column">
          <div
            className={
              !cookies.Authorization || cookies.Authorization?.length < 10
                ? "user-blur padding-bottom-30px"
                : ""
            }
          >
            <div className="row">
              <div className="col-lg-12">
                <GenericHeader
                  withSearch={true}
                  setFilterParams={setFilterParams}
                  filterParams={filterParams}
                  filterList={filter}
                />
              </div>
            </div>

            <div className="row">
              <Link to={"/property-maps"}>
                <p className="map-link">View Location Map</p>
              </Link>
            </div>

            <div className="row">
              {/* <PlaceGrid griditems={sectiondata.placesgrid} /> */}
              <PlaceGrid griditems={propertyGrid} />
            </div>
            {emptyMessage && (
              <div className="empty-property-message">
                There are 0 properties that matches the filter selection. Please
                change the filter selection.
              </div>
            )}

            <div className="row">
              <div className="col-lg-12">
                <div className="button-shared mt-4 text-center">
                  <button className="theme-btn border-0" onClick={loadMore}>
                    <span className="d-inline-block padding-right-15px">
                      <FiRefreshCw />
                    </span>
                    Load More
                  </button>
                </div>
              </div>
            </div>
          </div>
          {(!cookies.Authorization || cookies.Authorization?.length < 10) && (
            <Button
              url="/login"
              text="Login to see Details"
              className="blur-overlay-button"
            >
              {/* <button className="theme-btn blur-overlay-button">Login to see Details</button> */}
            </Button>
          )}
        </div>
      </section>

      {/* Newsletter */}
      {/* <NewsLetter newsLetterContent={sectiondata.calltoactions.newsletters} /> */}

      {/* Footer */}
      <Footer />

      <ScrollTopBtn />
    </main>
  );
}

export default ListingGrid;
