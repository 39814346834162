import React from 'react';
import SectionsHeading from "../common/SectionsHeading";
import Button from "../common/Button";
import roxas from '../../assets/images/roxas.jpg'

function About2({aboutcontent}) {
    return (
        <>
            <div className="row">
                <div className="col-lg-6">
                    <div className="about-content">
                        <SectionsHeading
                            title='"Seafood Capital of the Philippines"'
                            desc='The abundance of marine life makes Roxas City the “Seafood Capital of the Philippines.” This environment-friendly city is home to one of the richest fishing grounds in the country. Being a recipient of the Cleanest and Greenest Component City in Western Visayas Award in the Gawad Pangulo sa Kapaligiran (GPK) Cleanliness and Environmental contest is a clear testament of its people’s commitment in ensuring a healthier and brighter future for all its residents and visitors as well. Recently, Roxas City has been a recipient of major awards from various government agencies. The Department of Health (DOH) awarded this city with the Red Orchid Award for being “100 percent tobacco-free.” It has also gained the Department of Interior and Local Government (DILG) Seal of Good Housekeeping and lauded the Best Police Station in the region by the Philippine National Police.
                            '
                            descClass=" font-size-17 pr-3 mb-3"
                        >

                        </SectionsHeading>
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="img-boxes">
                        <div className="row padding-top-50px">
                            <img src={roxas} alt="Roxas City" />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default About2;
